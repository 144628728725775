import { Mail, Home, Briefcase, Search, List, UserCheck, Gift, Bell, ShoppingBag, AlignJustify, AlignLeft, Award} from "react-feather"

export default [
  {
    id: "home",
    title: "Home",
    icon: <Home size={20} />,
    visible: ["Admin", "Marketplace Member", "Agent"],
    navLink: "/home"
  },
  {
    id: "orderCost",
    title: "Order Cost",
    icon: <Mail size={20} />,
    visible: ["Admin"],
    navLink: "/order-cost"
  },
  {
    id: "purchasedLeads",
    title: "Purchased Leads",
    icon: <Briefcase size={20} />,
    visible: ["Agent", "Admin"],
    // visible: ["Admin", "Marketplace Member", "Agent"],
    navLink: "/purchased-leads"
  },
  {
    id: 'agent-controls',
    title: 'Agent Controls',
    icon: <UserCheck size={20} />,
    navLink: '/agents-list',
    visible: ["Admin"]
  },
  {
    id: "suppression-list",
    title: "Suppression List",
    icon: <List size={20} />,
    // visible: ["Agent"],
    visible: ["Admin"],
    navLink: "/suppression-list"
  },
  {
    id: "promocode",
    title: "Promocode",
    icon: <Gift size={20} />,
    visible: ["Admin"],
    navLink: "/promocode-list"
  },
  {
    id: "couponcode",
    title: "Coupon Codes",
    icon: <Award size={20} />,
    visible: ["Admin"],
    navLink: "/coupon-list"
  },
  // {
  //   id: "summary",
  //   title: "Order Summary",
  //   icon: <List size={20} />,
  //   visible: ["Admin"],
  //   navLink: "/order-summary"
  // },
  {
    id: "search",
    title: "Search",
    icon: <Search size={20} />,
    visible: ["Admin", "Agent"],
    navLink: "/search"
  },
  {
    id: "order-summary",
    title: "Order Summary",
    icon: <ShoppingBag size={20}/>,
    visible: ["Admin"],
    navLink: "/order-summary"
  },
  {
    id: "faq-manage",
    title: "Manage FAQ",
    icon: <AlignJustify size={20}/>,
    visible: ["Admin"],
    navLink: "/faq-manage"
  },
  {
    id: "faq",
    title: "FAQ",
    icon: <AlignLeft size={20}/>,
    visible: ["Agent"],
    navLink: "/faq"
  },
  {
    id: "notification",
    title: "Notifications",
    icon: <Bell/>,
    visible: ["Admin", "Agent"],
    navLink: "/notification"
  }
  
]
