import React, { useEffect} from 'react'
// import generatePDF from 'react-to-pdf'
import { useLocation } from 'react-router-dom'
import { Button } from 'reactstrap'
const PdfGenerator = () => {
    // const targetRef = useRef()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const name = searchParams.get('name')
    const date = searchParams.get('date')
    useEffect(() => {
      const printContent = () => {
        window.print()
      }
    
      const timer = setTimeout(printContent, 1000)
    
      return () => clearTimeout(timer)
    }, [])
    return (
        <div className='px-3 py-3'>
           {/* <Button color='primary' className='ms-1' onClick={() => generatePDF(targetRef, {filename: `${name}-${date}.pdf`})}>Download PDF</Button> */}
           {/* <div ref={targetRef} className='px-2 py-2'> */}
           <div  className='px-2 py-2'>
           PLEASE CAREFULLY READ AND UNDERSTAND THESE TERMS. THEY CONTAIN AN
          ARBITRATION AGREEMENT, JURY AND CLASS ACTION WAIVERS, LIMITATIONS OF
          LIABILITY AND OTHER PROVISIONS THAT AFFECT YOUR LEGAL RIGHTS.
          <h5 className="mt-1 mb-1">Acceptance of Terms</h5>
          <p className="mb-50">
            These terms of utilization constitute a binding agreement between
            you and our organization, which operates the Aria Leads website and
            its affiliated entities (referred to as "Company," "we," or "us").
            These stipulations, collectively with any integrated documents by
            explicit reference (termed "Terms"), dictate your access to and use
            of the Aria Leads website, the customer relationship management
            software, agent portal, and leads. These Terms apply to all
            insurance agents marketing products in collaboration with us or
            making use of any Resources.
          </p>
          <p className="mb-50">
            By utilizing the Resources, you indicate your consent to abide by
            these Terms. If you dissent from any aspect of these Terms, you must
            promptly notify us and discontinue your association with the
            Company.
          </p>
          <p className="">
            The Resources are accessible and intended for users who are 18 years
            or older, residing in the United States or its territories, and
            possess valid professional insurance licenses. Through utilizing the
            Resources, you assert that you are of legal age to establish a
            binding contract with the Company and fulfill all the mentioned
            eligibility criteria. If you do not meet these requisites, refrain
            from accessing or employing the Resources.
          </p>
          <h5 className="mt-1 mb-1">Alterations to Terms</h5>
          <p className="">
            We reserve the right, in its sole discretion, to change the Terms
            under which our services are extended, at our sole discretion. The
            latest edition of the Terms supersedes prior versions, and continued
            utilization of this website post-modification denotes your
            acceptance and agreement to the changes. We recommend periodic
            review of the Terms to stay informed of any updates.
          </p>
          <h5 className="mt-1 mb-1">
            Outbound Communications and Legal Compliance
          </h5>
          <p className="mb-1">
            Upon acquiring leads from us, you affirm your recognition that as an
            independent agent, you bear accountability for the legal standing of
            the leads and acknowledge all sales as final. Further information
            regarding the legalities of solicitation can be found at:
          </p>
          <p className="mb-1">
            {" "}
            <a
              href="https://www.ftc.gov/business-guidance/resources/can-spam-act-compliance-guide-business"
              target="_blank"
            >
              https://www.ftc.gov/business-guidance/resources/can-spam-act-compliance-guide-business
            </a>
          </p>
          <p className="mb-1">
            {" "}
            <a
              href="https://www.ftc.gov/business-guidance/resources/complying-telemarketing-sales-rule"
              target="_blank"
            >
              https://www.ftc.gov/business-guidance/resources/complying-telemarketing-sales-rule
            </a>
          </p>
          <h5 className="mt-1 mb-1">CAN-SPAM Act Compliance Guide</h5>
          <p className="mb-1">Complying with the Telemarketing Sales Rule</p>
          <p className="mb-50">
            Regarding your activities concerning the marketing and sales of
            insurance products, including your usage of Company resources, you
            agree not to:
          </p>
          <ul>
            <li>
              {" "}
              sell any leads purchased from the Company or assisted by the
              Company without explicit authorization.
            </li>
            <li>
              Engage in automatic dialing, telemarketing, or other prohibited
              communication methods.
            </li>
            <li>
              Purchase or employ leads generated via prohibited communication
              methods.
            </li>
            <li>
              Contact leads without proper consent or failing to screen against
              DNC registries.
            </li>
            <li>Communicate with leads that requested opt-out.</li>
            <li>
              For Medicare-related endeavors, violate specific marketing
              guidelines.
            </li>
            Violate federal or state laws, including TCPA, TSR, CAN-SPAM Act,
            HIPAA, CCPA, or applicable Medicare regulations.
          </ul>
          <h5 className="mb-1 mt-1">Access, Security, and Restricted Uses</h5>
          <p className="mb-1">
            To access any of the Resources, you may be asked to provide certain
            registration details or other information. It is a condition of your
            use of the Resources that all the information you provide in
            connection with your access to the Resources is correct, current,
            and complete. You agree that all information you provide to register
            with us is governed by our Privacy Policy, and you consent to all
            actions we take with respect to your information consistent with our
            Privacy Policy.
          </p>
          <p className="mb-1">
            If you choose, or are provided with, a username, password, or any
            other piece of information as part of our security procedures, you
            must treat such information as confidential, and you must not
            disclose it to any other person or entity. You also acknowledge that
            your account is personal to you and agree not to provide any other
            person with access to the Resources or portions of it using your
            username, password, or other security information. You agree to
            notify us immediately of any unauthorized access to or use of your
            username or password or any other breach of security. You also agree
            to ensure that you exit your account at the end of each session. You
            should use particular caution when accessing your account from a
            public or shared computer so that others are not able to view or
            record your password or other personal information.
          </p>
          <p className="mb-1">
            You may use the Resources only for lawful purposes and in accordance
            with these Terms. You agree not to:
          </p>
          <ul>
            <li>
              Use the Resources in any way that violates any applicable federal,
              state, local, or international law or regulation (including,
              without limitation, any laws regarding the export of data or
              software to and from the US or other countries).
            </li>
            <li>
              Use the Resources in any manner that could disable, overburden,
              damage, or impair the site or interfere with any other party's use
              of the Resources.
            </li>
            <li>
              Use any robot, spider, or other automatic device, process, or
              means to access the Resources for any purpose, including
              monitoring or copying any of the material on the Resources.
            </li>
            <li>
              Use any device, software, or routine that interferes with the
              proper working of the Resources.
            </li>
            <li>
              Introduce any viruses, Trojan horses, worms, logic bombs, or other
              material that is malicious or technologically harmful
            </li>
            <li>
              Attempt to gain unauthorized access to, interfere with, damage, or
              disrupt any server, computer, or database related to the
              Resources.
            </li>
            <li>
              Attack the Resources via a denial-of-service attack or a
              distributed denial-of-service attack.
            </li>
            <li>
              Otherwise attempt to interfere with the proper working of the
              Resources.
            </li>
          </ul>
          <h5 className="mt-1 mb-1">Termination and Access Restriction</h5>
          <p>
            We retain the right to terminate access to our Resources without
            notice. We reserve the right to modify the Aria Leads website,
            services, or materials at any time and without notice. We will not
            be liable if for any reason all or any part of the Aria Leads
            website is unavailable at any time or for any period. From time to
            time, we may restrict access to some parts of the Aria Leads
            website, or the entire Aria Leads website, to users, including
            registered users.
          </p>
          <h5 className="mb-50 mt-1">Lead Exclusivity</h5>
          <p>Leads sold maintain exclusivity to buyers for a minimum of 30 days.</p>
          <h5 className="mb-1 mt-50">Payment</h5>
          <p>Prices for leads are established by Aria Leads, dependent on user criteria lead type, quantity ect . User agreement to campaigns implies consent to associated costs for qualifying leads.</p>
          <h5 className="mb-1 mt-1">Refund Policy</h5>
          <ul>
            <li>Aaria Leads does not offer lead credits or refunds</li>
            <li>It is the user's responsibility to correctly select the parameters of their order (lead type, number of leads, location, etc.). Aria Leads will not issue any refunds or credit for failure to set the correct parameters.</li>
            <li>Aria Leads team, at its sole discretion, may choose to honor refund or credit requests on an ad-hoc basis, should they find that the user provided reason is accurate </li>
          </ul>
          <h5 className="mb-1 mt-1">Warranty Disclaimer</h5>
          <p className="mb-1">All Resources are presented "as is." The Company does not guarantee accuracy, completeness, or performance. </p>
          <p className="mb-1">You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Website will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.</p>
          <p className="mb-1">YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</p>
          <h5 className="mb-1 mt-1">Communication with Agent</h5>
          <p className="">As an agent, you authorize the Company to contact you in connection with the sale of insurance products. The Company may contact you by phone, email, text, voicemail, or other methods. You may opt out of communications by communicating to the Company your preference to opt out.</p>
          <h5 className="mb-1 mt-1">Independent Contractors</h5>
          <p>Except for employee agents, the Company’s agents are independent contractors of the Company and are not employees of the Company. Non-employee agents have the right to determine the method, manner, and means by which they perform their services. Nothing herein shall be construed to create a partnership, joint venture, or an agency relationship between non-employee contractors and the Company.</p>
          <h5 className="mb-1 mt-1">Trademarks</h5>
          <p>The Company’s name and logo, and all related names, logos, product and service names, designs and slogans are trademarks of the Company or its affiliates or licensors. You may not use such marks without the prior written permission of the Company. All other names, logos, product and service names, designs, and slogans on the Company’s website</p>
          <h5 className="mb-1 mt-1">Limitation on Liability</h5>
          <p className="mb-1">TO THE EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL THE COMPANY (OR ITS EMPLOYEES, OFFICERS, DIRECTORS OR AGENTS) BE LIABLE TO YOU FOR ANY CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE RESOURCES, REGARDLESS OF LEGAL THEORY, AND EVEN IF THE COMPANY HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGES. TO THE EXTENT PERMITTED BY APPLICABLE LAW, NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, THE COMPANY’S LIABILITY TO YOU ARISING FROM THESE TERMS, OR THE USE OF OR INABILITY TO USE THE RESOURCES, SHALL AT ALL TIMES BE LIMITED TO THE GREATER OF $1,000 OR THE AMOUNTS PAID BY YOU TO THE COMPANY FOR ACCESS TO AND USE OF THE RESOURCES.</p>
          <p>from the AAA’s roster of arbitrators. If the parties are unable to agree upon an arbitrator within seven (7) days of delivery of the Demand for Arbitration, then the AAA will appoint the arbitrator in accordance with the AAA Rules. Judgment on the arbitration award may be entered in any court having jurisdiction thereof. The arbitrator’s award of damages must be consistent with the terms of the “Limitation of Liability” section above as to the types and amounts of damages for which a party may be held liable.</p>
          <h5 className="mb-1 mt-1">Waiver and Severability</h5>
          <p className="mb-1">The failure of the Company to enforce any right or provision of these Terms will not constitute a waiver of future enforcement of that right or provision. The waiver of any such right or provision will be effective only if in writing and signed by a duly authorized representative of the Company. If for any reason a court of competent jurisdiction or an arbitrator finds any provision of these Terms invalid or unenforceable, that provision will be enforced to the maximum extent permissible, and the other provisions of these Terms will remain in full force and effect.</p>
          <h5 className="mb-1 mt-1">Contact Information</h5>
          <p className="mb-1">For any inquiries or concerns, reach us at:</p>
          <p className="mb-50">Phone: 305-548-2277</p>
          <p className="mb-50">Email: leads@arialeads.com</p>
.................................................................................................................................................................................................................................................................................................................................................................................................
          <p className="mb-50">Agent Name : {name}</p>
          <p className="mb-50">Agreement Date: {date}</p>
           </div>
        </div>
     )
}

export default PdfGenerator
