import React, {useState, useEffect, Fragment} from 'react'
import { Card,  TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Collapse, Button, Modal, ModalHeader, ModalBody, ModalFooter, CardBody } from 'reactstrap'
import { ChevronDown, Trash, Edit2, ChevronUp, X, Check } from 'react-feather'
import { getToken } from "@utils"
import axios from "axios"
import apiConfig from "@configs/apiConfig"
import { toast} from 'react-hot-toast'
import ReactPaginate from "react-paginate"
import ComponentSpinner from "../../@core/components/spinner/Loading-spinner"
import Avatar from "@components/avatar"
import { useDispatch } from "react-redux"
import { handleLogout } from '@store/authentication'
import { useNavigate } from 'react-router-dom'

const FaqView = () => {
    const history = useNavigate()
    const dispatch = useDispatch()
    const [active, setActive] = useState(1)
    const [open, setOpen] = useState('')
    const [categoryList, setCategoryList] = useState([])
    const [faqList, setFaqList] = useState([{}])
    const [paginatedData, setPaginatedData] = useState({})
    const [currentPage, setCurrentPage] = useState(0)
    const [uploaded, setUploaded] = useState([])
    const [uploadOpen, setUploadOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const token = getToken()
    const ToastContent = ({ message = null, error = null }) => (
        <Fragment>
          <div className="toastify-header">
            <div className="d-flex justify-content-between align-items-center">
              {!error && (
                <Avatar size="sm" color="success" icon={<Check size={12} />} />
              )}
              {error && <Avatar size="sm" color="danger" icon={<X size={12} />} />}
              <h6 className="align-self-center fw-bold ms-50 mb-0">{message}</h6>
            </div>
          </div>
          <div className="toastify-body"></div>
        </Fragment>
      )
    const toggle = tab => {
        setActive(tab)
      }
    const getFaq = (page, id) => {
        setLoading(true)
        const config = {
          method: "get",
          url: `${apiConfig.api.url_crm}faq/list?page=${parseInt(page)}&per_page=3&category_id=${id}&is_crm=${false}`,
          headers: {
            ContentType: "application/json",
            Authorization: `Token ${token}`
          }
        }
        axios(config).then((response) => {
          console.log("respone", response)
          if (response.data.status === 200) {
            setLoading(false)
            setFaqList(response.data.data)
            setPaginatedData(response.data.pagination)
          } else if (response.data.status === 204) {
            setLoading(false)
            setFaqList([])
          } else {
            setLoading(false)
            toast.error(
              <ToastContent message={response.data.message} error={true} />,
              { icon: true, hideProgressBar: true, autoClose: 2000 }
            )
          } 
        }).catch((error) => {
          setLoading(false)
          toast.error(
            <ToastContent message={error.message} error={true} />,
            { icon: true, hideProgressBar: true, autoClose: 2000 }
          )
        })
      }
      const getCategories = () => {
        setLoading(true)
        const config = {
          method: "get",
          url: `${apiConfig.api.url_crm}faq/category/list?is_crm=${false}`,
          headers: {
            ContentType: "application/json",
            Authorization: `Token ${token}`
          }
        }
        axios(config).then((response) => {
          console.log("respone", response)
          if (response.data.status === 200) {
            setLoading(false)
            setCategoryList(response.data.data)
            getFaq(1, response.data.data[0].value)
            setActive(response.data.data[0].value)
          } else if (response.data.status === 204) {
            setLoading(false)
            setCategoryList([])
            setFaqList([])
          } else if (response.data.status === 401) {
            dispatch(handleLogout)
            history('/login')
            toast.error(
              <ToastContent message={response.data.message} error={true} />,
              { icon: true, hideProgressBar: true, autoClose: 2000 }
            )
          } else {
            setLoading(false)
            toast.error(
              <ToastContent message={response.data.message} error={true} />,
              { icon: true, hideProgressBar: true, autoClose: 2000 }
            )
          } 
        }).catch((error) => {
          setLoading(false)
          toast.error(
            <ToastContent message={error.message} error={true} />,
            { icon: true, hideProgressBar: true, autoClose: 2000 }
          )
        })
      } 
      const handlePagination = page => {
        setCurrentPage(page.selected)
        getFaq(page.selected + 1, active)
      }
      const CustomPagination = () => {
        return (
          <>
          {faqList.length > 0 &&
          <ReactPaginate
            previousLabel=""
            nextLabel=""
            forcePage={currentPage}
            onPageChange={(page) => handlePagination(page)}
            pageCount={
             paginatedData &&
              (Math.ceil(paginatedData.total / 3))
            }
            breakLabel="..."
            activeClassName="active"
            pageClassName="page-item"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            nextLinkClassName="page-link"
            nextClassName="page-item next"
            previousClassName="page-item prev"
            previousLinkClassName="page-link"
            pageLinkClassName="page-link"
            containerClassName="pagination react-paginate separated-pagination pagination-sm justify-content-end mt-2"
          />
      }
          </>
        )
      }

      const getFiles = (item) => {
        setLoading(true)
        setUploaded([])
        const config = {
          method: "get",
          url: `${apiConfig.api.url_crm}faq/uploaded_files/${item.id}`,
          headers: {
            ContentType: "application/json",
            Authorization: `Token ${token}`
          }
        }
        axios(config).then((response) => {
          console.log('response', response)
          if (response.data.status === 200) {
              setLoading(false)
              setUploaded(response.data.data)
              setUploadOpen(true)
          } else if (response.data.status === 204) {
            setLoading(false)
            setUploaded([])
          } else {
            setLoading(false)
            toast.error(
              <ToastContent message={response.data.message} error={true} />,
              { icon: true, hideProgressBar: true, autoClose: 2000 }
            )
          }
        }).catch((error) => {
          setLoading(false)
          toast.error(
            <ToastContent message={error.message} error={true} />,
            { icon: true, hideProgressBar: true, autoClose: 2000 }
          )
        })
      }
      useEffect(() => {
        getCategories()
      }, [])
    return (
        <div  className="faq-nav">
        <h3>FAQ</h3>
        {loading && <ComponentSpinner/>}
        <Nav  pills className="mt-1 nav-faq">
        {categoryList.length > 0 && categoryList.map((item) => {
          return (
            <NavItem key={item.value}>
            <NavLink
              active={active === item.value}
              onClick={() => {
                toggle(item.value)
                getFaq(1, item.value)
              }}
            >
              {item.label}
            </NavLink>
          </NavItem>
          )
        })
        }
        
        </Nav>
        <Row className="mt-2">
        {
           faqList.length > 0 ?   faqList.map((item, index) => {
                return (
                  <Col className="" md={12} key={index}>
                  <Card className="px-2 py-1">
                    <Row className="d-flex justify-content-center">
                      <Col>
                        <Row>
                          <Col  className="faq-header">
                            {item.question}
                          </Col>
                          <Col className="d-flex justify-content-end">
                            
                             <div style={{cursor:'pointer'}}>
                              <span className='mr-1'>   {open !== `open${item.id}` ? (
                                  <ChevronDown size={15} onClick={() => setOpen(`open${item.id}`)}/>
                                ) : (
                                  <ChevronUp size={15}  onClick={() => setOpen('')} />
                                )}
                            </span>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Collapse isOpen={open === `open${item.id}` } className="mt-1">
                            <div dangerouslySetInnerHTML={{ __html: item.answer }} />
                            <div className='mt-50'>
                            {item.files_uploaded && <Button color='primary' size='sm' onClick={() => getFiles(item)}>View Docs</Button>}
                             </div>
                            </Collapse>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                )
              }) : <Col xs={12}><Card><CardBody className='d-flex justify-content-center'>No FAQ to show</CardBody></Card></Col>
            }
        </Row>
        <Row className="">
            <div className='d-flex justify-content-center'>
            <CustomPagination/>
            </div>
        </Row>
        <Modal isOpen={uploadOpen} >
        <ModalHeader>Uploaded Documents</ModalHeader>
        <ModalBody>
        {uploaded.map((dt, idx) => (<p key={idx}>
                {/* <input onChange={(e) => handleUploadDel(e, dt.name)} style={{marginRight: "10px"}} type="checkbox" name="uploadedchk" /> */}
                <a target="_blank" download={dt.name} rel="noreferrer" href={dt.url}>{dt.name}</a>
                </p>))}
        </ModalBody>
        <ModalFooter className='d-flex justify-content-center'>
        <Button color='primary' outline onClick={() => setUploadOpen(false)}>Close</Button>
        </ModalFooter>
      </Modal>

        </div>
    )
}

export default FaqView
