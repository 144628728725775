// ** React Imports
import { useParams, useNavigate, useLocation } from 'react-router-dom'

import { Fragment, useState, useEffect } from 'react'
// ** Utils
import { getToken, selectThemeColors } from '@utils'

import axios from "axios"
// ** Third Party Componentsimport Flatpickr from 'react-flatpickr'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import '@styles/react/libs/react-select/_react-select.scss' 
import ReactPaginate from 'react-paginate'
import DataTable from 'react-data-table-component'
import { ChevronDown, Plus, MoreVertical, Edit2, Delete, Minus} from 'react-feather'
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Button, 
  FormGroup,
  UncontrolledDropdown, 
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  Input,
  Label,
  Row,
  Col,
  Modal, 
  ModalBody, 
  ModalHeader,
  Badge,
  Spinner
} from 'reactstrap'

import '@styles/react/libs/tables/react-dataTable-component.scss'
import { toast} from 'react-hot-toast'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Select from 'react-select'

import apiConfig from '@configs/apiConfig'
// import Avatar from '@components/avatar'

import { useDispatch } from 'react-redux'
import { handleLogout } from '@store/authentication'

const ToastContent = ({ message = null }) => (
  <>
  {message !== null && (
    <div className='d-flex'>
      <div className='me-1'>
        {/* <Avatar size='sm' color='success' icon={<Edit size={12} />} /> */}
      </div>
      <div className='d-flex flex-column'>
        <div className='d-flex justify-content-between'>
          <span>{message}</span>
        </div>
      </div>
    </div>
  )}
  </>
)

const MySwal = withReactContent(Swal)

const CouponcodeAccess = () => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
  const { id } = useParams() 
  const dispatch = useDispatch()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const name = searchParams.get('name')
  const lead = searchParams.get('lead')
  const history = useNavigate()
  const token = getToken()

  // ** States
  const [userList, setUserList] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [toggleCleared, setToggleCleared] = useState(false)
  const [usersList, setUsersList] = useState([])
  const [user, setUser] = useState([])
  const [show, setShow] = useState(false)
  const [pending, setPending] = useState(false)
  const [loading, setLoading] = useState(false)
  const status = {
    active: { title: 'Active', color: 'light-success' },
    invited: {title: 'Invited', color: 'light-warning'}
  }
 
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const getAccessList = (page, per_page) => {
    setPending(true)
    const config = {
        method: 'get',
        url: `${apiConfig.api.url}discount_code/beneficiary/${id}?page=${page}&per_page=${per_page}`,
        headers: { 
          Authorization: `Token ${token}`
        }
      }
      console.log(config)
      
      axios(config)
      .then(function (response) {
        console.log(response)
        setPending(false)
        if (response.data.status === 200) {
          
          setUserList(response.data)
        } else if (response.data.status === 401) {
          dispatch(handleLogout())
          toast.error(
           <ToastContent message={response.data.message} />,
           {duration:3000}             
          )
          history('/login')  
        } else if (response.data.status === 204) {
          setUserList([])
        } else {
          toast.error(
            <ToastContent message={response.data.message} />,
            {duration:3000}             
           )
        }
      })
      .catch(error => {
        console.log(error)
        setPending(false)
        if (error && error.status === 401) {
          toast.error(
            <ToastContent message={error.message} />,
            { duration:2000 }
          )
        } else if (error) {
          toast.error(
            <ToastContent message={error.message} />,
            { duration:2000 }
          )
        } 
      })
  }

  const getUsersList = () => {
    // /agents/platform/<int:platform_id>?page=1&per_page=10
    const config = {
      method: 'get',
      url: `${apiConfig.api.url}discount_code/list_non_members/${id}`,
      headers: { 
        Authorization: `Token ${token}`
      }
    }
    
    axios(config)
    .then(function (response) {
      console.log(response)
      if (response.data.status === 200) {
        const arr = response.data.data
        const newArrayOfObj = arr.map(function(ar) {
          return (ar) ? (
              { value: ar.user_id, label: `${ar.name}`}
          ) : (null)
        })
        console.log(newArrayOfObj)
        setUsersList(newArrayOfObj)
      } else if (response.data.status === 401) {
        dispatch(handleLogout())
           toast.error(
            <ToastContent message={response.data.message} />,
            {duration:3000}             
          )
          history('/login')
      } 
    })
    .catch(error => {
      console.log(error)
      if (error && error.status === 401) {
        toast.error(
          <ToastContent message={error.message} />,
          { duration:2000 }
        )
      } else if (error) {
        toast.error(
          <ToastContent message={error.message} />,
          { duration:2000 }
        )
      } 
    })
  }
  const handleuser = (e) => {
    console.log(e)
    setUser(e)
  }
  console.log(user)

  // ** Get data on mount
  useEffect(() => {
    getAccessList(1, rowsPerPage)
    getUsersList()
  }, [])
  
  // ** Function to handle per page
  const handlePerPage = e => {
    setRowsPerPage(parseInt(e.target.value))
    getAccessList(1, e.target.value)
  }

  // ** Table Common Column
  const columns = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
    minWidth: '160px',
    cell: row => {
      
        return (
          <div className='d-flex align-items-center'>
            {/* <Avatar color={`light-primary`} className='me-1' content={row.user_info['name'].toUpperCase()} initials /> */}
          
            <div className='user-info text-truncate ml-1'>
              <span className='d-block font-weight-bold text-truncate'>{row.user_info['name']}</span>
              <small>{row.email}</small>
            </div>
          </div>
        )
    }
  },
  {
    name: 'Used Count ',
    selector: 'used_count ',
    sortable: true,
    minWidth: '160px',
    cell: row => {
        return (
        <span>{row.used_count}</span>
        )
    }
  },
  {
    name: 'Status',
    selector: 'is_active',
    minWidth: '130px',
    cell: row => {
      let active = 'pending'

      if (row.is_active === true) {
        active = 'active'
      } else {
        active = 'invited'
      }
      
      return (
        <>
        {(row.is_active === true)  && 
          <Badge color={status[`${active}`].color} pill style={{cursor:"pointer"}}>
          {/* <Badge color={status[`${active}`].color} pill style={{cursor:"pointer"}} onClick={() => ActiveInactive(row)}> */}
          {/* <Badge pill> */}
            {status[`${active}`].title}
          </Badge>
        }
        {(row.is_active === false)  && 
          <Badge color={status[`${active}`].color} pill>
            {status[`${active}`].title}
          </Badge>
        }
        </>
        
      )
    }
  }
]
 // ** Function to handle Pagination
const handlePagination = page => {
    setCurrentPage(page.selected)
    getAccessList(page.selected + 1, rowsPerPage)
}
const handleRowSelected = (row) => {
    console.log(row.selectedRows.length)
    setSelectedRows(row.selectedRows)
}

const addORremoveUser = () => {
    console.log('add')
    let d = {}
    let url = ''
    const user_ids = []
    console.log('user', user)
    if (selectedRows && selectedRows.length === 0) {
      user.map(element => {
        user_ids.push(element.value)
      })
      console.log(user_ids)
      console.log(user)
      url = `${apiConfig.api.url}discount_code/beneficiary/management/${id}?time_zone=${tz}`      
      d = {
          add_members: user_ids
      }
      console.log(d)
      setLoading(true)
      const config = {
        method: 'put',
        url,
        headers: { 
          ContentType: 'application/json',
          Authorization: `Token ${token}`
        }, 
        data : d
      }
      axios(config)
      .then(function (response) {
        setLoading(false)
        console.log(response)
        if (response.data.message === 'success' || response.data.status === 200) {
          toast.success(
            <ToastContent message='Members Added Successfully!' />,
            { duration:2000 }
          )
          getAccessList(currentPage + 1, rowsPerPage)
          getUsersList()
          
        } else if (response.data.status === 401) {
          dispatch(handleLogout())
           toast.error(
            <ToastContent message={response.data.message} />,
            {duration:3000}             
          )
          history('/login')
        } else {
          toast.error(
            <ToastContent message={response.data.message} />,
            { duration:2000 }
          )
        }setShow(!show)
      })
      .catch(error => {
        setLoading(false)
        console.log(error)
        if (error && error.status === 401) {
          toast.error(
            <ToastContent message={error.message} />,
            { duration:2000 }
          )
        } else if (error) {
          toast.error(
            <ToastContent message={error.message} />,
            { duration:2000 }
          )
        } 
      })

    } else {
      return MySwal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1'
        },
        buttonsStyling: false
      }).then(function (result) {
        if (result.value) {
           setPending(true)
            selectedRows.map(element => {
              user_ids.push(element.user_info.id)
            })
            console.log(user_ids)
            url = `${apiConfig.api.url}discount_code/beneficiary/management/${id}?time_zone=${tz}`
            d = { remove_members: user_ids }
            const config = {
              method: 'put',
              url,
              headers: { 
                ContentType: 'application/json',
                Authorization: `Token ${token}`
              }, 
              data : d
            }
           
            axios(config)
            .then(function (response) {
                console.log(response.data.status)
                if (response.data.status === 200) {
                  toast.success(
                    <ToastContent message='Members Removed Successfully!' />,
                    { duration:2000 }
                  )
                  getAccessList(currentPage + 1, rowsPerPage)
                  getUsersList()
                  setSelectedRows([])
                  setToggleCleared(!toggleCleared)
                } else if (response.data.status === 401) {
                  dispatch(handleLogout())
                  toast.error(
                   <ToastContent message={response.data.message} />,
                   {duration:3000}             
                  )
                  history('/login')
                } else {
                  toast.error(
                   <ToastContent message={response.data.message} />,
                   {duration:3000}             
                  )
                }
            }) 
            .catch(error => {
              console.log(error)
              if (error && error.status === 401) {
                toast.error(
                  <ToastContent message={error.message} />,
                  { duration:2000 }
                )
              } else if (error) {
                toast.error(
                  <ToastContent message={error.message} />,
                  { duration:2000 }
                )
              } 
            })
        } else if (result.dismiss === MySwal.DismissReason.cancel) {
            MySwal.fire({
              title: 'Cancelled',
              text: 'Card Delete Cancelled',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success'
              }
            })
          }
        })
    }
  }

// ** Custom Pagination
const CustomPagination = () => (
<ReactPaginate
    previousLabel=''
    nextLabel=''
    forcePage={currentPage}
    onPageChange={page => handlePagination(page)}
    pageCount={userList.pagination.total / rowsPerPage || 1}
    breakLabel='...'
    pageRangeDisplayed={2}
    marginPagesDisplayed={2}
    activeClassName='active'
    pageClassName='page-item'
    breakClassName='page-item'
    breakLinkClassName='page-link'
    nextLinkClassName='page-link'
    nextClassName='page-item next'
    previousClassName='page-item prev'
    previousLinkClassName='page-link'
    pageLinkClassName='page-link'
    containerClassName='pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1 mt-1'
/>
)

console.log(selectedRows)
return (
    <Fragment>
      <Card>
        <CardHeader className='flex-md-row flex-column align-md-items-center align-items-start border-bottom'>
          <CardTitle tag='h4'> {name}</CardTitle>
          <div className='d-flex mt-md-0 mt-1'>
            
            {selectedRows && selectedRows.length > 0 &&
              <Button className='ml-2' color='primary' onClick={() => addORremoveUser()}>
                <Minus size={15} />
                <span className='align-middle ml-50'>Remove Members</span>
              </Button>
            }
            {selectedRows && selectedRows.length === 0 &&
            <Button className='ml-2' color='primary' onClick={() => setShow(true)}>
              <Plus size={15} />
              <span className='align-middle ml-50'>Add Members</span>
            </Button>
            }
            
          </div>
        </CardHeader>
        <CardBody>
          <Row className='justify-content-start mb-1'>
            <Col sm='2'>
              <div className='d-flex align-items-center mt-1 me-2'>
                <Label for='sort-select' className='me-1'>show{lead}</Label>
                <Input
                  className='dataTable-select'
                  type='select'
                  id='sort-select'
                  value={rowsPerPage}
                  onChange={e => handlePerPage(e)}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={75}>75</option>
                  <option value={100}>100</option>
                </Input>
                {/* <Label for='sort-select'>entries</Label> */}
              </div>
            </Col>
          </Row>
          <DataTable
            noHeader
            pagination
            selectableRows
            columns={columns}
            paginationPerPage={rowsPerPage}
            paginationServer
            className='react-dataTable'
            sortIcon={<ChevronDown size={10} />}
            paginationDefaultPage={currentPage + 1}
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleCleared}
            paginationComponent={CustomPagination}
            data={userList.data}
            progressPending={pending}
          />
        </CardBody>
      </Card>
      <Modal isOpen={show} toggle={() => setShow(!show)} className='modal-dialog-centered modal-lg'>
        <ModalHeader className='bg-transparent' toggle={() => setShow(!show)}></ModalHeader>
        <ModalBody className='px-sm-5 pt-50 pb-5'>
        <div className='text-center mb-2'>
            <h1 className='mb-1'>Select Users</h1>
          </div>
          <Row className='gy-1 pt-75'>
            <Col md={12} >
              <FormGroup>
                <Select
                  theme={selectThemeColors}
                  isMulti
                  className='react-select'
                  classNamePrefix='select'
                  isClearable
                  name='types'
                  options={usersList}
                  onChange={handleuser} 
                />
              </FormGroup>
              {/* <DataTable
                noHeader
                pagination
                selectableRows
                columns={columns_user}
                paginationPerPage={rowsPerPage_user}
                paginationServer
                className='react-dataTable'
                sortIcon={<ChevronDown size={10} />}
                paginationDefaultPage={currentPage_user + 1}
                onSelectedRowsChange={handleRowSelected_user}
                // clearSelectedRows={toggleCleared}
                paginationComponent={CustomPagination_user}
                data={userList.data}
              /> */}
            </Col>
          </Row> 
          <Col xs={12} className='text-center mt-2 pt-50'>
            <Button onClick={addORremoveUser} className='me-1' color='primary'style={{marginRight: '20px'}} disabled = {user.length === 0 || loading}>
              {loading && <Spinner size='sm' className='me-1'></Spinner>}Submit
            </Button>
            <Button type='reset' color='secondary' outline
            onClick={() => { setShow(false) }}
 
            // onClick={() => { setShowAgents(!showAgents) }}
            >
              Cancel
            </Button>
          </Col>
        </ModalBody>
      </Modal>
    </Fragment>
  )
}

export default CouponcodeAccess
