import React from 'react'
import { Plus, ChevronDown, ShoppingCart, MoreVertical, Edit, Download, Check, X } from 'react-feather'
import DataTable from 'react-data-table-component'
import ReactPaginate from 'react-paginate'
import '@styles/react/libs/tables/react-dataTable-component.scss'
// import moment from 'moment'
import {
    Card,
     CardHeader,
     CardTitle, 
     Input,
     Label
    
  } from "reactstrap"
 
  const CustomLabel = ({ htmlFor }) => {
    return (
      <Label className='form-check-label' htmlFor={htmlFor}>
        <span className='switch-icon-left'>
          <Check size={14} />
        </span>
        <span className='switch-icon-right'>
          <X size={14} />
        </span>
      </Label>
    )
  }

const OrderStates = () => {
    const data =   [
        {
            created_at: "2023-03-21 16:31:41",
            lead_type_id: 2,
            id: 1,
            order_items_id: 65,
            price_per_quantity: 0.01,
            quantity: 1,
            shopping_cart_id: "e02b9bad-660b-47b9-8088-ebd572774391",
            total_cost: 0.01,
            type_: 1,
            state_name:'AL',
            item_name:'1 month old complete',
            unit_price:0.001,
            quantity:10
        },
        {
            created_at: "2023-03-21 16:31:41",
            lead_type_id: 2,
            id: 2,
            order_items_id: 65,
            price_per_quantity: 0.01,
            quantity: 1,
            shopping_cart_id: "e02b9bad-660b-47b9-8088-ebd572774391",
            total_cost: 0.01,
            type_: 1,
            state_name:'AL',
            item_name:'1 month old complete',
            unit_price:0.001,
            quantity:10
        },
        {
            created_at: "2023-03-21 16:31:41",
            lead_type_id: 2,
            id: 3,
            order_items_id: 65,
            price_per_quantity: 0.01,
            quantity: 1,
            shopping_cart_id: "e02b9bad-660b-47b9-8088-ebd572774391",
            total_cost: 0.01,
            type_: 1,
            state_name:'AL',
            item_name:'1 month old complete',
            unit_price:0.001,
            quantity:10
        },
        {
            created_at: "2023-03-21 16:31:41",
            lead_type_id: 2,
            id: 4,
            order_items_id: 65,
            price_per_quantity: 0.01,
            quantity: 1,
            shopping_cart_id: "e02b9bad-660b-47b9-8088-ebd572774391",
            total_cost: 0.01,
            type_: 1,
            state_name:'AL',
            item_name:'1 month old complete',
            unit_price:0.001,
            quantity:10
        },
        {
            created_at: "2023-03-21 16:31:41",
            lead_type_id: 2,
            id: 5,
            order_items_id: 65,
            price_per_quantity: 0.01,
            quantity: 1,
            shopping_cart_id: "e02b9bad-660b-47b9-8088-ebd572774391",
            total_cost: 0.01,
            type_: 1,
            state_name:'AL',
            item_name:'1 month old complete',
            unit_price:0.001,
            quantity:10
        },
        {
            created_at: "2023-03-21 16:31:41",
            lead_type_id: 2,
            id: 6,
            order_items_id: 65,
            price_per_quantity: 0.01,
            quantity: 1,
            shopping_cart_id: "e02b9bad-660b-47b9-8088-ebd572774391",
            total_cost: 0.01,
            type_: 1,
            state_name:'AL',
            item_name:'1 month old complete',
            unit_price:0.001,
            quantity:10
        },
        {
            created_at: "2023-03-21 16:31:41",
            lead_type_id: 2,
            id: 7,
            order_items_id: 65,
            price_per_quantity: 0.01,
            quantity: 1,
            shopping_cart_id: "e02b9bad-660b-47b9-8088-ebd572774391",
            total_cost: 0.01,
            type_: 1,
            state_name:'AL',
            item_name:'1 month old complete',
            unit_price:0.001,
            quantity:10
        },
        {
            created_at: "2023-03-21 16:31:41",
            lead_type_id: 2,
            id: 8,
            order_items_id: 65,
            price_per_quantity: 0.01,
            quantity: 1,
            shopping_cart_id: "e02b9bad-660b-47b9-8088-ebd572774391",
            total_cost: 0.01,
            type_: 1,
            state_name:'AL',
            item_name:'1 month old complete',
            unit_price:0.001,
            quantity:10
        }
    ]
    const columns = [
        {
            name: 'Id',
            selector: 'id',
            sortable: true,
            // sortFunction: caseInsensitiveSort,
            minWidth: '100px',
            cell: row => (
              <div className='d-flex align-items-center'>
                <div className='user-info text-truncate'>
                  <span className='d-block font-weight-bold text-truncate'>#{row.id}</span>
                </div>
              </div>
            )
          },
          {
            name: 'State',
            selector: 'state_name',
            sortable: true,
            // sortFunction: caseInsensitiveSort,
            minWidth: '100px',
            cell: row => (
              <div className='d-flex align-items-center'>
                <div className='user-info text-truncate'>
                  <span className='d-block font-weight-bold text-truncate'>{row.state_name}</span>
                </div>
              </div>
            )
          },
        
       
          {
            name: 'Quantity',
            selector: 'quantity',
            sortable: true,
            // sortFunction: caseInsensitiveSort,
            minWidth: '100px',
            cell: row => (
              <div className='d-flex align-items-center'>
                <div className='user-info text-truncate'>
                  <span className='d-block font-weight-bold text-truncate'>{row.quantity}</span>
                </div>
              </div>
            )
          },
          {
            name: 'Exclusive For Aria Members',
            selector: 'only_for_aria_member',
            minWidth: '130px',
            cell: row => {
                return (
                <>
                {console.log(row)}
                    <div className='d-flex flex-column'>
                        <div className='form-switch form-check-success'>
                        <Input type='switch' onChange={() => statusSettings(row)}  checked={row.only_for_aria_member} id={`switch_${row.id}`} name={`switch_${row.id}`} />
                        <CustomLabel htmlFor={`switch_${row.id}`} />
                        </div>
                    </div>
                </>
                )
              }
          }
       
       
      ]
    console.log(data)
    console.log(columns)

    return (
        <div>
            <Card>
            <CardHeader className='flex-md-row flex-column align-md-items-center align-items-start border-bottom'>
          <CardTitle tag='h4'>Order Summary</CardTitle>
        </CardHeader>
                <div className='ps-2 pe-2 pt-2 pb-2'>
                    <div className="d-flex align-items-center mb-1">
                    <Label for='sort-select'>show</Label>
              <Input
                className='dataTable-select'
                type='select'
                id='sort-select'
                // value={rowsPerPage}
                // onChange={e => handlePerPage(e)}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={75}>75</option>
                <option value={100}>100</option>
              </Input>
                    </div>
                    <div>
            <DataTable
          noHeader
          pagination
          columns={columns}
        //   paginationPerPage={rowsPerPage}
        //   paginationServer
          className='react-dataTable'
          sortIcon={<ChevronDown size={10} />}
        //   paginationDefaultPage={currentPage}
        //   paginationComponent={CustomPagination}
          data={data}
        //   onSelectedRowsChange={handleChange}
        />  
        </div>
        </div>
            </Card>
        </div>
    )

}

export default OrderStates
