import React, { useState, useEffect, Fragment } from "react"
import {
  Card,
  Row,
  Col,
  Label,
  Button,
  Input,
  Collapse,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  CardTitle,
  CardColumns,
  CardBody,
  Popover,
  PopoverHeader,
  PopoverBody,
  ListGroupItem,
  ListGroup,
  Spinner
} from "reactstrap"
import Select from "react-select"
import ReactPaginate from "react-paginate"
import {
  ChevronDown,
  Trash,
  Edit2,
  ChevronUp,
  Delete,
  Check,
  X,
  Plus,
  FileText,
  Eye,
  EyeOff,
  DownloadCloud
} from "react-feather"
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML
} from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import draftToHtml from "draftjs-to-html"
import "@styles/react/libs/editor/editor.scss"
import { getToken } from "@utils"
import axios from "axios"
import apiConfig from "@configs/apiConfig"
import { toast} from 'react-hot-toast'
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import Avatar from "@components/avatar"
import { useDropzone } from 'react-dropzone'

// import Uppy from "@uppy/core"
// import { DragDrop } from "@uppy/react"
// import thumbnailGenerator from "@uppy/thumbnail-generator"
// import "uppy/dist/uppy.css"
// import "@uppy/status-bar/dist/style.css"
import ComponentSpinner from "../../@core/components/spinner/Loading-spinner"
import { useDispatch } from "react-redux"
import { handleLogout } from '@store/authentication'
import { useNavigate } from 'react-router-dom'

const FaqCrud = () => {
  const history = useNavigate()
  const dispatch = useDispatch()
  const token = getToken()
  const MySwal = withReactContent(Swal)
  const ToastContent = ({ message = null, error = null }) => (
    <Fragment>
      <div className="toastify-header">
        <div className="d-flex justify-content-between align-items-center">
          {!error && (
            <Avatar size="sm" color="success" icon={<Check size={12} />} />
          )}
          {error && <Avatar size="sm" color="danger" icon={<X size={12} />} />}
          <h6 className="align-self-center fw-bold ms-50 mb-0">{message}</h6>
        </div>
      </div>
      <div className="toastify-body"></div>
    </Fragment>
  )

  
  const [open, setOpen] = useState("")
  const [modalOpen, setModalOpen] = useState(false)
  const [description, setDescription] = useState(EditorState.createEmpty())
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [editPopoverOpen, setEditPopoverOpen] = useState(false)
  const [categoryList, setCategoryList] = useState([])
  const [newCategory, setNewCategory] = useState("")
  const [categoryEditId, setCategoryEditId] = useState("")
  const [toUpload, setToUpload] = useState([])
  const [faqList, setFaqList] = useState([{}])
  const [faqQuestion, setFaqQuestion] = useState("")
  const [faqError, setFaqError] = useState(false)
  const [descriptionError, setDescriptionError] = useState(false)
  const [modalCategory, setModalCategory] = useState("")
  const [mainCategory, setMainCategory] = useState("")
  const [categoryError, setCategoryError] = useState(false)
  const [editFlag, setEditFlag] = useState(false)
  const [editId, setEditId] = useState("")
  const [currentPage, setCurrentPage] = useState(0)
  const [paginatedData, setPaginatedData] = useState({})
  const [uploaded, setUploaded] = useState([])
  const [uploadOpen, setUploadOpen] = useState(false)
  const [removeFile, setRemoveFile] = useState([])
  const [loading, setLoading] = useState(false)
  const [addLoading, setAddLoading] = useState(false)
//   const [files, setFiles] = useState([])
//   const uppy = new Uppy({
//     autoProceed: true,
//     restrictions: {
//       allowedFileTypes: [
//         ".png",
//         ".jpeg",
//         ".jpg",
//         ".pdf",
//         ".mp4",
//         ".mov",
//         ".avi",
//         ".mkv"
//       ],
//       maxFileSize: 50 * 1024 * 1024
//     }
//   })
 const { getRootProps, getInputProps } = useDropzone({
    onDrop: acceptedFiles => {
      setToUpload([...toUpload, ...acceptedFiles.map(file => Object.assign(file))])
    }
  })
  const handleCategory = (selectedOption) => {
    setModalCategory(selectedOption)
    setCategoryError(false)
  }

//   uppy.use(thumbnailGenerator)

//   uppy.on("thumbnail:generated", (file, preview) => {
//     const arr = previewArr
//     arr.push(file)
//     setPreviewArr([...arr])
//   })

//   uppy.on("complete", (result) => {
//     const files = result.successful
//     const fldt = [...toUpload]
//     for (const dt of files) {
//       fldt.push(dt.data)
//     }
//     console.log(fldt)
//     setToUpload(fldt)
//   })

  const renderFilePreview = (file) => {
    if (file.type && file.type.startsWith("image")) {
      return (
        <img
          className="rounded"
          alt={file.name}
          src={URL.createObjectURL(file)}
          height="28"
          width="28"
        />
      )
    } else {
      return <FileText size="28" />
    }
  }

  const handleRemoveFile = (file, text) => {
    let uploadedFiles
    if (text === "uploaded") {
      uploadedFiles = uploaded
      setRemoveFile([...removeFile, file.name])
    } else {
      uploadedFiles = toUpload
    }
    const filtered = uploadedFiles.filter((i) => i.name !== file.name)
    if (text === "uploaded") {
      setUploaded([...filtered])
    } else {
      setToUpload([...filtered])
    }
  }
  const fileList = toUpload.map((file, index) => (
    <ListGroupItem
      key={`${file.name}-${index}`}
      className="d-flex align-items-center justify-content-between"
    >
      <div className="file-details d-flex align-items-center">
        <div className="file-preview me-1">{renderFilePreview(file)}</div>
        <div>
          <p className="file-name mb-0">{file.name}</p>
        </div>
      </div>
      <Button
        color="danger"
        outline
        size="sm"
        className="btn-icon"
        onClick={() => handleRemoveFile(file, "toupload")}
      >
        <X size={14} />
      </Button>
    </ListGroupItem>
  ))
  const uploadedList = uploaded.map((file, index) => (
    <ListGroupItem
      key={`${file.name}-${index}`}
      className="d-flex align-items-center justify-content-between"
    >
      <div className="file-details d-flex align-items-center">
        <div className="file-preview me-1">{renderFilePreview(file)}</div>
        <div>
          <p className="file-name mb-0">{file.name}</p>
        </div>
      </div>
      <Button
        color="danger"
        outline
        size="sm"
        className="btn-icon"
        onClick={() => handleRemoveFile(file, "uploaded")}
      >
        <X size={14} />
      </Button>
    </ListGroupItem>
  ))
  const addOpen = () => {
    setFaqQuestion("")
    setDescription(EditorState.createEmpty())
    setFaqError(false)
    setDescriptionError(false)
    setModalCategory(false)
    setCategoryError(false)
    setModalOpen(true)
    setEditFlag(false)
    setUploaded([])
    setToUpload([])
  }
  const convertFrom_Html = (msg) => {
    const blocksFromHTML = convertFromHTML(msg)

    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    )
    return state
  }

  const getFiles = (item, text) => {
    setLoading(true)
    setUploaded([])
    const config = {
      method: "get",
      url: `${apiConfig.api.url_crm}faq/uploaded_files/${item.id}`,
      headers: {
        ContentType: "application/json",
        Authorization: `Token ${token}`
      }
    }
    axios(config)
      .then((response) => {
        console.log("response", response)
        if (response.data.status === 200) {
          setLoading(false)
          setUploaded(response.data.data)
          if (text === "view") {
            setUploadOpen(true)
          } else if (text === "edit") {
            setUploadOpen(false)
          }
        } else if (response.data.status === 204) {
          setLoading(false)
          setUploaded([])
        } else {
          setLoading(false)
          toast.error(
            <ToastContent message={response.data.message} error={true} />,
            { icon: true, hideProgressBar: true, autoClose: 2000 }
          )
        }
      })
      .catch((error) => {
        toast.error(<ToastContent message={error.message} error={true} />, {
          icon: true,
          hideProgressBar: true,
          autoClose: 2000
        })
      })
  }
  const editOpen = (item) => {
    console.log("item", item)
    setEditId(item.id)
    setEditFlag(true)
    setFaqQuestion(item.question)
    setModalCategory(item.category.id)
    const data = categoryList.findIndex((u) => u.value === item.category.id)
    console.log("edit-cat", data)
    setModalCategory(categoryList[data])
    const ans = convertFrom_Html(item.answer)
    setDescription(EditorState.createWithContent(ans))
    getFiles(item, "edit")
    setToUpload([])
    setRemoveFile([])
    setModalOpen(true)
  }
  const getFaq = (page, id) => {
    setLoading(true)
    const config = {
      method: "get",
      url: `${apiConfig.api.url_crm}faq/admin/list?page=${parseInt(
        page
      )}&per_page=3&category_id=${id}&is_crm=${false}`,
      headers: {
        ContentType: "application/json",
        Authorization: `Token ${token}`
      }
    }
    axios(config)
      .then((response) => {
        console.log("respone", response)
        if (response.data.status === 200) {
          setLoading(false)
          setFaqList(response.data.data)
          setPaginatedData(response.data.pagination)
        } else if (response.data.status === 204) {
          setLoading(false)
          setFaqList([])
        } else {
          setLoading(false)
          toast.error(
            <ToastContent message={response.data.message} error={true} />,
            { icon: true, hideProgressBar: true, autoClose: 2000 }
          )
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error(<ToastContent message={error.message} error={true} />, {
          icon: true,
          hideProgressBar: true,
          autoClose: 2000
        })
      })
  }
  const getCategories = () => {
    setLoading(true)
    const config = {
      method: "get",
      url: `${apiConfig.api.url_crm}faq/category/list?is_crm=${false}`,
      headers: {
        ContentType: "application/json",
        Authorization: `Token ${token}`
      }
    }
    axios(config)
      .then((response) => {
        console.log("respone", response)
        if (response.data.status === 200) {
          setLoading(false)
          setCategoryList(response.data.data)
          setMainCategory(response.data.data[0])
          getFaq(1, response.data.data[0].value)
        } else if (response.data.status === 204) {
          setLoading(false)
          setCategoryList([])
          setFaqList([])
          setMainCategory('')
        } else if (response.data.status === 401) {
          dispatch(handleLogout)
          history('/login')
          toast.error(
            <ToastContent message={response.data.message} error={true} />,
            { icon: true, hideProgressBar: true, autoClose: 2000 }
          )
        } else {
          setLoading(false)
          toast.error(
            <ToastContent message={response.data.message} error={true} />,
            { icon: true, hideProgressBar: true, autoClose: 2000 }
          )
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error(<ToastContent message={error.message} error={true} />, {
          icon: true,
          hideProgressBar: true,
          autoClose: 120000
        })
      })
  }
  const handleMainCategory = (selectedOption) => {
    setMainCategory(selectedOption)
    getFaq(1, selectedOption.value)
  }
  const addCategory = () => {
    const d = { name: newCategory, is_crm: false }
    const config = {
      method: "post",
      url: `${apiConfig.api.url_crm}faq/category`,
      headers: {
        ContentType: "application/json",
        Authorization: `Token ${token}`
      },
      data: d
    }
    axios(config)
      .then((response) => {
        console.log("respone", response)
        // setPopoverOpen(false)
        if (response.data.status === 200) {
          setPopoverOpen(false)
          setNewCategory('')
          getCategories()
          toast.success(
            <ToastContent message={response.data.message} error={false} />,
            { icon: true, hideProgressBar: true, autoClose: 2000 }
          )
        } else {
          toast.error(
            <ToastContent message={response.data.message} error={true} />,
            { icon: true, hideProgressBar: true, autoClose: 2000 }
          )
        }
      })
      .catch((error) => {
        toast.error(<ToastContent message={error.message} error={true} />, {
          icon: true,
          hideProgressBar: true,
          autoClose: 2000
        })
      })
  }

  const editCategory = () => {
    const d = { name: newCategory, is_crm:false }
    const config = {
      method: "put",
      url: `${apiConfig.api.url_crm}faq/category/${categoryEditId}`,
      headers: {
        ContentType: "application/json",
        Authorization: `Token ${token}`
      },
      data: d
    }
    axios(config)
      .then((response) => {
        console.log("respone", response)
        // setPopoverOpen(false)
        if (response.data.status === 200) {
          setEditPopoverOpen(false)
          getCategories()
          toast.success(
            <ToastContent message={response.data.message} error={false} />,
            { icon: true, hideProgressBar: true, autoClose: 2000 }
          )
        } else {
          toast.error(
            <ToastContent message={response.data.message} error={true} />,
            { icon: true, hideProgressBar: true, autoClose: 2000 }
          )
        }
      })
      .catch((error) => {
        toast.error(<ToastContent message={error.message} error={true} />, {
          icon: true,
          hideProgressBar: true,
          autoClose: 2000
        })
      })
  }
  const categoryEdit = (item) => {
    console.log("item", item)
    setNewCategory(item.label)
    setCategoryEditId(item.value)
    setEditPopoverOpen(true)
  }
  const handleDelete = (id, text) => {
    return MySwal.fire({
      title: "Are you sure?",
      text: "You want to delete!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Confirm!",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger ms-1"
      },
      buttonsStyling: false
    }).then(function (result) {
      if (result.value) {
        let apiUrl
        if (text === "faq") {
          apiUrl = `${apiConfig.api.url_crm}faq/${id}`
        } else {
          apiUrl = `${apiConfig.api.url_crm}faq/category/${id}`
        }
        const config = {
          method: "delete",
          url: apiUrl,
          headers: {
            ContentType: "application/json",
            Authorization: `Token ${token}`
          }
        }
        console.log(config)
        axios(config)
          .then((response) => {
            console.log(response.data.status)
            if (response.data.status === 200) {
              setCurrentPage(0)
              if (text === "faq") {
                getFaq(1, mainCategory.value)
              } else {
                getFaq(1, mainCategory.value)
                getCategories()
              }
              MySwal.fire({
                icon: "success",
                title: "Deleted!",
                text: "Successfully Deleted.",
                customClass: {
                  confirmButton: "btn btn-success"
                }
              })
            } else if (
              response.data.status > 200 &&
              response.data.status < 299
            ) {
              toast.error(
                <ToastContent message={response.data.message} error={true} />,
                { icon: true, hideProgressBar: true, autoClose: 2000 }
              )
            } else if (response.data.status === 401) {
              toast.error(
                <ToastContent message={response.data.message} error={true} />,
                { icon: true, hideProgressBar: true, autoClose: 2000 }
              )
              // history.push("/login")
            } else if (response.data.status === 422) {
              if (text !== "faq") {
                toast.error(
                  <ToastContent
                    message="This category cannot be deleted as it contains FAQ"
                    error={true}
                  />,
                  { icon: true, hideProgressBar: true, autoClose: 2000 }
                )
              }
              // history.push("/login")
            } else {
              toast.error(
                <ToastContent message={response.data.message} error={true} />,
                { icon: true, hideProgressBar: true, autoClose: 2000 }
              )
            }
          })
          .catch((error) => {
            console.log(error)
            toast.error(<ToastContent message={error.message} error={true} />, {
              icon: true,
              hideProgressBar: true,
              autoClose: 2000
            })
          })
      } else if (result.dismiss === MySwal.DismissReason.cancel) {
        MySwal.fire({
          title: "Cancelled",
          text: "Update Cancelled",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-success"
          }
        })
      }
    })
  }
  const statusChange = (item) => {
    return MySwal.fire({
      title: "Are you sure?",
      text: "You want to update!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Confirm!",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger ms-1"
      },
      buttonsStyling: false
    }).then(function (result) {
      if (result.value) {
        const config = {
          method: "patch",
          url: `${apiConfig.api.url_crm}faq/enable_disable/${item.id}`,
          headers: {
            ContentType: "application/json",
            Authorization: `Token ${token}`
          },
          data: { is_active: !item.is_active }
        }
        console.log(config)
        axios(config)
          .then((response) => {
            console.log(response.data.status)
            if (response.data.status === 200) {
              setCurrentPage(0)
              getFaq(1, mainCategory.value)
              MySwal.fire({
                icon: "success",
                title: "Updated!",
                text: "Successfully updated.",
                customClass: {
                  confirmButton: "btn btn-success"
                }
              })
            } else if (
              response.data.status > 200 &&
              response.data.status < 299
            ) {
              toast.error(
                <ToastContent message={response.data.message} error={true} />,
                { icon: true, hideProgressBar: true, autoClose: 2000 }
              )
            } else if (response.data.status === 401) {
              toast.error(
                <ToastContent message={response.data.message} error={true} />,
                { icon: true, hideProgressBar: true, autoClose: 2000 }
              )
              // history.push("/login")
            } else {
              toast.error(
                <ToastContent message={response.data.message} error={true} />,
                { icon: true, hideProgressBar: true, autoClose: 2000 }
              )
            }
          })
          .catch((error) => {
            console.log(error)
            toast.error(<ToastContent message={error.message} error={true} />, {
              icon: true,
              hideProgressBar: true,
              autoClose: 2000
            })
          })
      } else if (result.dismiss === MySwal.DismissReason.cancel) {
        MySwal.fire({
          title: "Cancelled",
          text: "Update Cancelled",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-success"
          }
        })
      }
    })
  }
  const addOrEditFaq = () => {
    const formData = new FormData()
    setAddLoading(true)
    formData.append("question", faqQuestion)
    formData.append(
      "answer",
      draftToHtml(convertToRaw(description.getCurrentContent()))
    )
    formData.append("category_id", modalCategory.value)
    formData.append(
      "files_uploaded",
      toUpload.length < 1 && uploaded.length < 1 ? "false" : "true"
    )
    console.log("removed_files", removeFile)
    if (toUpload.length > 0) {
      toUpload.map((fl, idx) => {
        formData.append(`file${idx + 1}`, fl)
      })
    }
    if (editFlag) {
      formData.append("removed_files", [removeFile])
    }

    const d = formData
    let config = {}
    if (!editFlag) {
      config = {
        method: "post",
        url: `${apiConfig.api.url1}faq`,
        headers: {
          ContentType: "multipart/form-data",
          Authorization: `Token ${token}`
        },
        data: d
      }
    } else {
      config = {
        method: "put",
        url: `${apiConfig.api.url1}faq/${editId}`,
        headers: {
          ContentType: "multipart/form-data",
          Authorization: `Token ${token}`
        },
        data: d
      }
    }
    axios(config)
      .then((response) => {
        console.log(response)
        if (response.data.status === 200) {
          setAddLoading(false)
          setModalOpen(false)
          toast.success(
            <ToastContent message={response.data.message} error={false} />,
            { icon: true, hideProgressBar: true, autoClose: 2000 }
          )
          setCurrentPage(0)
          setMainCategory(modalCategory)
          getFaq(1, modalCategory.value)
        } else {
          setAddLoading(false)
          toast.error(
            <ToastContent message={response.data.message} error={true} />,
            { icon: true, hideProgressBar: true, autoClose: 2000 }
          )
        }
      })
      .catch((error) => {
        setAddLoading(false)
        toast.error(<ToastContent message={error.message} error={true} />, {
          icon: true,
          hideProgressBar: true,
          autoClose: 2000
        })
      })
  }
  useEffect(() => {
    getCategories()
  }, [])
  // useEffect(() => {
  //   getFaq(1)
  // }, [])

  
  const handlePagination = (page) => {
    setCurrentPage(page.selected)
    getFaq(page.selected + 1, mainCategory.value)
  }
  const CustomPagination = () => {
    return (
      <>
        {faqList.length > 0 && (
          <ReactPaginate
            previousLabel=""
            nextLabel=""
            forcePage={currentPage}
            onPageChange={(page) => handlePagination(page)}
            pageCount={paginatedData && Math.ceil(paginatedData.total / 3)}
            breakLabel="..."
            activeClassName="active"
            pageClassName="page-item"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            nextLinkClassName="page-link"
            nextClassName="page-item next"
            previousClassName="page-item prev"
            previousLinkClassName="page-link"
            pageLinkClassName="page-link"
            containerClassName="pagination react-paginate separated-pagination pagination-sm justify-content-end mt-2"
          />
        )}
      </>
    )
  }
  const handleRemoveAllFiles = () => {
    setToUpload([])
  }

  return (
    <>
      {loading && <ComponentSpinner />}
      <Row>
        <Col md={6}>
          <h3> Manage FAQ</h3>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col md={6}>
          {/* <Label>Choose a Category</Label> */}
          <Select
            options={categoryList}
            value={mainCategory}
            onChange={handleMainCategory}
          ></Select>
        </Col>
        <Col md={6} className="">
          <Button color="primary" size="sm" className="" onClick={addOpen}>
            <span className="align-self-middle">
              <Plus size={20} />
            </span>
            <span className="ml-50">Add New FAQ</span>
          </Button>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xl={9}>
          <Row>
            {faqList.length > 0 ? (
              faqList.map((item, index) => {
                return (
                  <Col className="" md={12} key={index}>
                    <Card className="px-2 py-1">
                      <Row className="d-flex justify-content-center">
                        <Col>
                          <Row>
                            <Col className="faq-header">{item.question}</Col>
                            <Col className="d-flex justify-content-end">
                              <div style={{ cursor: "pointer" }}>
                                <span
                                  className="me-1"
                                  title="Click here to enable/disable FAQ"
                                >
                                  {item.is_active ? (
                                    <Eye
                                      size={15}
                                      onClick={() => statusChange(item)}
                                    ></Eye>
                                  ) : (
                                    <EyeOff
                                      size={15}
                                      onClick={() => statusChange(item)}
                                    />
                                  )}
                                </span>
                                <span className="me-1">
                                  <Edit2
                                    size={15}
                                    onClick={() => editOpen(item)}
                                  />
                                </span>
                                <span className="me-1">
                                  <Trash
                                    size={15}
                                    onClick={() => handleDelete(item.id, "faq")}
                                  />
                                </span>
                                <span className="me-1">
                                  {" "}
                                  {open !== `open${item.id}` ? (
                                    <ChevronDown
                                      size={15}
                                      onClick={() => setOpen(`open${item.id}`)}
                                    />
                                  ) : (
                                    <ChevronUp
                                      size={15}
                                      onClick={() => setOpen("")}
                                    />
                                  )}
                                </span>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Collapse
                                isOpen={open === `open${item.id}`}
                                className="mt-1"
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.answer
                                  }}
                                />
                                <div className="mt-50">
                                  {item.files_uploaded && (
                                    <Button
                                      color="primary"
                                      size="sm"
                                      onClick={() => getFiles(item, "view")}
                                    >
                                      View Docs
                                    </Button>
                                  )}
                                </div>
                              </Collapse>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                )
              })
            ) : (
              <Col xs={12}>
                <Card>
                  <CardBody className="d-flex justify-content-center">
                    No FAQ to show
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
          <Row className="">
            <div className="d-flex justify-content-center" >
            <CustomPagination />
            </div>
          </Row>
        </Col>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle>Categories</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="category-body">
                {categoryList.map((item) => {
                  return (
                    <div
                      className="d-flex justify-content-between border-bottom mb-1"
                      key={item.value}
                    >
                      <h5>{item.label}</h5>
                      <span style={{ cursor: "pointer" }}>
                        <Edit2
                          size={17}
                          id="editCategory"
                          onClick={() => categoryEdit(item)}
                        ></Edit2>{" "}
                        <Trash
                          onClick={() => handleDelete(item.value)}
                          size={17}
                          className="ml-50"
                        ></Trash>
                      </span>
                    </div>
                  )
                })}
              </div>
              {categoryList.length > 0 && (
                <Popover
                  placement="left"
                  target="editCategory"
                  isOpen={editPopoverOpen}
                  toggle={() => {
                    setEditPopoverOpen(!editPopoverOpen)
                  }}
                >
                  <PopoverHeader className="d-flex justify-content-between">
                    Edit Category{" "}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => setEditPopoverOpen(false)}
                    >
                      <X size={20} />
                    </span>
                  </PopoverHeader>
                  <PopoverBody>
                    <Input
                      type="text"
                      placeholder="Enter Category Name"
                      onChange={(event) => setNewCategory(event.target.value)}
                      value={newCategory}
                    ></Input>
                    <div className="d-flex justify-content-center mt-1">
                      <Button
                        type="submit"
                        color="primary"
                        size="sm"
                        onClick={editCategory}
                        disabled={newCategory === ""}
                      >
                        Submit
                      </Button>
                    </div>
                  </PopoverBody>
                </Popover>
              )}

              <div className="mt-1 d-flex justify-content-center">
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => setPopoverOpen(true)}
                  id="controlledPopover"
                >
                  Add New
                </Button>
              </div>
              <Popover
                placement="bottom"
                target="controlledPopover"
                isOpen={popoverOpen}
                toggle={() => {
                  setPopoverOpen(!popoverOpen)
                  setNewCategory("")
                }}
              >
                <PopoverHeader className="d-flex justify-content-between">
                  Add New Category{" "}
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => setPopoverOpen(false)}
                  >
                    <X size={20} />
                  </span>
                </PopoverHeader>
                <PopoverBody>
                  <Input
                    type="text"
                    placeholder="Enter Category Name"
                    onChange={(event) => setNewCategory(event.target.value)}
                  ></Input>
                  <div className="d-flex justify-content-center mt-1">
                    <Button
                      type="submit"
                      color="primary"
                      size="sm"
                      onClick={addCategory}
                      disabled={newCategory === ""}
                    >
                      Submit
                    </Button>
                  </div>
                </PopoverBody>
              </Popover>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal isOpen={modalOpen} size="lg">
        <ModalHeader>{editFlag ? "Edit FAQ" : "Add FAQ"}</ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={12} className="mb-1">
              <Label>Category</Label>
              <Select
                autoFocus
                options={categoryList}
                value={modalCategory}
                onChange={handleCategory}
                id="category"
              ></Select>
              {categoryError && (
                <span className="text-danger">This field is required</span>
              )}
            </Col>
            <Col sm={12} className="mb-1">
              <Label>Question</Label>
              <Input
                type="text"
                id="question"
                name="question"
                value={faqQuestion}
                onChange={(event) => {
                  setFaqQuestion(event.target.value)
                  setFaqError(false)
                }}
              ></Input>
              {faqError && (
                <span className="text-danger">This field is required</span>
              )}
            </Col>
            <Col sm={12} className="mb-1">
              <Label>Description</Label>
              <Editor
                name="editorContent"
                id="editorContent"
                editorState={description}
                onEditorStateChange={(data) => {
                  setDescription(data)
                  setDescriptionError(false)
                }}
              />
              {descriptionError && (
                <span className="text-danger">This field is required</span>
              )}
            </Col>
            <Col sm="12" className="faq-file mb-2">
              {/* <DragDrop uppy={uppy} /> */}
              <div {...getRootProps({ className: 'dropzone' })}>
               <input {...getInputProps()} />
              <div className='d-flex align-items-center justify-content-center flex-column'>
                  <DownloadCloud size={64} />
                  <h5>Drop Files here or click to upload</h5>
                  <p className='text-secondary'>
                  Drop files here or click{' '}
                  <a href='/' onClick={e => e.preventDefault()}>
                      browse
                  </a>{' '}
                  thorough your machine
                  </p>
              </div>
             </div>
              {toUpload.length ? (
                <Fragment>
                  <ListGroup className="my-2">{fileList}</ListGroup>
                  <div className="d-flex justify-content-end">
                    {!editFlag && (
                      <Button
                        className="me-1"
                        color="danger"
                        outline
                        onClick={handleRemoveAllFiles}
                      >
                        Remove All
                      </Button>
                    )}
                  </div>
                </Fragment>
              ) : null}
              {editFlag && uploaded.length > 0 && (
                <h4 className="mt-1">Uploaded Files</h4>
              )}
              {uploaded.length ? (
                <Fragment>
                  <ListGroup className="my-2">{uploadedList}</ListGroup>
                </Fragment>
              ) : null}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-end">
          <Button color="secondary" outline onClick={() => setModalOpen(false)}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={addLoading === true}
            onClick={() => {
              let clear = true
              const desc = draftToHtml(
                convertToRaw(description.getCurrentContent())
              )
              if (!modalCategory.value) {
                setCategoryError(true)
                clear = false
              }
              if (faqQuestion === "") {
                setFaqError(true)
                clear = false
              }
              if (desc === "<p></p>\n") {
                setDescriptionError(true)
                clear = false
              }
              if (clear) {
                addOrEditFaq()
              }
            }}
          >
            {addLoading ? (
              <>
                <Spinner color="white" size="sm" />
                <span className="ms-50">Loading...</span>{" "}
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={uploadOpen}>
        <ModalHeader>Uploaded Documents</ModalHeader>
        <ModalBody>
          {uploaded.map((dt, idx) => (
            <p key={idx}>
              {/* <input onChange={(e) => handleUploadDel(e, dt.name)} style={{marginRight: "10px"}} type="checkbox" name="uploadedchk" /> */}
              <a
                target="_blank"
                download={dt.name}
                rel="noreferrer"
                href={dt.url}
              >
                {dt.name}
              </a>
            </p>
          ))}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Button color="primary" outline onClick={() => setUploadOpen(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default FaqCrud
