import React, { useEffect, useState} from 'react'
import { Check, X } from "react-feather"
// ** Custom Components
import Avatar from '@components/avatar'
// ** Reactstrap Imports
import { Card, CardBody, CardText, Row, Col, Alert } from 'reactstrap'
import { Link} from "react-router-dom"
// import axios from 'axios'
import ComponentSpinner from '@components/spinner/Loading-spinner'

const Thankyou = () => {
  const [loading, setLoading] = useState(false)
  const orderDetails = JSON.parse(localStorage.getItem('orderDetails'))
  console.log(orderDetails)
  useEffect(() => {
    setLoading(false)
    if (orderDetails) {
      console.log('entered useeffect')
      const grSetting = {
        grTemplateSlug: "genius-bluebird-template-86",
        grCustomerEmail: `${orderDetails && orderDetails.email}`,
        grReference: `Order ID: ${orderDetails && orderDetails.order_id}`,
        grPaymentAmount: `${orderDetails && orderDetails.order_total}`
      }
  
      const scriptTag = document.createElement('script')
      scriptTag.src = "https://e9f87d8c82343264e6ba-2977c39e5e9390b1a89ab5ed2a171102.ssl.cf1.rackcdn.com/toolbox/geniusreferrals-toolbox_3.6.2.min.js"
      scriptTag.async = true
      scriptTag.addEventListener('load', () => {
        console.log('grTool', GRToolbox, grSetting)
        GRToolbox.processForBonus(grSetting).then(e => {
          console.log(e)
        })
      })
  
      document.body.appendChild(scriptTag)
  
      return () => {
        document.body.removeChild(scriptTag)
        localStorage.removeItem('orderDetails')
      }
    }
  }, [])
  // const referal = () => {
  //   setLoading(true)
  //   const config = {
  //     method:'get',
  //     url:`https://template.geniusreferrals.com/en/client-template/gr-process-for-bonus?grTemplateSlug=advocate-portal-710&grCustomerEmail=${orderDetails.email}&grReference=Order Id : ${orderDetails.order_id}&grPaymentAmount=${orderDetails.order_total}`
  //   }
  //   axios(config).then((response) => {
  //     setLoading(false)
  //     console.log('response', response)
  //   }).catch((error) => {
  //     setLoading(false)
  //     console.log('error', error)
  //   })
  // }
  // useEffect(() => {
  //   if (Boolean(orderDetails)) {
  //     console.log('entered referal useEffect')
  //     referal()
  //   }
  // }, [])
  return (
    <div> 
      {
        loading && <ComponentSpinner/>
      }
        <div className="d-flex justify-content-center align-items-center auth-wrap">
        <Card className="card-success p-5">
          <CardBody className="text-center">
            
            <Avatar
              icon={ <Check size={28}/> }
              className="shadow"
              color="primary"
              size="xl"
            />
            <div className="text-center">
              <h1 className="mb-1 text-white">Thank You</h1>
                <CardText className="m-auto w-100">
                You have completed the payment  successfully!
                </CardText> 
             
                <CardText className='mt-1 w-100'>
                Please Click
                <Link to='/home' className='ms-50 me-50'>here</Link>
                {/* <span className='text-warning' style={{cursor:'pointer'}} onClick={moveToLogin}>log in </span> */}
                to continue
                {/* Click here to continue. */}
                </CardText>
            
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default Thankyou
