import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Plus, ChevronDown, ShoppingCart, MoreVertical, Edit, Download, DollarSign } from 'react-feather'
import DataTable from 'react-data-table-component'
import ReactPaginate from 'react-paginate'
import '@styles/react/libs/tables/react-dataTable-component.scss'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import moment from 'moment'
import axios from 'axios'
import {
    Card,
     CardHeader,
     CardBody, 
     CardTitle, 
     CardText, 
     Row, 
     Col, 
     UncontrolledCollapse,
     Modal, 
     ModalBody, 
     ModalHeader, 
     Form, 
     FormGroup,
     Input,
     Label,
     Button,
     UncontrolledDropdown, 
     DropdownToggle,
     DropdownMenu,
     DropdownItem,
     Table,
     Spinner
  } from "reactstrap"
import Flatpickr from 'react-flatpickr'
import {  getToken } from '@utils'
import apiConfig from '@configs/apiConfig'
import { toast} from 'react-hot-toast'
import ComponentSpinner from '@components/spinner/Loading-spinner'
import { handleLogout } from '@store/authentication'

import PdfGenerator from './PdfGenerator'

const ToastContent = ({ message = null }) => (
  <>
  {message !== null && (
  <div className='d-flex'>
      <div className='me-1'>
          {/* <Avatar size='sm' color='success' icon={<Edit size={12} />} /> */}
      </div>
      <div className='d-flex flex-column'>
          <div className='d-flex justify-content-between'>
              <span>{message}</span>
          </div>
      </div>
  </div>
  )}
  </>
)
const OrderSummary = () => {
 moment.locale('en')
 const token = getToken()
 const history = useNavigate()
 const dispatch = useDispatch()
//  const history = useNavigate()
 const [summary, setSummary] = useState([])
 const [loading, setLoading] = useState(false)
 const [rowsPerPage, setRowsPerPage] = useState(10)
 const [currentPage, setCurrentPage] = useState(0)
 const [paginatedData, setPaginatedData] = useState(null)
 const [picker, setPicker] = useState()
 const [total, setTotal] = useState()
 const [totalAmount, setTotalAmount] = useState(0)
 const [totalOrders, setTotalOrders] = useState(0)
 const [startDate, setStartDate] = useState('')
 const [endDate, setEndDate] = useState('')
 const [disable, setDisable] = useState(false)
// const [tosDownload, setTosDownload] = useState(false)
//  console.log('tos', tosDownload)
 const getSummary = (page, per_page, fromDate, toDate) => {
  setLoading(true)
  const config = {
    method: 'get',
    url: `${apiConfig.api.url}order_summary?page=${page}&per_page=${per_page}&start_date=${fromDate}&end_date=${toDate}`,
    headers: { 
      Authorization: `Token ${token}`
    }
  }
  axios(config).then((response) => {
    console.log('response', total)
    if (response.data.status === 200) {
      setSummary(response.data.data)
      setPaginatedData(response.data)
      setTotal(response.data.pagination.total)
      setLoading(false)
    } else if (response.data.status === 204) {
      setLoading(false)
    } else if (response.data.status === 401) {
      dispatch(handleLogout)
      history('/login')
      toast.error(
        <ToastContent message={response.data.message} />,
          {duration:2000}             
      )
    }  else {
      setLoading(false)
      toast.error(
        <ToastContent message={response.data.message} />,
          {duration:2000}             
      )
    }
  })
  .catch((error) => {
    console.log('error', error)
    setLoading(false)
    setLoading(false)
      toast.error(
        <ToastContent message={error.message} />,
          {duration:2000}             
      )
  })
 }
 const getDashboardDetails = (fromDate, toDate) => {
  setLoading(true)
  const config = {
    method: 'get',
    url: `${apiConfig.api.url}order_dashboard?start_date=${fromDate}&end_date=${toDate}`,
    headers: { 
      Authorization: `Token ${token}`
    }
  }
  axios(config).then((response) => {
    console.log('response', response)
    if (response.data.status === 200) {
      setLoading(false)
      setTotalAmount(response.data.data.total_amount)
      setTotalOrders(response.data.data.total_orders)
    } else if (response.data.status === 204) {
      setLoading(false)
    } else {
      setLoading(false)
      toast.error(
        <ToastContent message={response.data.message} />,
          {duration:2000}             
      )
    }
  })
  .catch((error) => {
    console.log('error', error)
    setLoading(false)
    toast.error(
      <ToastContent message={error.message} />,
        {duration:2000}             
    )
  })
 }
 const handlePagination = (page) => {
  setCurrentPage(page.selected)
  getSummary(page.selected + 1, rowsPerPage, startDate, endDate)
}
const handlePerPage = (event) => {
  setRowsPerPage(parseInt(event.target.value))
  setTimeout(() => {
    getSummary(1, event.target.value, startDate, endDate)
  }, 300)
}

const handlePicker = (date) => {
  if (date.length > 0) {
    const fromDate =  moment(date[0]).format('MM/DD/YYYY')
    const toDate = moment(date[1]).format('MM/DD/YYYY')
    console.log('fromDate', fromDate)
    setStartDate(fromDate)
    setEndDate(toDate)
    setPicker(date)
    getSummary(1, 10, fromDate, toDate)
    getDashboardDetails(fromDate, toDate)
    } else {
      getSummary(1, 10, '', '')
      getDashboardDetails('', '')
    }
}
const convertToCSV = (data) => {
  const headers = Object.keys(data[0])
  const rows = data.map((row) => headers.map((header) => row[header]).join(","))
  // Create the common header cell that spans all columns
  return [headers.join(","), ...rows].join("\n")
}

const downloadCSV = (csvString) => {
  const element = document.createElement("a")
  const file = new Blob([csvString], { type: "text/csv" })
  element.href = URL.createObjectURL(file)
  element.download = `Orders From ${startDate}-${endDate}`
  document.body.appendChild(element) // Required for Firefox
  element.click()
  document.body.removeChild(element)
}
const downloadAll = () => {
  setDisable(true)
  const config = {
    method: 'get',
    url: `${apiConfig.api.url}order_summary?page=1&per_page=${total}&start_date=${startDate}&end_date=${endDate}`,
    headers: { 
      Authorization: `Token ${token}`
    }
  }
  axios(config).then((response) => {
    if (response.data.status === 200) {
      const tempData = response.data.data
      console.log('data', tempData)
      const filterData = tempData.map((item) => {
        return (
        item.order_items.map((orderData) => {
          console.log('order-data', orderData)
        return {OrderId: item.id, Agent:item.user_name, Email:item.email, PaidAmount:item.total_amount_paid, TransactionId:item.transaction_id, Description:orderData.shopping_cart_details.lead_type_info.platform_id === 3 ? `${orderData.shopping_cart_details.lead_type_info.description}(Fex)` : `${orderData.shopping_cart_details.lead_type_info.description}`, State:orderData.shopping_cart_details.state, County:orderData.shopping_cart_details.county, UnitPrice:orderData.price_per_quantity, Quantity:orderData.shopping_cart_details.quantity, ItemCost:orderData.total_cost, PromoCode:orderData.promo_code}
        })
        )
      })
      const printData = [].concat(...filterData)
      console.log('printData', printData)
      const csvString = convertToCSV(printData)
      downloadCSV(csvString)
      setDisable(false)
    } else {
      // setLoading(false)
      setDisable(false)
      toast.error(

        <ToastContent message={response.data.message} />,
          {duration:2000}             
      )
    }
  }).catch((error) => {
    setDisable(false)
    // setLoading(false)
      toast.error(

        <ToastContent message={error.message} />,
          {duration:2000}             
      )
  })
}

const downloadTos = (row) => {
 console.log(row)
//  history(`/tos-download?name=${row.user_name}&date=${row.local_purchase_date}`)
const url = `/tos-download?name=${row.user_name}&date=${row.local_purchase_date}`
window.open(url, '_blank')
}

const CustomPagination = () => {
  return (
    <ReactPaginate
      previousLabel=""
      nextLabel=""
      forcePage={currentPage}
      onPageChange={(page) => handlePagination(page)}
      pageCount={
        paginatedData &&
        (Math.ceil(paginatedData.pagination.total / rowsPerPage) || 1)
      }
      breakLabel="..."
      activeClassName="active"
      pageClassName="page-item"
      breakClassName="page-item"
      breakLinkClassName="page-link"
      nextLinkClassName="page-link"
      nextClassName="page-item next"
      previousClassName="page-item prev"
      previousLinkClassName="page-link"
      pageLinkClassName="page-link"
      containerClassName="pagination react-paginate separated-pagination pagination-sm justify-content-end mt-2"
    />
  )
}

const ExpandedComponent = ({ data }) => (
  <Table style={{maxWidth: "1050px", marginLeft: "67px"}}>
    <thead>
      <tr>
        <th>State</th>
        <th>County</th>
        <th>Price Per Quantity</th>
        <th>Quantity</th>
        <th>Total Cost</th>
        <th>Lead Type</th>

      </tr>
    </thead>
    <tbody>
      {data.order_items.map((log) => (
        <tr>
          <td>{log.shopping_cart_details.state}</td>
          <td>{log.shopping_cart_details.county}</td> 
          <td>${log.shopping_cart_details.lead_type_info.cost}</td>
          <td>{log.shopping_cart_details.quantity}</td>
          <td>${log.total_cost}</td>
          <td>{log.shopping_cart_details.lead_type_info.platform_id === 3 ? `${log.shopping_cart_details.lead_type_info.description}(Fex)` : `${log.shopping_cart_details.lead_type_info.description}` }</td>
        </tr>
      )  
      )}
    </tbody>
  </Table>
)
 useEffect(() => {
   getSummary(1, 10, startDate, endDate)
   getDashboardDetails(startDate, endDate)
   console.log('summary', summary)
 }, [])
 const columns = [
        {
            name: 'Id',
            selector: 'id',
            sortable: true,
            minWidth: '100px',
            cell: row => (
              <div className='d-flex align-items-center'>
                <div className='user-info text-truncate'>
                  <span className='d-block font-weight-bold text-truncate'>#{row.id}</span>
                </div>
              </div>
            )
        },
        {
          name: 'Name',
          selector: 'id',
          sortable: true,
          minWidth: '100px',
          cell: row => (
            <div className='d-flex align-items-center'>
              <div className='user-info text-truncate'>
                <span className='d-block font-weight-bold text-truncate'>{row.user_name}</span>
              </div>
            </div>
          )
        },
        {
          name: 'Email',
          selector: 'id',
          sortable: true,
          minWidth: '300px',
          cell: row => (
            <div className='d-flex align-items-center'>
              <div className='user-info text-truncate'>
                <span className='d-block font-weight-bold text-truncate'>{row.email}</span>
              </div>
            </div>
          )
        },
        {
          name: 'Total Amount',
          selector: 'id',
          sortable: true,
          minWidth: '100px',
          cell: row => (
            <div className='d-flex align-items-center'>
              <div className='user-info text-truncate'>
                <span className='d-block font-weight-bold text-truncate'>${row.total_amount_paid.toFixed(2)}</span>
              </div>
            </div>
          )
        },
        {
          name: 'Transaction ID',
          selector: 'id',
          sortable: true,
          minWidth: '100px',
          cell: row => (
            <div className='d-flex align-items-center'>
              <div className='user-info text-truncate'>
                <span className='d-block font-weight-bold text-truncate'>{row.total_amount_paid === 0 ? 'Promo Applied' : row.transaction_id}</span>
              </div>
            </div>
          )
        },
        {
          name: 'Date',
          selector: 'local_purchase_date',
          sortable: true,
          // sortFunction: caseInsensitiveSorter,
          minWidth: '100px',
          cell: (row) => {
          // const dateObject = new Date(row.local_purchase_date)
          return (
            <div className='d-flex align-items-center'>
              <div className='user-info text-truncate'>
                <span className='d-block font-weight-bold text-truncate'>{moment(row.local_purchase_date, "MM-DD-YYYY").format('DD MMM YYYY')}</span>
              </div>
            </div>
          )
          }
        },
        {
          name: 'Action',
          allowOverflow: true,
          minWidth: '100px',
          cell: row => {
            console.log(row)
            return (
              
                <div className='d-flex delete-card'>
                  {
                row.tos_agreed && <UncontrolledDropdown>
                  <DropdownToggle className='pr-1' tag='span'>
                    <MoreVertical size={15} />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem className='w-100' onClick={() => downloadTos(row) } ><Download size={18} className='vertical_drop mr-50'/> Download TOS</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
          }
              </div>
            )
          }
        }
      ]
    console.log(token)
    console.log(columns)

    return (
        <div>
          {
        disable === true ? <ComponentSpinner/> : ''
       }
           <Card>
        <CardBody>
          <Row>
          <Col className="mb-1" md={4}>
                  <Label className="form-label" for="range-picker">
                    Start and End Date
                  </Label>
                  <Flatpickr
                    value={picker}
                    id="range-picker"
                    className="form-control"
                    onClose={(date) => handlePicker(date)}
                    options={{
                      mode: "range",
                      defaultDate: picker
                    }}
                  />
                </Col>
                <Col md={4}>
                  <Button
                    className="mt-2"
                    color="primary"
                    onClick={() => downloadAll()}
                    disabled={summary.length === 0}
                  >
                    <>
                      <Download size={15} />
                      <span className="align-middle ms-50">Download File</span>
                    </>
                  </Button>
                </Col>
          </Row>
        </CardBody>
      </Card>
           <Row>
        
        <Col>
          <Card>
            <CardHeader >
              <CardTitle>Total Revenue </CardTitle>
              <span className="" style={{color:'#314ec4'}}><DollarSign size={40} /></span>
            </CardHeader>
            <CardBody>
              <CardText style={{ color: "#141452", fontSize:'30px' }}>${totalAmount  !== null ? totalAmount.toFixed(2) : 0}</CardText>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle>Total Orders</CardTitle>
              <span style={{color:'#c22939'}}><ShoppingCart size={40}/></span>
            </CardHeader>
            <CardBody>
              <CardText style={{ color: "#141452", fontSize:'30px' }}>{totalOrders}</CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
            <Card>
            <CardHeader className='flex-md-row flex-column align-md-items-center align-items-start border-bottom'>
          <CardTitle tag='h4'>Order Summary</CardTitle>
        </CardHeader>
                <div className='ps-2 pe-2 pt-2 pb-2'>
                    <div className="agent-sort d-flex align-items-center mb-1">
                    <Label for='sort-select'>show</Label>
              <Input
                className='dataTable-select'
                type='select'
                id='sort-select'
                value={rowsPerPage}
                onChange={e => handlePerPage(e)}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={75}>75</option>
                <option value={100}>100</option>
              </Input>
                    </div>
          <div>
            <DataTable
            noHeader
            pagination
            columns={columns}
            paginationPerPage={rowsPerPage}
            paginationServer
            className='react-dataTable'
            sortIcon={<ChevronDown size={10} />}
            paginationDefaultPage={currentPage}
            paginationComponent={CustomPagination}
            data={summary}
            progressPending={loading}
            expandableRows
            expandableRowsComponent={ExpandedComponent}
        //   onSelectedRowsChange={handleChange}
            />  
        </div>
        </div>
            </Card>
            
        </div>
    )
}

export default OrderSummary
