import React, { useEffect, useState } from 'react'
import { Plus, ChevronDown, ShoppingCart, MoreVertical, Edit, Download, Check, X } from 'react-feather'
import DataTable from 'react-data-table-component'
import ReactPaginate from 'react-paginate'
import '@styles/react/libs/tables/react-dataTable-component.scss'
import { useLocation, useNavigate} from 'react-router-dom'
import axios from 'axios'
import apiConfig from '@configs/apiConfig'
import { toast} from 'react-hot-toast'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useDispatch } from 'react-redux'
import { handleLogout } from '@store/authentication'

// import moment from 'moment'
import {
    Card,
     CardHeader,
     CardTitle, 
     Input,
     Label,
     Row,
     Col
    
  } from "reactstrap"
 
  const CustomLabel = ({ htmlFor }) => {
    return (
      <Label className='form-check-label' htmlFor={htmlFor}>
        <span className='switch-icon-left'>
          <Check size={14} />
        </span>
        <span className='switch-icon-right'>
          <X size={14} />
        </span>
      </Label>
    )
  }

  const ToastContent = ({ message = null }) => (
    <>
    {message !== null && (
    <div className='d-flex'>
        <div className='me-1'>
            {/* <Avatar size='sm' color='success' icon={<Edit size={12} />} /> */}
        </div>
        <div className='d-flex flex-column'>
            <div className='d-flex justify-content-between'>
                <span>{message}</span>
            </div>
        </div>
    </div>
    )}
    </>
  )

const StateDetails = () => {
    const history = useNavigate()
    const dispatch = useDispatch()
    const MySwal = withReactContent(Swal)
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const type = searchParams.get('type')
    const month = searchParams.get('month')
    const platform = searchParams.get('platform')
    const leadCategory = searchParams.get('cat')
    console.log('type=', type)
    console.log('month=', month)
    console.log('platform=', platform)
    const [stateData, setStateData] =  useState([])
    const [searchValue, setSearchValue] = useState('')
    const [filteredData, setFilteredData] = useState([])
    const [loading, setLoading] = useState(false)

    const handleFilter = e => {
        const value = e.target.value
        let updatedData = []
        setSearchValue(value)
        if (value.length) {
          updatedData = stateData.filter(item => {
            const startsWith =
              item.state.toLowerCase().startsWith(value.toLowerCase()) 
              
            const includes =
              item.state.toLowerCase().includes(value.toLowerCase())
              
    
            if (startsWith) {
              return startsWith
            } else if (!startsWith && includes) {
              return includes
            } else return null
          })
          setFilteredData(updatedData)
          setSearchValue(value)
        }
      }
      const getStateDetails = () => {
        setLoading(true)
        const  config = {
            method: 'get',
             url : `${apiConfig.api.url}admin/count_state_type/${leadCategory}/${type}/${month}/${platform}`
             }
    axios(config).then((response) => {
         console.log('dataf', response.data.data)
        if (response.data.status === 200) {
            const resp = response.data.data.data
            const store = []
            let i = 1
            for (const item in resp) {
                
                store.push({id:i, state:item, county:resp[item].county, total:resp[item].total, disabled_for_non_aria_member:resp[item].disabled_for_non_aria_member})
                i++
            }
            console.log('data', store)
            setStateData(store)
            setLoading(false)
          } else if (response.data.status === 401) {
            dispatch(handleLogout)
            history('/login')
            toast.error(
              <ToastContent message={response.data.message}  />,
              { icon: true, hideProgressBar: true, autoClose: 2000 }
            )
          } else {
            setLoading(false)
  
            toast.error(
  
              <ToastContent message={response.data.message} />,
                {duration:2000}             
            )
          }
    })
    .catch(error => {
        setLoading(false)
  
                  console.log(error)
                  if (error && error.status === 401) {
                    toast.error(
                      <ToastContent message={error.message} />,
                      { duration:2000 }
                    )
                  } else if (error) {
                    toast.error(
                      <ToastContent message={error.message} />,
                      { duration:2000 }
                    )
                  } 
            })
    }
      const statusSettings = (row) => {
        console.log(row)
        return MySwal.fire({
          title: 'Are you sure?',
          text: "You want to update!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, Confirm!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1'
          },
          buttonsStyling: false
        }).then(function (result) {
          if (result.value) {
            const config = {
              method: 'post',
              url: `${apiConfig.api.url}admin/enable_disable_non_aria_state_purchase/${type}/${month}/${platform}`,
              // headers: { 
              //   ContentType: 'application/json',
              //   Authorization: `Token ${token}`
              // }, 
              data :{state:row.state, disabled_for_non_aria_member:!row.disabled_for_non_aria_member}
            }
            console.log(config)
            axios(config)
            .then(response => {
              console.log(response)
              if (response.data.status === 200) {
               getStateDetails()
                // searchValue ? filterUsers(searchValue) : getPromocodeList(currentPage + 1, rowsPerPage)
                MySwal.fire({
                  icon: 'success',
                  title: 'Updated!',
                  text: 'Successfully Updated.',
                  customClass: {
                    confirmButton: 'btn btn-success'
                  }
                })
              } else if (response.data.status > 200 && response.data.status > 299) {
                toast.error(
                  <ToastContent message={response.data.message} />,
                  { duration:2000 }
                )
              } else if (response.data.status === 401) {
                 dispatch(handleLogout())
                 toast.error(
                  <ToastContent message={response.data.message} />,
                  {duration:3000}             
                )
                history('/login')
              } else {
                toast.error(
                 <ToastContent message={response.data.message} />,
                 {duration:3000}             
               )
              }
            })
            .catch(error => {
              console.log(error)
              if (error && error.status === 401) {
                toast.error(
                  <ToastContent message={error.message} />,
                  { duration:2000 }
                )
              } else if (error) {
                toast.error(
                  <ToastContent message={error.message} />,
                  { duration:2000 }
                )
              } 
            })
          } else if (result.dismiss === MySwal.DismissReason.cancel) {
            MySwal.fire({
              title: 'Cancelled',
              text: 'Update Cancelled',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success'
              }
            })
          }
        })
      }
    const columns = [
        {
            name: 'Id',
            selector: 'id',
            sortable: true,
            // sortFunction: caseInsensitiveSort,
            minWidth: '100px',
            cell: row => (
              <div className='d-flex align-items-center'>
                <div className='user-info text-truncate'>
                  <span className='d-block font-weight-bold text-truncate'>#{row.id}</span>
                </div>
              </div>
            )
          },
          {
            name: 'State',
            selector: 'state',
            sortable: true,
            // sortFunction: caseInsensitiveSort,
            minWidth: '100px',
            cell: row => (
              <div className='d-flex align-items-center'>
                <div className='user-info text-truncate'>
                  <span className='d-block font-weight-bold text-truncate'>{row.state}</span>
                </div>
              </div>
            )
          },
        
       
          {
            name: 'Total',
            selector: 'total',
            sortable: true,
            // sortFunction: caseInsensitiveSort,
            minWidth: '100px',
            cell: row => (
              <div className='d-flex align-items-center'>
                <div className='user-info text-truncate'>
                  <span className='d-block font-weight-bold text-truncate'>{row.total}</span>
                </div>
              </div>
            )
          },
          {
            name: 'Exclusive For Aria Members',
            selector: 'only_for_aria_member',
            minWidth: '130px',
            cell: row => {
                return (
                <>
                {console.log(row)}
                    <div className='d-flex flex-column'>
                        <div className='form-switch form-check-success'>
                        <Input type='switch' onChange={() => statusSettings(row)}  checked={row.disabled_for_non_aria_member} id={`switch_${row.id}`} name={`switch_${row.id}`} />
                        <CustomLabel htmlFor={`switch_${row.id}`} />
                        </div>
                    </div>
                </>
                )
              }
           }         
       
       
      ]
    // console.log(data)
    // console.log(columns)
    
    useEffect(() => {
        getStateDetails()
    }, [])

    return (
        <div>
            <Card>
            <CardHeader className='flex-md-row flex-column align-md-items-center align-items-start border-bottom'>
          <CardTitle tag='h4'>State Details</CardTitle>
        </CardHeader>
        <Row className='justify-content-end mx-0'>
          <Col className='d-flex align-items-center justify-content-end mt-1 me-1' md='6' sm='12'>
            <Label className='me-1' for='search-input'>
              Search
            </Label>
            <Input
              className='dataTable-filter mb-50'
              type='text'
              bsSize='sm'
              id='search-input'
              value={searchValue}
              onChange={handleFilter}
            />
          </Col>
        </Row>
                <div className='ps-2 pe-2 pt-2 pb-2'>
                    {/* <div className="d-flex align-items-center mb-1">
                    <Label for='sort-select'>show</Label>
              <Input
                className='dataTable-select'
                type='select'
                id='sort-select'
             
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={75}>75</option>
                <option value={100}>100</option>
              </Input>
                    </div> */}
                    <div>
            <DataTable
          noHeader
          pagination
          columns={columns}
          progressPending={loading}
        //   paginationPerPage={rowsPerPage}
        //   paginationServer
          className='react-dataTable'
          sortIcon={<ChevronDown size={10} />}
        //   paginationDefaultPage={currentPage}
        //   paginationComponent={CustomPagination}
          data={ searchValue.length ? filteredData : stateData}
        //   onSelectedRowsChange={handleChange}
        />  
        </div>
        </div>
            </Card>
        </div>
    )
}
export default StateDetails
