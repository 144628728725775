// ** Dropdowns Imports
import UserDropdown from "./UserDropdown"
import NavCart from "./NavCart"
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'
import { handleSelectedLead, handleDigitalPlatform } from "../../../../redux/navbar"
import { useDispatch } from "react-redux"
import { useState } from "react"
import { useHref } from "react-router-dom"
import dialerImg from '../../../../assets/images/background/hireDialer.jpg'
import aiImg from '../../../../assets/images/background/Export.png'

const NavbarUser = () => {
  const [selectedLead, setSelectedLead] = useState('Mailed Leads')
  const [selectedDigitalItem, setSelectedDigitalItem] = useState('Select...')
  const history = useHref()
  const dispatch = useDispatch()

  const handleMailedLeads = () => {
    dispatch(handleSelectedLead('Mailed Leads'))
    setSelectedLead('Mailed Leads')
    dispatch(handleDigitalPlatform(""))
    setSelectedDigitalItem('Select...')
  }
  const handleDigitalLeads = () => {
    dispatch(handleSelectedLead('Digital Leads'))
    setSelectedLead('Digital Leads')
  }
  // const store = useSelector((state) => state.navbar)

  const digitalItems = [
    {
      id: 5,
      name: 'Mortgage Protection',
      disabled: true
    },
    {
      id: 7,
      name: 'Medicare',
      disabled: true
    },
    {
      id: 4,
      name: 'IUL',
      disabled: false
    },
    {
      id: 6,
      name: 'Health Insurance',
      disabled: true
    },
    {
      id: 8,
      name: 'Recruiting Leads',
      disabled: true
    },
    {
      id:9,
      name:'Fex',
      disabled: false
    }
  ]

  const handleDigitalItem = (itm) => {
    dispatch(handleDigitalPlatform(itm.id))
    setSelectedDigitalItem(itm.name)
  }

  const renderDigitialItem = digitalItems.map(item => {
    return (
      <DropdownItem tag='span' disabled={item.disabled} key={item.id} onClick={() => handleDigitalItem(item)}>
        {item.name}
      </DropdownItem>
    )
  })

  return (
    <>
    {history === '/home' &&
     <ul className="nav navbar-nav align-items-center me-auto">
      <li>
       View
      <UncontrolledButtonDropdown className="nav-btn-dropdown ms-50" direction="down" size='sm'>
        <DropdownToggle color='primary' caret>
         {selectedLead}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem tag='a' onClick={handleMailedLeads}>Mailed Leads</DropdownItem>
          <DropdownItem tag='a' onClick={handleDigitalLeads}>Digital Leads</DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
      </li>
      {selectedLead === 'Digital Leads' &&
      <li className="ms-1">
      Select
      <UncontrolledButtonDropdown className="nav-btn-dropdown ms-50" direction="down" size='sm'>
        <DropdownToggle color='primary' caret>
         {selectedDigitalItem}
        </DropdownToggle>
        <DropdownMenu>
          {renderDigitialItem}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
      </li>
      }
    </ul>
    }
    <ul className="nav navbar-nav align-items-center ms-auto">
    <li className='ai-button' style={{marginRight: '15px'}}>
            <a href="https://agent-ai.net/" target='_blank'   >
              <span className='d-flex align-items-end'>
              <img src={aiImg}  style={{height:'15px'}}></img>
              </span>
            </a>
        </li>
    <li style={{marginRight: '15px'}} className='custom-button' >
        <a href="https://vortex-virtual.com" target='_blank' >
          <span className='d-flex align-items-center'>
          <img src={dialerImg} className='d-inline mr-50' style={{height:'15px'}}></img><span>Hire a Dialer</span>
          </span>
        </a>
    </li>
      <li ><NavCart /></li>
      <l1><UserDropdown /></l1>
    </ul>
    
    </>
  )
}
export default NavbarUser
