import { ShoppingCart } from "react-feather"
import { Link } from 'react-router-dom'
// import Badge from '@mui/material/Badge'
import { Badge } from "reactstrap"
import CartDropdown from "./CartDropdown"

const NavCart = () => {
 
    return (
        <div>    
        {/* <Link to ={{
            pathname: `/cart`
          }}>  */}
          <CartDropdown/>
          {/* <ShoppingCart size={25} />
                <Badge badgeContent={4} color='primary'>
                <ShoppingCart size={25}/>
              </Badge>
            {cartData.length > 0 ? (
            <Badge pill color="primary" className="badge-up">
                {cartData.length}
                <ShoppingCart size={25}/>
            </Badge>
            ) : null} */}
          {/* </Link> */}
        </div>
        
        // 
    )
      
    
}
export default NavCart