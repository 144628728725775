import React, {useEffect, useState, selectThemeColors} from 'react'
import Select from 'react-select'
import { Card, CardBody, CardHeader, CardTitle, Row, Col, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter, Button,   UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap'
import {  getToken } from '@utils'
import apiConfig from '@configs/apiConfig'
import DataTable from 'react-data-table-component'
import { ChevronDown, Check, X, Edit, Delete, MoreVertical, Plus } from 'react-feather'
import { toast} from 'react-hot-toast'
import ReactPaginate from 'react-paginate'
import Swal from 'sweetalert2'
import ComponentSpinner from '@components/spinner/Loading-spinner'

import withReactContent from 'sweetalert2-react-content'
import '@styles/react/libs/tables/react-dataTable-component.scss'

import axios from 'axios'
import { useDispatch } from "react-redux"
import { handleLogout } from '@store/authentication'
import { useNavigate } from 'react-router-dom'

const ToastContent = ({ message = null }) => (
    <>
    {message !== null && (
    <div className='d-flex'>
        <div className='me-1'>
        </div>
        <div className='d-flex flex-column'>
            <div className='d-flex justify-content-between'>
                <span>{message}</span>
            </div>
        </div>
    </div>
    )}
    </>
  )

const Notification = () => {
    const token = getToken()
    const history = useNavigate()
    const dispatch = useDispatch()
    const MySwal = withReactContent(Swal)
    const [states, setStates] = useState([])
    const [currentState, setCurrentState] = useState('')
    const [show, setShow] = useState(false)
    // const [complete, setComplete] = useState([])
    // const [incompleted, setIncompleted] = useState([])
    // const [bpl, setBpl] = useState([])
    const [addArr, setAddArr] = useState([])
    const [removeArr, setRemoveArr] = useState([])
    const [notificationData, setNotificationData] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(0)
    const [paginatedData, setPaginatedData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [editFlag, setEditFlag] = useState(false)
    const [stateIndex, setStateIndex] = useState('')
    const [exitstingStates, setExistingStates] = useState([])
    const [editId, setEditId] = useState('')
    const [addLoading, setAddLoading] = useState(false)
    const [modalLoading, setModalLoading] = useState(false)
    const [allLeadType, setAllLeadType] = useState([])
    const [gold, setGold] = useState([])
    const [silver, setSilver] = useState([])
    const [bpl, setBPL] = useState([])
    const [fex, setFex] = useState([])
    console.log(allLeadType)
    const getStates = () => {
        const config = {
            method: 'get',
            url: `${apiConfig.api.url}leads/state_only_list`,
            headers: { 
              Authorization: `Token ${token}`
            }
          }
          axios(config)
          .then(function (response) {
              console.log(response.data)
              if (response.data.status === 200) {
              setStates(response.data.data.states)
              setAllLeadType(response.data.data.all_lead_types)
              setExistingStates(response.data.data.existing_states)
              const goldData = []
              const silverData = []
              const bplData = []
              const fexData = []
              response.data.data.all_lead_types.map((item) => {
                if (item.platform_id === 0 && item.type_ === 1 && item.month !== 0) {
                   goldData.push(item)
                } else if (item.platform_id === 0 && item.type_ === 2 && item.month !== 0) {
                  silverData.push(item)
                } else if (item.platform_id === 0  && item.month === 0) {
                  bplData.push(item)
                } else if (item.platform_id === 3) {
                  fexData.push(item)
                }
              })
              setGold(goldData)
              setSilver(silverData)
              setBPL(bplData)
              setFex(fexData)
            } else if (response.data.status === 204) {
              setStates([])
              setExistingStates([])
            } else {
                toast.error(
                    <ToastContent message={response.data.message} />,
                    { duration:2000 }
                  )
            }
             
          })
          .catch(error => {
                  console.log(error)
                  if (error && error.status === 401) {
                    toast.error(
                      <ToastContent message={error.message} />,
                      { duration:2000 }
                    )
                  } else if (error) {
                    toast.error(
                      <ToastContent message={error.message} />,
                      { duration:2000 }
                    )
                  } 
          })
    } 
    const getEditDetails = (row) => {
      setModalLoading(true)
      const config = {
          method: 'get',
          url: `${apiConfig.api.url}new_leads_notifications/${row.id}`,
          headers: { 
            Authorization: `Token ${token}`
          }
        }
        axios(config)
        .then(function (response) {
            console.log(response.data)
            setModalLoading(false)
            if (response.data.status === 200) {
              console.log('response', response.data.data.all_lead_types)
              setAllLeadType(response.data.data.all_lead_types)
             const data = response.data.data.existing.map((item) => {
              return item.lead_type_id
             })
             setAddArr(data)
             setRemoveArr(data)
          } else if (response.data.status === 204) {
             setAllLeadType([])
             setAddArr([])
             setRemoveArr([])
          } else {
              toast.error(
                  <ToastContent message={response.data.message} />,
                  { duration:2000 }
                )
          }
           
        })
        .catch(error => {
                setModalLoading(false)
                console.log(error)
                if (error && error.status === 401) {
                  toast.error(
                    <ToastContent message={error.message} />,
                    { duration:2000 }
                  )
                } else if (error) {
                  toast.error(
                    <ToastContent message={error.message} />,
                    { duration:2000 }
                  )
                } 
        })
  } 
    const getEnabledStates = (page, perPage) => {
      setLoading(true)
      const config = {
        method: 'get',
        url: `${apiConfig.api.url}new_leads_notifications?page=${page}&per_page=${perPage}`,
        headers: { 
          Authorization: `Token ${token}`
        }
      }
      axios(config).then((response) => {
        console.log('notification-response', response)
        if (response.data.status === 200) {
          setNotificationData(response.data.data)
          setPaginatedData(response.data)
          setLoading(false)
        } else if (response.data.status === 204) {
          setNotificationData([])
          setPaginatedData([])
          setLoading(false)
        }  else if (response.data.status === 401) {
          dispatch(handleLogout)
          history('/login')
          toast.error(
            <ToastContent message={response.data.message} />,
            { duration:2000 }
          )
        } else {
          setLoading(false)
          toast.error(
            <ToastContent message={response.data.message} />,
            { duration:2000 }
          )
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error(
          <ToastContent message={error.message} />,
          { duration:2000 }
        )
      })
    }
  
    const handleState = (selectedOption) => {
       if (selectedOption.value) {
         console.log('entered..')
         setCurrentState(selectedOption.value)
       }
    }
    // const oneMonth = (event, text) => {
    //   if (event.target.checked) {
    //     if (text === 'gold') {
    //       console.log('text', 'entered..')
    //       setComplete(prevComplete => [...prevComplete, 1])
    //     } else {
    //       setIncompleted(prevIncompleted => [...prevIncompleted, 1])
    //     }
    //   } else {
    //     let remove
    //     if (text === 'gold') {
    //       remove = complete.filter(item => item !== 1)
    //       setComplete(remove)
    //     } else {
    //       remove = incompleted.filter(item => item !== 1)
    //       setIncompleted(remove)
    //     }
    //   }
    // }
    // const twoMonth = (event, text) => {
    //   if (event.target.checked) {
    //     if (text === 'gold') {
    //       console.log('text', 'entered..')
    //       setComplete(prevComplete => [...prevComplete, 2])
    //     } else {
    //       setIncompleted(prevIncompleted => [...prevIncompleted, 2])
    //     }
    //   } else {
    //     let remove
    //     if (text === 'gold') {
    //       remove = complete.filter(item => item !== 2)
    //       setComplete(remove)
    //     } else {
    //       remove = incompleted.filter(item => item !== 2)
    //       setIncompleted(remove)
    //     }
    //   }
    // }
    // const threeMonth = (event, text) => {
    //   if (event.target.checked) {
    //     if (text === 'gold') {
    //       console.log('text', 'entered..')
    //       setComplete(prevComplete => [...prevComplete, 3])
    //     } else {
    //       setIncompleted(prevIncompleted => [...prevIncompleted, 3])
    //     }
    //   } else {
    //     let remove
    //     if (text === 'gold') {
    //       remove = complete.filter(item => item !== 3)
    //       setComplete(remove)
    //     } else {
    //       remove = incompleted.filter(item => item !== 3)
    //       setIncompleted(remove)
    //     }
    //   }
    // }
    // const nineMonth = (event, text) => {
    //   if (event.target.checked) {
    //     if (text === 'gold') {
    //       console.log('text', 'entered..')
    //       setComplete(prevComplete => [...prevComplete, 9])
    //     } else {
    //       setIncompleted(prevIncompleted => [...prevIncompleted, 9])
    //     }
    //   } else {
    //     let remove
    //     if (text === 'gold') {
    //       remove = complete.filter(item => item !== 9)
    //       setComplete(remove)
    //     } else {
    //       remove = incompleted.filter(item => item !== 9)
    //       setIncompleted(remove)
    //     }
    //   }
    // }

    // const handleBpl = (event, text) => {
    //   if (event.target.checked) {
    //     if (text === 'gold') {
    //       console.log('text', 'entered..')
    //       setBpl(prevBpl => [...prevBpl, 1])
    //     } else {
    //       setBpl(prevBpl => [...prevBpl, 2])
    //     }
    //   } else {
    //     let remove
    //     if (text === 'gold') {
    //       remove = bpl.filter(item => item !== 1)
    //       setBpl(remove)
    //     } else {
    //       remove = bpl.filter(item => item !== 2)
    //       setBpl(remove)
    //     }
    //   }
    // }
    const handleNotificationInput = (event, item) => {
      console.log('event', event)
      console.log('item', item)
      if (event.target.checked) {
         setAddArr(prevArr => [...prevArr, item.id])
      } else {
         const remove = addArr.filter((data) => data !== item.id)
         setAddArr(remove)
      }
    }

    const addNotification = () => {
      setAddLoading(true)
      // const d = {
      //   state: currentState, 
      //   buy_per_leads: bpl, 
      //   completed: complete, 
      //   incomplete: incompleted,
      //   enabled_bpl:bpl.length > 0,
      //   enabled_gold_or_silver: complete.length > 0 || incompleted.length > 0
      // }
      const d = {state: currentState, add: addArr}
      let config 
      if (!editFlag) {
       config = {
        method: 'post',
        url: `${apiConfig.api.url}new_leads_notifications`,
        data:d,
        headers: { 
          Authorization: `Token ${token}`
        }
      }
    } else {
      const removeData = removeArr.every(item => addArr.includes(item)) ? [] : removeArr.filter(item => !addArr.includes(item))
      console.log('removedArr', removeData)
      d['remove'] = removeData
      delete d['state']
      config = {
        method: 'put',
        url: `${apiConfig.api.url}new_leads_notifications/${editId}`,
        data:d,
        headers: { 
          Authorization: `Token ${token}`
        }
      }
    }
      axios(config).then((response) => {
        console.log('response', response)
        if (response.data.status === 200) {
          setAddLoading(false)
          setShow(false)
          toast.success(<ToastContent message= {!editFlag ? "Successfuly added notification" : "Successfuly edited notification" }/>, { duration:2000 })
          getEnabledStates(1, 10)
          setCurrentPage(0)
          getStates()
        } else {     
          setAddLoading(false)
            toast.error(
                    <ToastContent message={response.data.message} />,
                    { duration:2000 }
                  )
        }
      })
      .catch((error) => {
        setAddLoading(false)
        toast.error(
                    <ToastContent message={error.message} />,
                    { duration:2000 }
                  )
      })
    }
    useEffect(() => {
     getStates()
     console.log('token', states)
     console.log('token', currentState)
     console.log('state-list', states)
    }, [])
    useEffect(() => {
      console.log('add-arr', addArr)
     }, [addArr.length])

    useEffect(() => {
      getEnabledStates(1, 10)
    }, [])

    const addOpen = () => {
      setEditFlag(false)
      setAddArr([])
      setRemoveArr([])
      setStateIndex('')
      setCurrentState('')
      setShow(true)
    }
    
    const handlePagination = (page) => {
      setCurrentPage(page.selected)
      getEnabledStates(page.selected + 1, rowsPerPage)
    }
    const handlePerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value))
      setTimeout(() => {
        getEnabledStates(1, event.target.value)
      }, 300)
    }

    const CustomPagination = () => {
      return (
        <ReactPaginate
          previousLabel=""
          nextLabel=""
          forcePage={currentPage}
          onPageChange={(page) => handlePagination(page)}
          pageCount={
            paginatedData &&
            (Math.ceil(paginatedData.pagination.total / rowsPerPage) || 1)
          }
          breakLabel="..."
          activeClassName="active"
          pageClassName="page-item"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          nextLinkClassName="page-link"
          nextClassName="page-item next"
          previousClassName="page-item prev"
          previousLinkClassName="page-link"
          pageLinkClassName="page-link"
          containerClassName="pagination react-paginate separated-pagination pagination-sm justify-content-end mt-2"
        />
      )
    }

    const editData = (row) => {
      console.log('row', row)
      // setBpl(row.buy_per_leads)
      // setComplete(row.completed)
      // setIncompleted(row.incomplete)
      // setCurrentState(row.state)
      setEditId(row.id)
      // const ind = exitstingStates.findIndex((item) => item.value === row.state)
      // console.log('index', ind)
      // setStateIndex(ind)
    }

   const editOpen = (row) => {
    setEditFlag(true)
    setShow(true)
    getEditDetails(row)
   }

    const handleDelete = (id) => {
      console.log(id)
      return MySwal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1'
        },
        buttonsStyling: false
      }).then(function (result) {
        if (result.value) {
          const config = {
            method: 'delete',
            url: `${apiConfig.api.url}new_leads_notifications/${id}`
          }
                      
          axios(config)
          .then(function (response) {
            console.log(response.data.status)
            if (response.data.status === 200) {
              toast.success(
                <ToastContent message={'Successfully Deleted'} />,
                {duration:3000}             
                )
                getEnabledStates(1, 10)
                getStates()
            } else if (response.data.status === 401) {
              dispatch(handleLogout())
              toast.error(
                <ToastContent message={response.data.message} />,
                {duration:3000}             
              )
              history('/login') 
            } else {
              toast.error(
                <ToastContent message={response.data.message} />,
                {duration:3000}             
              )
            }
          })
          .catch(error => {
            console.log(error)
            toast.error(
              <ToastContent message={response.data.message} />,
              {duration:3000}             
            )
          })
        } else if (result.dismiss === MySwal.DismissReason.cancel) {
          MySwal.fire({
            title: 'Cancelled',
            text: 'Address Delete Cancelled',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success'
            }
          })
        }
      })
    }

    const column = [
      {
       name:'id',
       selector:'id',
       sortable:true,
       cell: row => {
        return (
          <span className='text-truncate text-capitalize align-middle'>
            {row.id}
          </span>
        )
      }
      },
      { 
       name:'state',
       selector:'state',
       sortable:true,
       cell: row => {
        return (
          <span className='text-truncate text-capitalize align-middle'>
            {row.state}
          </span>
        )
      }
      },
      {
        name: 'Action',
        allowOverflow: true,
        minWidth: '100px',
        cell: (row, index) => {
          return (
            <UncontrolledDropdown >
              <DropdownToggle className='pr-1' tag='span' style={{cursor:'pointer'}} onClick={() => editData(row)}>
                <MoreVertical size={15}  />
              </DropdownToggle>
              <DropdownMenu className= {index === 0 ? 'notification-dropdown' : ''} >
                  <DropdownItem className='w-100' onClick={() => editOpen(row)}><Edit size={18} className='me-50' />Edit</DropdownItem>
                  <DropdownItem className='w-100' onClick={() => handleDelete(row.id)}><Delete size={18} className='me-50'/>Delete</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        }
      }
  ]

    return (
        <>
        <Card>
            <CardHeader><CardTitle>Notifications</CardTitle></CardHeader>
            <CardBody>
              <Modal isOpen={show} toggle={() => setShow(!show)} size='lg'>
                <ModalHeader>{ !editFlag ? 'Add New Notification' : 'Edit Notification' }</ModalHeader>
                <ModalBody>
                {
                  modalLoading ? <div className='d-flex justify-content-center'><Spinner color="primary" size="lg"/> </div> :  <>
                  {
                    !editFlag && <Row>
                      <Col >
                          <Label>Please select a state</Label>
                          <Select
                          theme={selectThemeColors}
                          className='react-select'
                          classNamePrefix='select'
                          // value={currentState}
                          defaultValue={exitstingStates[stateIndex]}
                          options={!editFlag ? states : exitstingStates}
                          onChange={handleState}
                          />
                      </Col>
                  </Row>
               }
                <Row className="mt-1"><Col><h6>Gold</h6></Col></Row>
                  <Row className=''>
                  {
                    gold && gold.length > 0 && gold.map((item) => {
                      return (
                        
                        <Col className='mt-1' xs={6} sm={3}><Input type="checkbox" onClick={(event) => handleNotificationInput(event, item)} checked={addArr.some((u) => u === item.id)}></Input><Label>{item.description}</Label></Col>

                      )
                    })
                  }
                  </Row>
                  <Row className="mt-1"><Col><h6>Silver</h6></Col></Row>
                  <Row className=''>
                  {
                    silver && silver.length > 0 && silver.map((item) => {
                      return (
                        
                        <Col className='mt-1' xs={6} sm={3}><Input type="checkbox" onClick={(event) => handleNotificationInput(event, item)} checked={addArr.some((u) => u === item.id)}></Input><Label>{item.description}</Label></Col>

                      )
                    })
                  }
                  </Row>
                  <Row className="mt-1"><Col><h6>BPL</h6></Col></Row>
                  <Row className=''>
                  {
                    bpl && bpl.length > 0 && bpl.map((item) => {
                      return (
                        
                        <Col className='mt-1' xs={6} sm={3}><Input type="checkbox" onClick={(event) => handleNotificationInput(event, item)} checked={addArr.some((u) => u === item.id)}></Input><Label>{item.description}</Label></Col>

                      )
                    })
                  }
                  </Row>
                  <Row><Col className="mt-1"><h6>FEX</h6></Col></Row>
                  <Row className=''>
                  {
                    fex && fex.length > 0 && fex.map((item) => {
                      return (
                        
                        <Col className='mt-1' xs={6} sm={3}><Input type="checkbox" onClick={(event) => handleNotificationInput(event, item)} checked={addArr.some((u) => u === item.id)}></Input><Label>{item.description}</Label></Col>

                      )
                    })
                  }
                  </Row>
             
                  {/* <Row className='mt-2'>
                      <Col xs={12}><h6>Silver</h6></Col>
                      <Col className='mt-50' xs={6} sm={3}><Input type="checkbox" onClick={(event) => oneMonth(event, 'silver')} checked={incompleted.some((u) => u === 1)}></Input><Label>1+ month</Label></Col>
                      <Col className='mt-50' xs={6} sm={3}><Input type="checkbox" onClick={(event) => twoMonth(event, 'silver')} checked={incompleted.some((u) => u === 2)}></Input><Label>2+ month</Label></Col>
                      <Col className='mt-50' xs={6} sm={3}><Input type="checkbox" onClick={(event) => threeMonth(event, 'silver')} checked={incompleted.some((u) => u === 3)}></Input><Label>3+ month</Label></Col>
                      <Col className='mt-50' xs={6} sm={3}><Input type="checkbox" onClick={(event) => nineMonth(event, 'silver')} checked={incompleted.some((u) => u === 9)}></Input><Label>9+ month</Label></Col>
                  </Row>
                  <Row className='mt-2'>
                      <Col xs={12}><h6>BPL</h6></Col>
                      <Col className='mt-50' xs={6} sm={3}><Input type="checkbox" onClick={(event) => handleBpl(event, 'gold')} checked={bpl.some((u) => u === 1)}></Input><Label>Gold</Label></Col>
                      <Col className='mt-50' xs={6} sm={3}><Input type="checkbox" onClick={(event) => handleBpl(event, 'silver')} checked={bpl.some((u) => u === 2)}></Input><Label>Silver</Label></Col>
                  </Row> */}
                  </>
                }
               
                </ModalBody>
                <ModalFooter>
                <Col xs={12} className='text-center mt-2 pt-50'>
                <Button type='submit' className='me-1' color='primary' style={{marginRight: '20px'}} onClick={addNotification} disabled={(!editFlag && currentState === '') || addLoading === true || modalLoading}>
                {
                  addLoading ? (
                  <>
                    <Spinner color="white" size="sm" />
                    <span className="ms-50">Loading...</span>{" "}
                  </>
                ) : (
                  "Submit"
                )
              }
                </Button>
                <Button
                  type='reset'
                  color='secondary'
                  outline
                  onClick={() => {
                    setShow(false)
                  }}
                >
                  Cancel
                </Button>
              </Col>
                </ModalFooter>
              </Modal>
                <Row>
                  <div className='d-flex justify-content-between'>
                  <div className="d-flex align-items-center mb-0">
                  <Label for="sort-select">show</Label>

                  <Input
                    className="dataTable-select "
                    type="select"
                    id="sort-select"
                    onChange={(e) => handlePerPage(e)}
                  >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={75}>75</option>
                  <option value={100}>100</option>
                  </Input>
                </div>
                  <Button color='primary' onClick={addOpen}>  
                  <>
                  <Plus size={15} />
                  <span className="align-middle ml-50">Add Notification</span>
                 </>
                 </Button>
                  </div>
                </Row>
                <Row className='mt-1'>
                <DataTable
                    columns={column}
                    className='react-dataTable p-1'
                    sortIcon={<ChevronDown size={10} />}
                    data={notificationData}
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationComponent={
                      paginatedData &&
                      paginatedData.hasOwnProperty("pagination") &&
                      CustomPagination
                    }
                    paginationPerPage={rowsPerPage}
                    paginationDefaultPage={currentPage + 1}
                  />
                </Row>
            </CardBody>
        </Card>
        </>
    )
}

export default Notification
