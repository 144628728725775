// You can customize the template with the help of this file

//Api config options
const apiConfig = {
  api: {
     url: 'https://ivr-marketplace.herokuapp.com/v1/',
     url1:'https://downloads.arialeads.com/v1/',
    //url1:'https://ivr-test-app-backend.herokuapp.com/v1/',
    //url: 'https://ivr-marketplace-backend-test.herokuapp.com/v1/',
    //url_crm:'https://ivr-test-app-backend.herokuapp.com/v1/',
    url_crm:'https://ivr-system-dev.herokuapp.com/v1/',
    //frontEnd:'https://ivr-test-app.herokuapp.com'
    frontEnd:'https://login.arialeads.com'
  },
  token: {
    url2: ''
  }
}

export default apiConfig