// ** React Imports
import { Fragment, lazy } from "react"
import { Navigate } from "react-router-dom"
// ** Layouts
import BlankLayout from "@layouts/BlankLayout"
import VerticalLayout from "@src/layouts/VerticalLayout"
import HorizontalLayout from "@src/layouts/HorizontalLayout"
import LayoutWrapper from "@src/@core/layouts/components/layout-wrapper"

// ** Route Components
import PublicRoute from "@components/routes/PublicRoute"

// ** Utils
import { isObjEmpty } from "@utils"
import OrderSummary from "../../views/OrderSummary"
import OrderStates from "../../views/OrderStates"
import StateDetails from "../../views/StateDetails"
import CheckoutModified from "../../views/home/CheckoutModified"
import Notification from "../../views/Notification"
import PdfGenerator from "../../views/PdfGenerator"
import FaqCrud from "../../views/Faq/FaqCrud"
import FaqView from "../../views/Faq/FaqView"
import Couponcode from "../../views/promocode/Couponcode"
import CouponcodeAccess from "../../views/promocode/CouponcodeAcccess"
import Thankyou from "../../views/Thankyou"

const getLayout = {
  blank: <BlankLayout />,
  vertical: <VerticalLayout />,
  horizontal: <HorizontalLayout />
}

// ** Document title
const TemplateTitle = "%s - IVR - Market Place"

// ** Default Route
const DefaultRoute = "/home"

const Home = lazy(() => import("../../views/Home"))
const OrderCost = lazy(() => import("../../views/OrderCost"))
const PurchasedLeads = lazy(() => import("../../views/profile/tabs/ViewLeads"))
const SuppressionList = lazy(() => import("../../views/Suppression/SuppressionList"))
const Login = lazy(() => import("../../views/Login"))
const Register = lazy(() => import("../../views/Register"))
const MailVerification = lazy(() => import("../../views/MailVerification"))
const ForgotPassword = lazy(() => import("../../views/ForgotPassword"))
const ResetPassword = lazy(() => import("../../views/ResetPassword"))
const Error = lazy(() => import("../../views/Error"))
const Cart = lazy(() => import("../../views/home/Cart"))
const Checkout = lazy(() => import("../../views/home/Checkout"))
const Invoice = lazy(() => import("../../views/profile/Invoice"))
const AgentAllocation = lazy(() => import("../../views/AgentAllocation"))
const Search = lazy(() => import("../../views/search/Search"))
const Print = lazy(() => import('../../views/profile/tabs/components/Print'))
const SingleView = lazy(() => import('../../views/profile/tabs/components/SingleView'))
const AgentsList = lazy(() => import('../../views/profile/tabs/Agents'))
const Promocode = lazy(() => import('../../views/promocode/Promocode'))
const PromocodeAccess = lazy(() => import('../../views/promocode/PromocodeAccess'))

// ** Merge Routes
const Routes = [
  {
    path: "/",
    index: true,
    element: <Navigate replace to={DefaultRoute} />
  },
  {
    path: "/home",
    element: <Home />
  },
  {
    path: "/cart",
    element: <Cart />
  },
  {
    path: "/checkout",
    element: <Checkout />
  },
  {
    path: "/checkout-modified",
    element: <CheckoutModified/>
  },
  {
    path: "/order-cost",
    element: <OrderCost />
    
  },
  {
    path: "/purchased-leads",
    element: <PurchasedLeads />
    
  },
  {
    path: "/order-summary",
    element: <OrderSummary />
    
  },
  {
    path: "/notification",
    element:<Notification/>
    
  },
  {
    path: "/order-states",
    element: <OrderStates/>
    
  },
  {
    path: "/state-details",
    element: <StateDetails/>
    
  },
  {
    path: "/coupon-list",
    element: <Couponcode/>
    
  },
  {
    path: '/purchased-leads/:id/:platform/:name/:userID/:leadtype',
    element: <PurchasedLeads />
  }, 
  {
    path: "/suppression-list",
    element: <SuppressionList />
    
  },
  {
    path: "/invoice",
    element: <Invoice />
  },
  {
    path: "/login",
    element: <Login />,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/login/:token",
    element: <Login />,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/register",
    element: <Register />,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/payment-success",
    element: <Thankyou />,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/verification/:token",
    element: <MailVerification />,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/error",
    element: <Error />,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "agent-allocation/:id",
    element: <AgentAllocation />
  },
  {
    path: "search",
    element: <Search />
  },
  {
    path: '/print/:category_id',
    element: <Print/>,
    // element: lazy(() => import('../../views/profile/tabs/components/Print')),
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/purchased-leads/view/:id/:category_id',
    element: <SingleView/>,
    // component: lazy(() => import('../../views/pages/agents/SingleView')),
    meta: {
      authRoute: true,
      publicRoute: false
    }
  },
  {
    path: '/agents-list',
    element: <AgentsList/>,
    meta: {
      authRoute: true,
      publicRoute: false
    }
  },
  {
    path: '/promocode-list',
    element: <Promocode/>,
    meta: {
      authRoute: true,
      publicRoute: false
    }
  },
  {
    path: '/promocode/members-access/:id',
    element: <PromocodeAccess/>,
    meta: {
      authRoute: true,
      publicRoute: false
    }
  },
  {
    path: '/coupon-code-access/:id',
    element: <CouponcodeAccess/>,
    meta: {
      authRoute: true,
      publicRoute: false
    }
  },
  {
    path: '/faq-manage',
    element:<FaqCrud/>,
    meta: {
      authRoute: true,
      publicRoute: false
    }
  },
  {
    path: '/faq',
    element:<FaqView/>,
    meta: {
      authRoute: true,
      publicRoute: false
    }
  },
  {
    path: '/tos-download',
    element: <PdfGenerator/>,
    meta: {
      authRoute: true,
      publicRoute: false,
      layout: 'blank'

    }
  }

]

const getRouteMeta = (route) => {
  if (isObjEmpty(route.element.props)) {
    if (route.meta) {
      return { routeMeta: route.meta }
    } else {
      return {}
    }
  }
}

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout, defaultLayout) => {
  const LayoutRoutes = []

  if (Routes) {
    Routes.filter((route) => {
      let isBlank = false
      // ** Checks if Route layout or Default layout matches current layout
      if (
        (route.meta && route.meta.layout && route.meta.layout === layout) ||
        ((route.meta === undefined || route.meta.layout === undefined) &&
          defaultLayout === layout)
      ) {
        const RouteTag = PublicRoute

        // ** Check for public or private route
        if (route.meta) {
          route.meta.layout === "blank" ? (isBlank = true) : (isBlank = false)
        }
        if (route.element) {
          const Wrapper =
            // eslint-disable-next-line multiline-ternary
            isObjEmpty(route.element.props) && isBlank === false
              ? // eslint-disable-next-line multiline-ternary
                LayoutWrapper
              : Fragment

          route.element = (
            <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
              <RouteTag route={route}>{route.element}</RouteTag>
            </Wrapper>
          )
        }

        // Push route to LayoutRoutes
        LayoutRoutes.push(route)
      }
      return LayoutRoutes
    })
  }
  return LayoutRoutes
}

const getRoutes = (layout) => {
  const defaultLayout = layout || "vertical"
  const layouts = ["vertical", "horizontal", "blank"]

  const AllRoutes = []

  layouts.forEach((layoutItem) => {
    const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout)

    AllRoutes.push({
      path: "/",
      element: getLayout[layoutItem] || getLayout[defaultLayout],
      children: LayoutRoutes
    })
  })
  return AllRoutes
}

export { DefaultRoute, TemplateTitle, Routes, getRoutes }
