// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import apiConfig from '@configs/apiConfig'
import { getToken } from '@utils'

// ** Axios Imports
import axios from "axios"

export const getBookmarks = createAsyncThunk(
  "layout/getBookmarks",
  async () => {
    const response = await axios.get("/api/bookmarks/data")
    return {
      data: response.data.suggestions,
      bookmarks: response.data.bookmarks
    }
  }
)
export const getCartData = createAsyncThunk(
  "layout/getCartData",
  async () => {
    const response = await axios.get(`${apiConfig.api.url}cart`)
    return {
      data: response.data.data
      // bookmarks: response.data.bookmarks
    }
  }
)
export const exportAi = createAsyncThunk(
  'layout/exportAi',
  async ({datas, category_id, user_id}) => {
    try {
      const payload = {lead_members: datas, is_crm:false}
      const token = getToken()
      const config = {
        method: 'post',
        url: `${apiConfig.api.url_crm}gohighlevel/export_from_db/${category_id}/${user_id}`,
        data: payload,
        headers: {
          ContentType: 'application/json',
          Authorization: `Token ${token}`
        }
      }

      // dispatch({ type: 'EXPORT_AI_START' })
      const response = await axios(config)
      return {data: response.data.message}
      // dispatch({ type: 'EXPORT_AI_DONE', payload: response.data.message })
    } catch (error) {
      return {data: 'Error'}

      // dispatch({ type: 'EXPORT_AI_DONE', payload: 'Please Try Again' })
    }
  }
)
export const updateBookmarked = createAsyncThunk(
  "layout/updateBookmarked",
  async (id) => {
    await axios.post("/api/bookmarks/update", { id })
    return id
  }
)

export const layoutSlice = createSlice({
  name: "layout",
  initialState: {
    query: "",
    bookmarks: [],
    cartData: [],
    suggestions: [],
    deleteItems: {},
    selectedLead: "Mailed Leads",
    digitalPlatform: "",
    exportIndicator:null,
    exportLoading:false
  },
  reducers: {
    handleSearchQuery: (state, action) => {
      state.query = action.payload
    },
    handleDeleteItems: (state, action) => {
      state.deleteItems = action.payload
    },
    handleSelectedLead: (state, action) => {
      console.log('action', action)
      if (action.payload === "Mailed Leads") state.digitalPlatform = ""
      state.selectedLead = action.payload
    },
    handleDigitalPlatform: (state, action) => {
      console.log('action', action)
      state.digitalPlatform = action.payload
    },
    EXPORT_AI_START: (state) => {
      console.log('entered..')
      state.exportLoading = true
      state.exportIndicator = null
    },
    EXPORT_AI_DONE: (state, action) => {
      console.log('entered..')
      state.exportLoading = false
      state.exportIndicator = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBookmarks.fulfilled, (state, action) => {
        state.suggestions = action.payload.data
        state.bookmarks = action.payload.bookmarks
      })
      .addCase(getCartData.fulfilled, (state, action) => {
        state.cartData = action.payload.data
       
      })
      .addCase(exportAi.pending, (state) => {
        state.exportLoading = true
      })
      .addCase(exportAi.fulfilled, (state, action) => {
        state.exportLoading = false
        state.exportIndicator = action.payload.data
      })
      .addCase(exportAi.rejected, (state, action) => {
        state.exportLoading = false
        state.exportIndicator = action.payload.data
      })
      .addCase(updateBookmarked.fulfilled, (state, action) => {
        let objectToUpdate

        // ** find & update object
        state.suggestions.find((item) => {
          if (item.id === action.payload) {
            item.isBookmarked = !item.isBookmarked
            objectToUpdate = item
          }
        })

        // ** Get index to add or remove bookmark from array
        const bookmarkIndex = state.bookmarks.findIndex(
          (x) => x.id === action.payload
        )

        if (bookmarkIndex === -1) {
          state.bookmarks.push(objectToUpdate)
        } else {
          state.bookmarks.splice(bookmarkIndex, 1)
        }
      })
  }
})

export const { handleSearchQuery, handleDeleteItems, handleSelectedLead, handleDigitalPlatform, EXPORT_AI_START, EXPORT_AI_DONE } = layoutSlice.actions

export default layoutSlice.reducer
