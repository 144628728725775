import { Fragment, useState, useEffect } from "react"
import { Edit2, Plus } from "react-feather"
import {
    Card,
     CardHeader,
     CardBody, 
     CardTitle, 
     CardText, 
     Row, 
     Col, 
     Form, 
     Input,
     InputGroup,
     Label,
     Alert,
     Button, Accordion, AccordionBody, AccordionHeader, AccordionItem,
     Spinner
  } from "reactstrap"
import axios from 'axios'
import apiConfig from '@configs/apiConfig'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import Select from "react-select"
import Cleave from 'cleave.js/react'
import InputMask from 'react-input-mask'
import { toast} from 'react-hot-toast'
// import { yupResolver } from '@hookform/resolvers/yup'
// import * as yup from "yup"
import {getCartData} from "@store/navbar"
import { State, City }  from 'country-state-city'
import ComponentSpinner from '@components/spinner/Loading-spinner'
import { useDispatch, useSelector } from "react-redux"
import { getUserData, selectThemeColors } from "@utils"
import { useNavigate } from 'react-router-dom'
import Countdown from "react-countdown"

import { handleLogout } from '@store/authentication'
import CheckoutTerms from "./CheckoutTerms"


const ToastContent = ({ message = null }) => (
        <>
        {message !== null && (
        <div className='d-flex'>
            <div className='me-1'>
                {/* <Avatar size='sm' color='success' icon={<Edit size={12} />} /> */}
            </div>
            <div className='d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span>{message}</span>
                </div>
            </div>
        </div>
        )}
        </> 
    )
    
    // const defaultValues = {
    //     AddressType: {},
    //     HouseNo: '',
    //     Country: '',
    //     State: {},
    //     County: {},
    //     ZipCode: ''
    //   }
    const addresstype = [
        // { value: '', label: 'Select Address type'},
        { value: '1', label: 'Home'},
        { value: '2', label: 'Office'},
        { value: '3', label: 'Other'}
    ]

    // const schema1 = yup.object({
    //     HouseNo: yup.string().required(),
    //     Country: yup.string().required(),
    //     ZipCode: yup.string().required(),
    //     County: yup.lazy((value) => {
    //         switch (typeof value) {
    //           case 'object':
    //             return yup.object().shape({
    //               value: yup.string().required(),
    //               label: yup.string().required()
    //             })
    //           case 'string':
    //             return yup.string().required()
    //           default:
    //             return yup.object().shape({
    //               value: yup.string().required(),
    //               label: yup.string().required()
    //             })
    //         }
    //       }),
    //     AddressType: yup.object().shape({
    //     value: yup.string().required(),
    //     label: yup.string().required()
    //     }),
    //     State: yup.object().shape({
    //     value: yup.string().required(),
    //     label: yup.string().required()
    //     })
    // }).required()

// const schema2 = yup.object({
//         name: yup.string().required(),
//         security_code: yup.string().required(),
//         expiration: yup.string().required()
//         // card_holder_cvv: yup.string().required()
//     }).required()


const CheckoutModified = () => {
    const userData = getUserData()
    const history = useNavigate()
    const dispatch = useDispatch()
    const form = useForm()
    const { control, handleSubmit, setValue, formState: { errors }  } = form
    const {
        control : control2,
        handleSubmit : handleSubmit2,
        formState: { errors:  errors2},
        getValues: getValues2,
        setValue: setValue2
    } = useForm()
    //timer
    const m = localStorage.getItem('used_minute')
    const s = localStorage.getItem('used_second')
    const saved_minute = m ? JSON.parse(m) : ''
    const saved_second = s ? JSON.parse(s) : ''

    const store = useSelector((state) => state.navbar)
    const cartItem = store?.cartData
    const [addressList, setAddressList] = useState([])
    const [open, setOpen] = useState('')
    const [openPayment, setOpenPayment] = useState('')
    const [addOpen, setAddOpen] = useState()
    const [input, setInput] = useState({})
    const [shoppingCart, setShoppingCart] = useState([])
    const [promocodeCart, setPromocodeCart] = useState([])
    const [paymentList, setPaymentList] = useState([])
    const [savedCard, setSavedCard] = useState('')
    const [cardCvv, setCardCvv] = useState('')
    const [totalCost, setTotalCost] = useState(0)
    const [total, setTotal] = useState(0)
    const options = { creditCard: true }
    const [checked, setChecked] = useState(false)
    const [radioBtn, setRadioBtn] = useState()
    const [reserveLeads, setReserveLeads] = useState([])
    const [isCard, setIsCard] = useState(false)
    const [isAddress, setIsAddress] = useState(false)
    const [saveAddress, setSaveAddress] = useState(false)
    const [cardError, setCardError] = useState(false)
    const [states, setStates] = useState([])
    const [counties, setCounties] = useState([])
    const [editAddress, setEditAddress] = useState([])
    const [loader, setLoading] = useState(false)
    const [cartStock, setCartStock] = useState([])
    const [mypromocode, setMypromocode] = useState([])
    const [coupon, setCoupons] = useState('')
    // const [leads, setLeads] = useState([])
    const [carData, setCartData] = useState([])
    const [couponApply, setCouponApply] = useState(false)
    const [couponID, setCouponID] = useState(0)
    const [totalUsed, setTotalUsed] = useState(0)
    // const [changeCity, setChangeCity] = useState('')
    const [listPromocode, setListPromocode] = useState('')
    const [authData, setAuthData] = useState({})
    
    const [selectDisabled, setSelectDisabled] = useState(true)
    const [fieldDisable, setFieldDisable] = useState(false)
    const [existingAddress, setExistingAddress] = useState(false)
    const [addAddress, setAddAddress] = useState(false)
    const [addressEdit, setAddressEdit] = useState(false)
    const [finalAddress, setFinalAddress] = useState({})
    const [displayPayment, setDisplayPayment] = useState(false)
    const [termsModal, setTermsModal] = useState(true)
    // const [discountCoupons, setDiscountCoupons] = useState([])
    const [discountCoupon, setDiscountCoupon] = useState(null)
    const [discountApplied, setDiscountApplied] = useState(false)
    const [initialAmount, setInitialAmount] = useState(0)
    const [couponName, setCouponName] = useState('')
    const [applyLoading, setApplyLoading] = useState(false)
    // const [discData, setDiscData] = useState(null)
    // const [addresschecked, setAddressChecked] = useState(false)
    // const [promocode, setPromocode] = useState('')

   console.log(couponID)
   console.log(addAddress)
   console.log(addressEdit)
   console.log(finalAddress)
//    console.log(changeCity)
    // const {
    //     control,
    //     setValue,
    //     handleSubmit,
    //     formState: { errors },
    //     getValues 
    // } = useForm({ defaultValues, resolver: yupResolver(schema1)})
    //   } = useForm({ defaultValues1, resolver: yupResolver(schema1)})
    // const {
    //     control : control2,
    //     setValue: setValue2,
    //     handleSubmit : handleSubmit2,
    //     formState: { errors:  errors2},
    //     getValues: getValues2
    // } = useForm({ resolver: yupResolver(schema2) })
    //   } = useForm({ defaultValues2, resolver: yupResolver(schema2) })
    const toggle = (id, edit) => {
        console.log('id', id)
        console.log('Edit', open)
       
        if (edit === 'disable') {
            setOpen(id)
            setFieldDisable(true)
            setExistingAddress(true)
            setDisplayPayment(false)
            setIsCard(false)
            setSavedCard('')
            setAuthData({})
            setCardCvv('')
            setValue2('name', '')
            setValue2('expiration', '')
            setValue2('cardNumber', '')
            setCardError(false)

        } else if (edit === 'edit') {
            setOpen(id)
            setFieldDisable(false)
            setExistingAddress(false)
            setDisplayPayment(false)
            setIsCard(false)
            setSavedCard('')
            setAuthData({})
            setCardCvv('')
            setValue2('name', '')
            setValue2('expiration', '')
            setValue2('cardNumber', '')
            setCardError(false)
        } else {
            if (open === id) {
                console.log('close') 
                setOpen()
                setValue("Country", '')
                setInput([]) 
                setFieldDisable(true)
                setExistingAddress(true)
                setDisplayPayment(false)
                setIsCard(false)
                setSavedCard('')
                setAuthData({})
                setCardCvv('')
                setValue2('name', '')
                setValue2('expiration', '')
                setValue2('cardNumber', '')
                setValue('address', '')
                setValue('zip', '')
                setValue('state', null)
                setValue('city', null)
                setValue('city_input', '')
                setCardError(false)
            } else {
                setIsCard(false)
                setSavedCard('')
                setAuthData({})
                setCardCvv('')
                setFieldDisable(false)
                setExistingAddress(false)
                console.log('open')
                setRadioBtn(false) 
                setInput([]) 
                setDisplayPayment(false)
                setRadioBtn(false)
                setValue2('name', '')
                setValue2('expiration', '')
                setValue2('cardNumber', '')
                setCardError(false) 
                // setValue("id", '')
                // setValue("AddressType", {})
                // setValue("HouseNo", '')
                // setValue("Country", 'United States of America')
                // setValue("State", {})
                // setValue("ZipCode", '')
                setOpen(id)
            } 
        }
    }
    const validateNoNumbers = (value) => {
        return !/\d/.test(value)
      }
    const handleSaveAddress = (event) => {
        console.log(event)
        if (event.target.checked) {
            setAddAddress(true)
        } else {
            setAddAddress(false)
        }
    }
    const addressToggle = (id) => {
        if (parseInt(addOpen) === parseInt(id)) {
            setAddOpen()
        } else { setAddOpen(id) }             
    }

    const paymentToggle = id => {
       openPayment === id ? setOpenPayment() : setOpenPayment(id)
    }
    const getAddresses = () => {
        const config = {
            method: 'get',
            url: `${apiConfig.api.url}address`
        }
        
        axios(config)
        .then(function (response) {
            console.log(response.data)
            if (response.data.status_code === 200) {
                // if (response.data.status !== 204) {
                setAddressList(response.data.data)
                // }
            } else if (response.data.status === 401) {
               dispatch(handleLogout())
                history('/login')
            }
        })
        .catch(error => {
            console.log(error)
            if (error && error.status === 401) {
              toast.error(
                <ToastContent message={error.message} />,
                { duration:2000 }
              )
            } else if (error) {
              toast.error(
                <ToastContent message={error.message} />,
                { duration:2000 }
              )
            } 
          })
    }

    const getCheckoutVerifier = (reserveLeads_) => {
        console.log('reserve', reserveLeads_)
        reserveLeads_.map(items => {
            const config = {
                    method: 'get',
                    url: `${apiConfig.api.url}checkout/verifier/${items.category_id}/${items.id_for_duplicate_cart_items}/${items.quantity}`
                    // url: `https://ivr-marketplace.herokuapp.com/v1/checkout/verifier/${items.id_for_duplicate_cart_items}/${items.quantity}`
                }
                
                axios(config)
                .then(function (response) {
                    console.log(response)
                    if (response.data.status === 200) {
                            console.log(response.data)
                    } else if (response.data.status === 500) {
                        toast.error(
                            <ToastContent message={response.data.message} />,
                            {duration:3000}             
                        )
                    } else if (response.data.status === 401) {
                            dispatch(handleLogout())
                            toast.error(
                                <ToastContent message={response.data.message} />,
                                {duration:3000}             
                            )
                            history('/login')   
                    } else {
                        toast.error(
                            <ToastContent message={response.data.message} />,
                            {duration:3000}             
                        )
                    }
                })
                .catch(error => {
                    console.log(error)
                    if (error && error.status === 401) {
                      toast.error(
                        <ToastContent message={error.message} />,
                        { duration:2000 }
                      )
                    } else if (error) {
                      toast.error(
                        <ToastContent message={error.message} />,
                        { duration:2000 }
                      )
                    } 
                })
        })
    }

    const placeOrderCheck = (cartItems) => {
        
        const d = []
        cartItems.map(items => (
            // d = {id: items.id, state: items.state, county:items.county, month:parseInt(items.lead_type_info.month), quantity:parseInt(items.quantity) ? parseInt(items.quantity) : 0, type_: parseInt(items.lead_type_info.type_)}
            d.push({id: items.id, state: items.state, county:items.county, month:items.lead_type_info['month'], quantity:parseInt(items.quantity) ? parseInt(items.quantity) : 0, type_: items.lead_type_info.type_, lead_type_id:items.lead_type_info.id, platform_id:items.lead_type_info.platform_id, category_id:items.category_id})
            )
        )
        const config = {
        method: 'post',
        url: `${apiConfig.api.url}checkout/reserve_leads`,
        // url: `https://ivr-marketplace.herokuapp.com/v1/checkout/reserve_leads`,
        data: d
        }
        console.log(d)
        
        axios(config)
        .then(function (response) {
            //   console.log(response)
            if (response.data.status === 200) {
                console.log(response.data.data)
                setReserveLeads(response.data.data)
                getCheckoutVerifier(response.data.data)
                setCouponApply(false)
            } else if (response.data.status === 204) {
                toast.error(
                <ToastContent message={response.data.message} />,
                    {duration:2000}             
                )
                history('/cart')
            } else if (response.data.status === 401) {
                    dispatch(handleLogout())
                    toast.error(
                        <ToastContent message={response.data.message} />,
                        {duration:3000}             
                    )
                    history('/login') 
            } else {
                toast.error(
                    <ToastContent message={response.data.message} />,
                        {duration:2000}             
                    )
            }
        })
        .catch(error => {
            console.log(error)
            if (error && error.status === 401) {
              toast.error(
                <ToastContent message={error.message} />,
                { duration:2000 }
              )
            } else if (error) {
              toast.error(
                <ToastContent message={error.message} />,
                { duration:2000 }
              )
            } 
        })
    }

    const cartStockVerifier = () => {
        console.log(cartItem)
        console.log('verifier')
        const d = []
        if (cartItem && cartItem.length > 0) {
        cartItem.map(items => (
            // d = {id: items.id, state: items.state, county:items.county, month:parseInt(items.lead_type_info.month), quantity:parseInt(items.quantity) ? parseInt(items.quantity) : 0, type_: parseInt(items.lead_type_info.type_)}
            d.push({id: items.id, state: items.state, county:items.county, month:items.lead_type_info['month'], quantity:parseInt(items.quantity) ? parseInt(items.quantity) : 0, type_: items.lead_type_info.type_, platform_id:items.lead_type_info.platform_id, category_id:items.category_id})
            )
          )
        }
        const config = {
          method: 'post',
          url: `${apiConfig.api.url}cart/stock/verifier`,
        //   url: `https://ivr-marketplace.herokuapp.com/v1/cart/stock/verifier`,
          data: d
      }
      console.log(d)
      
      axios(config)
      .then(function (response) {
          console.log(response)
          if (response.data.status === 200) {
            const cartData_ = []
              
                  let total_ = 0
                  let singleTotal = 0
                  console.log(response.data.data)
                                     
                  cartItem.forEach(element => {
                        console.log(element)
                        if (response.data.data[element.id] !== 0) {
                          console.log('notzero')
                          console.log(cartStock)
                          cartData_.push(element.id)
                          let single_tot = 0
                          single_tot = (element.lead_type_info['cost'] * element.quantity)
                          const ser = (single_tot * 3.5) / 100
                          singleTotal += single_tot + ser
    
                          total_ += (element.lead_type_info['cost'] * element.quantity)
                        }
                       
                      
                    })
                    // if (discountApplied) {
                    //     const discountPrice = total_ - ((discountCoupon.value * total_) / 100)
                    //     const discTotal = discountPrice + ((discountPrice * 3.5) / 100)
                    //     setTotal(discountPrice)
                    //     setTotalCost(discTotal)
                    // } else {
                    // const service = (total_ * 3.5) / 100
                    // const tot = total_ + service
                    setInitialAmount(total_)
                    setTotal(total_)
                    // setTotalCost(Math.round(singleTotal))
                    setTotalCost(singleTotal)
                    // setShoppingCart(cartData_)
                    // }                   
                setCartStock(response.data.data)
            
            } else if (response.data.status === 401) {
                dispatch(handleLogout())
                toast.error(
                    <ToastContent message={response.data.message} />,
                    {duration:3000}             
                )
                history('/login') 
            } else {
                toast.error(
                    <ToastContent message={response.data.message} />,
                    {duration:2000}             
                )
            }
      })
      .catch(error => {
        console.log(error)
        if (error && error.status === 401) {
          toast.error(
            <ToastContent message={error.message} />,
            { duration:2000 }
          )
        } else if (error) {
          toast.error(
            <ToastContent message={error.message} />,
            { duration:2000 }
          )
        } 
    })
    
         
    }
    
    const getMyPromocodeList = () => {
        const config = {
            method: 'get',
            url: `${apiConfig.api.url}promo_code/my/operating`
        }
        
        axios(config)
        .then(function (response) {
            console.log(response)
            if (response.data.status === 200) {
              setMypromocode(response.data.data)
                const arr = response.data.data
                const newArrayOfObj = arr.map(function(ar) {
                return (ar) ? (
                    { value: ar.id, label: `${ar.name}`, isDisabled: parseInt(ar.can_purchase_upto) === parseInt(ar.used_count)  }
                ) : (null)
                })
                console.log(newArrayOfObj)
                setListPromocode(newArrayOfObj)
            } else if (response.data.status === 401) {
               dispatch(handleLogout())
               toast.error(
                <ToastContent message={response.data.message} />,
                {duration:3000}             
                )
                history('/login') 
            } 
            // else {
            //     toast.error(
            //         <ToastContent message={response.data.message} />,
            //         { duration:2000 }
            //       )
            // }
        })
        .catch(error => {
          console.log(error)
          if (error && error.status === 401) {
            toast.error(
              <ToastContent message={error.message} />,
              { duration:2000 }
            )
          } else if (error) {
            toast.error(
              <ToastContent message={error.message} />,
              { duration:2000 }
            )
          } 
        })
    }
    const getCartData_ = () => {

        const config = {
            method: 'get',
            url: `${apiConfig.api.url}cart`
            // url: `https://ivr-marketplace.herokuapp.com/v1/cart`
        }
        
        axios(config)
        .then(function (response) {
            // console.log(response)
            const shpCartData_ = []
            // const dcart = []
            if (response.data.status_code === 200) {
                // setCartData(response.data.data)
                // let total_ = 0
                // let singleTotal = 0
                setCartData(response.data.data)
                response.data.data.forEach(element => {
                    let single_tot = 0
                    // dcart = {'cart_id': element.id , }
                    // cartData.push(element.id)
                    console.log(element)
                    console.log(element.lead_type_info['description'])
                    single_tot = (element.lead_type_info['cost'] * element.quantity)
                    shpCartData_.push({cart_id: element.id, quantity: element.quantity, state: element.state, county: element.county, per_cost: element.lead_type_info['cost'], total_cost: single_tot, description: element.lead_type_info['description'], platform_id:element.lead_type_info['platform_id']})
                })
                console.log('shpCartData', shpCartData_)
                // // const service = (total_ * 3.5) / 100
                // // const tot = total_ + service
                // setTotal(total_)
                // setTotalCost(Math.round(singleTotal))
                setShoppingCart(shpCartData_)
                if (!termsModal) {
                placeOrderCheck(response.data.data)
                }
                
            } else if (response.data.status === 401) {
             dispatch(handleLogout())
            } else if (response.data.status === 204) {
            //    setCartData([])
            }
        })
        .catch(error => {
            console.log(error)
            if (error && error.status === 401) {
              toast.error(
                <ToastContent message={error.message} />,
                { duration:2000 }
              )
            } else if (error) {
              toast.error(
                <ToastContent message={error.message} />,
                { duration:2000 }
              )
            } 
        })
    }
    console.log(shoppingCart)


    const getPaymentList = () => {
        const config = {
                method: 'get',
                url: `${apiConfig.api.url}card`
                // url: `https://ivr-marketplace.herokuapp.com/v1/card`
            }
            
            axios(config)
            .then(function (response) {
                // console.log(response)
                // console.log(response.data.status)
                if (response.data.status_code === 200) {
                    setPaymentList(response.data.data)
                    
                } else if (response.data.status === 500) {
                    toast.error(
                        <ToastContent message={response.data.message} />,
                        {duration:3000}             
                    )
                } else if (response.data.status === 401) {
                     dispatch(handleLogout())
                }
            })
            .catch(error => {
                console.log(error)
                if (error && error.status === 401) {
                  toast.error(
                    <ToastContent message={error.message} />,
                    { duration:2000 }
                  )
                } else if (error) {
                  toast.error(
                    <ToastContent message={error.message} />,
                    { duration:2000 }
                  )
                } 
            })
    }

    console.log(openPayment)
    console.log(displayPayment)
    // const getDiscountCoupons = () => {
    //     const config = {
    //         method: 'get',
    //         url: `${apiConfig.api.url}disount_code/availability`
    //     }
    //     axios(config).then((response) => {
    //         console.log('discount-response', response)
    //         if (response.data.status === 200) {
    //           const formatData = response.data.data.map((item) => {
    //             return {label : item.title, value: item.discount_percentage, limit:item.per_user_limit, discount_id:item.id}
    //           })
    //           setDiscountCoupons(formatData)
    //         } else if (response.data.status === 204) {
    //            setDiscountCoupons([])
    //         } else {
    //             setDiscountCoupons([])
    //             toast.error(
    //                 <ToastContent message={response.data.message} />,
    //                 { duration:3000 }
    //             )
    //         }
    //     }).catch((error) => {
    //         setDiscountCoupons([])
    //         toast.error(
    //             <ToastContent message={error.message}/>,
    //             { duration:3000 }
    //         )
    //      })
    // }
    useEffect(() => {
        // getDiscountCoupons()
        getAddresses()
        getMyPromocodeList()
        setInput([])
        // getLeadsType()
        cartStockVerifier()
        getCartData_()
        getPaymentList()
        dispatch(getCartData())
        paymentToggle('1')
        // if (radioBtn) setIsAddress(false)
        // const values1 = getValues()
        // console.log(values1)
        // if (!values1) setIsAddress(false)
        const st = State.getStatesOfCountry('US')
        const sts = st.map((s) => {
        return (s) ? (
            { value: s.isoCode, label: s.name, lat: s.latitude, log: s.longitude }
        ) : (null)
        })
        setStates(sts)
        
    }, [termsModal])

    const handleState = (val) => {
        
        console.log(val)
        const ct = City.getCitiesOfState('US', val.value ? val.value : val)
        console.log(ct)
        const cts = [{value: 'other', label: 'Other'}]
        if (ct && ct.length > 0) {
            ct.map((c) => {
                cts.push({value: c.name, label: c.name, lat: c.latitude, log: c.longitude})
            })
        setCounties(cts)
        } else setCounties(cts)
        //   setCurState(val)
        setValue("state", val)
        setValue("city", {})
        setValue("city_type", '')
        
    }
    
    // const handleCounty = (val) => {
    //     if (val.value === 'other') {
    //         setChangeCity(val.value)
    //         setValue("County", '')
    //       } else {
    //         setValue("County", val)
    //       }
    // }
    
useEffect(() => {
        if (listPromocode.length > 0) {
      console.log('listpromocode', listPromocode.some((u) => u.isDisabled === false))
     
       if (listPromocode.some((u) => u.isDisabled === false)) {
        console.log('enteresss')
        setSelectDisabled(false)
       }
        }
    }, [listPromocode])
    
useEffect(() => {
    if (editAddress && editAddress.city) {

    const c = counties.findIndex(s => s.value === editAddress.city)
    c > -1 ? setValue("County", counties[c]) : setValue("County", {})
    console.log(c)
    }

}, [counties])
    const handleEditAddress = (data, text) => {
        if (text === 'disable') {
            toggle('1', 'disable')
        } else {
            toggle('1', 'edit')

        }
        console.log(data)
        setEditAddress(data)
        const ad_type = addresstype.find(({ value }) => value === String(data.type_))
        const s = states.findIndex(s => s.value === data.state)
        console.log(s)
        const ct = City.getCitiesOfState('US', data.state)
        const cts = []
        if (ct && ct.length > 0) {
            ct.map((c) => {
            cts.push({value: c.name, label: c.name, lat: c.latitude, log: c.longitude})
            })
            setCounties(cts)
            console.log(ct)
            const c = ct.findIndex(s => s.name === data.city)
            console.log('c', c)
            c > -1 ? setValue("city", {value: ct[c].name, label: cts[c].label}) : setValue("city", null)
            console.log(ct[c])
        }
        console.log(states[s])
        setValue("id", data.id)
        // setValue("Name", data.name)
        // setValue("Phone", data.mobile_number)
        setValue("address_type", {value: ad_type.value, label: ad_type.label})
        setValue("address", data.address)
     

        // setValue("Country", {value: c.value, label: c.label})
        setValue("state", states[s])
        setValue("zip", data.zip_code)
        setValue("city_input", data.city)
    }
    // console.log(open)
    if (radioBtn) setIsAddress(true)
    const handleAddress = data => {
        console.log('data', data)
        const copyInput = {...input}
        delete data.cardNumber
        delete data.expiration
        delete data.name
        delete data.security_code
        console.log(data)
        // copyInput['add_address'] = true
        // copyInput['edit_address'] = false
        console.log("Input: ", copyInput)
        // console.log(data)

        const isNullish = Object.values(data).every(value => {
            console.log(value)
            if (value === null || value === undefined || value === '') {
            return true
            } else return false
        })
       
        if (!isNullish) {
           
            console.log(data)
            
            let d
            if (data.id && existingAddress === true) {
                 setAddAddress(false)
                 setAddressEdit(false)
                d = {
                    type_ : data.address_type['value'],
                    address: data.address,
                    state: data.state['value'],
                    city:  data.city_input,
                    country: 'United States of America',
                    
                    zip_code: data.zip,
                    id: data.id
                }
                setFinalAddress(d)
               
            } else if (data.id && existingAddress === false) {
                // setAddAddress(false)y
                
                setAddressEdit(true)
                d = {
                    type_ : data.address_type['value'],
                    address: data.address,
                    state: data.state['value'],
                    city:  data.city_input,
                    country: 'United States of America',
                    
                    zip_code: data.zip,
                    id: data.id
                }
                setFinalAddress(d)
            } else {
                
                  setAddressEdit(false)
                d = {
                    type_ : data.address_type['value'],
                    
                    // name: data.Name,
                    // mobile_number: data.Phone,
                    address: data.address,
                    state: data.state['value'],
                    city:  data.city_input,
                    country: 'United States of America',
                    // city: data.City['value'],
                    // state: data.State['value'],
                    zip_code: data.zip
                }
                console.log('address-input', d)
                setFinalAddress(d)
                
               
            }
            
            console.log("Input: ", d)
            
            setSaveAddress(true)
            // setAddressChecked(true)
            console.log(d)
            copyInput['address'] = d
            console.log(copyInput)
            setInput(copyInput)
            setIsAddress(true)
            toast.success(
                <ToastContent message='Address is Confirmed!' />,
                { duration:4000 }
            )
            setDisplayPayment(true)
        
        } 
    }
    

    const handleChangeAddress = (event, index, item) => {
        console.log(item)
        handleEditAddress(item, 'disable')
        
        // toggle('0')
        const target = event.target
        // const value = target.value
        console.log(target.value)
        console.log(radioBtn)
        // console.log(value)
        index === target.value ? setRadioBtn(true) : setRadioBtn()
        

        const copyInput = {...input}
        console.log(copyInput)
        copyInput['add_address'] = false
        copyInput['edit_address'] = false
        copyInput['address'] = item
        // delete copyInput.address
        setInput(copyInput)
        setIsAddress(true)
        console.log(copyInput)
    }

    const handleSavedCard = (element) => {
        console.log(element)
        setIsCard(true)
        setSavedCard(element.payment_profile)
        setAuthData(element.saved_authorize_data)
        console.log(savedCard)
        console.log(element)
    }
        
    const handleFormSubmit = (data) => {
        console.log("TEEEE", data)
       
        console.log(input)
        // const values = getValues()
         const values2 = getValues2()
         console.log('card-data', values2)
        const copyContent = {}
        // let copyContent = {}
        // console.log(values)
       let isNullish 
       if (!data.expiration) {
        console.log('entered first if')
        isNullish = false
       } else {
        isNullish = Object.values(values2).every(value => {
            if (value === undefined || value === null || value === '') {
            return true
            } else {
                return false
            }
        })
       }

        
       if (!isNullish) {
        console.log(isAddress)
        console.log(isNullish)
        console.log(saveAddress)
      

        console.log('dadasd', copyContent)
        console.log(isNullish)
        let url_
        // console.log(isAddress)

        if (!isCard && total !== 0) {
            toast.error(
                <ToastContent message="Please chose a payment method!" />,
                { duration:3000 }
            )
        } else  {
            setLoading(true)
            console.log('API Call on Submit')
            if (savedCard) {
            console.log(savedCard)  
                url_ = `${apiConfig.api.url}card/charge_profile/${savedCard}`
                console.log(cardCvv)
                copyContent['cardcode'] = cardCvv
                copyContent['saved_authorize_data'] = authData
            } else {
                url_ = `${apiConfig.api.url}card/charge_profile/new_card`
                console.log(savedCard)
                if (total > 0) {
                    const expiry = values2.expiration.split('/')
                    copyContent['card_number'] = values2.cardNumber.replace(/\s/g, "")
                    copyContent['expiry_month'] = expiry[0].replace(/\s/g, "")
                    copyContent['expiry_year'] = expiry[1].replace(/\s/g, "")
                    copyContent['expiry_date'] = `${expiry[1].replace(/\s/g, "")}-${expiry[0].replace(/\s/g, "")}`
                    // copyContent['card_cvv'] = values2.security_code
                    copyContent['cardcode'] = values2.security_code
                    copyContent['cardholder_name'] = values2.name
                    copyContent['save_card'] = checked
                }
            }
            copyContent['amount'] = parseFloat(totalCost).toFixed(2)

            console.log(url_)
            const cartItems_ = []
            console.log(shoppingCart)
            if (!couponApply) {
                shoppingCart.forEach(item => {
                    reserveLeads.forEach(items => {
                    if (item.cart_id === items.id) {
                        cartItems_.push({
                            cart_id: item.cart_id, 
                            quantity: item.quantity, 
                            state: item.state, 
                            county: item.county, 
                            per_cost: item.per_cost, 
                            total_cost: item.total_cost, 
                            description: item.description,
                            platform_id:item.platform_id,
                            id_for_duplicate_cart_items: 
                            items.id_for_duplicate_cart_items,
                            lead_type_info_id: items.lead_type_id
                        })
                    } 
                    })
                })
                if (discountApplied && Boolean(discountCoupon)) {
                    copyContent['discount_id'] = discountCoupon.id
                }
            } else {
                promocodeCart.forEach(item => {
                    reserveLeads.forEach(items => {
                    if (item.cart_id === items.id) {
                        cartItems_.push({cart_id: item.cart_id, 
                            quantity: item.quantity, 
                            state: item.state, 
                            county: item.county, 
                            per_cost: item.per_cost, 
                            total_cost: item.total_cost, 
                            description:item.description, 
                            platform_id:item.platform_id,
                            promo_code_id: item.promo_code_id,
                            promo_purchasing_count:item.promo_purchasing_count,
                            id_for_duplicate_cart_items: items.id_for_duplicate_cart_items,
                            lead_type_info_id: items.lead_type_id
                        })
                        } 
                    })
                })
                copyContent['promo_code_id'] = couponID
                copyContent['promo_purchasing_count'] = totalUsed
                if (discountApplied && Boolean(discountCoupon)) {
                    copyContent['discount_id'] = discountCoupon.id
                }
            }
            // copyContent['add_address'] = addAddress
            copyContent['edit_address'] = addressEdit
            if (addressEdit) {
                copyContent['add_address'] = false
            } else {
               copyContent['add_address'] = addAddress
            }
            copyContent['address'] = finalAddress
            console.log(cartItems_)
            copyContent['cart_details'] = cartItems_
            const config = {
                method: 'post',
                url: url_,
                data: copyContent
            }   
            console.log(config)
            
            console.log(copyContent)
            setInput(copyContent)
            if (savedCard && cardCvv === '') {
                setLoading(false)
                toast.error(
                    <ToastContent message='Please provide a valid Cvv' />,
                    { duration:4000 }
                ) 
            } else {
            axios(config)
            .then(function (response) {
                console.log(response)
                if (response.data.status_code === 200) {
                    localStorage.removeItem('used_minute')
                    localStorage.removeItem('used_second')
                    const orderDetails = {order_id: response.data.order_id, order_total:totalCost.toFixed(2), email: userData.dt.email}
                    localStorage.setItem('orderDetails', JSON.stringify(orderDetails))
                    setInput([]) 
                    toast.success(
                        <ToastContent message='Order Placed Successfully Please check your data on Purchased Leads!' />,
                        { duration:4000 }
                    )
                    setInput([])
                    dispatch(getCartData())
                    history('/payment-success')
                }  else if ((response.data.status === 401)) {
                    dispatch(handleLogout())
                    toast.error(
                        <ToastContent message={response.data.message} />,
                        {duration:3000}             
                    )
                    history('/login')       
                } else {
                    toast.error(
                        <ToastContent message={response.data.message} />,
                        {duration:3000}             
                    )
                }
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
                if (error && error.status === 401) {
                  toast.error(
                    <ToastContent message={error.message} />,
                    { duration:2000 }
                  )
                  setLoading(false)
                } else if (error) {
                  toast.error(
                    <ToastContent message={error.message} />,
                    { duration:2000 }
                  )
                  setLoading(false)
                } 
            })
        }
        } 
    }
    }

    const handleSaveCard = (event) => {
        setChecked(event.target.checked)
    }
    const handleCardNumber = (e) => {
        console.log(e.target.value)
        if (e.target.value) {
         setValue2('cardNumber', e.target.value)
        setCardError(false)
        } else {
        setCardError(true)
        }
    }
// const handleCoupons = (e) => {
//     console.log(e.target.value)
//     setCoupons(e.target.value)
// }
const handleCouponName = (event) => {
   const val = event.target.value.toUpperCase()
   setCouponName(val)
}
const CouponsApply = () => {
    // console.log(leads)
    const shpCartData__ = []

    console.log('CouponsApply')
    console.log(coupon)
    let lead_id = ''
    let TotalUsed = 0
    let can_purchase_upto = ''
    let used_count = ''
    let purchase_upto = ''
    let promo_codeId = ''
    
    mypromocode.map(cup_code => {
        if (cup_code.name === coupon.label) {
            // console.log(coupon.label)
            lead_id = cup_code.lead_type_info_id
            promo_codeId = cup_code.id
            can_purchase_upto = cup_code.can_purchase_upto
            used_count = cup_code.used_count
            purchase_upto = can_purchase_upto - used_count
            setCouponID(promo_codeId)
        }
    })
        let purchase_dec = purchase_upto
        let grandTotalCost = 0
        let grandtotal_ = 0
        let OthersingleTot = 0
        console.log(carData)
        if (!discountApplied) {
        if (lead_id && promo_codeId) {
                const index_lead_id = carData.findIndex(item => item.lead_type_info.id === lead_id)
                console.log(index_lead_id)
                let single_tot = 0
                let lead_total = 0
                let used_purchase = 0
                let ser = 0
            if (index_lead_id > -1) {
                carData.map(cartitems => {
                    console.log(cartitems.lead_type_info.id)
                    console.log(lead_id)
                    console.log(cartitems)
                
                    if (cartitems.lead_type_info.id === lead_id) {
                        console.log('equal')
                            if (purchase_dec >= 1) {
                                purchase_dec = purchase_dec - cartitems.quantity 
                                if (purchase_dec >= 0) {
                                    used_purchase = cartitems.quantity
                                    single_tot = 0
                                } else {
                                    used_purchase = cartitems.quantity + purchase_dec
                                    const remaining = cartitems.quantity - used_purchase
                                    single_tot = (cartitems.lead_type_info['cost'] * remaining)
                                }
                                ser = ((single_tot) * 3.5) / 100
                                grandTotalCost += single_tot + ser
                                grandtotal_ += single_tot

                                lead_total += single_tot
                                TotalUsed += used_purchase
                                console.log(single_tot)
                                console.log(lead_total)
                                console.log(used_purchase)
                            
                                shpCartData__.push({
                                    platform_id:cartitems.platform_id,
                                    cart_id: cartitems.id, 
                                    quantity: cartitems.quantity,
                                    state: cartitems.state, 
                                    promo_purchasing_count: used_purchase,
                                    promo_code_id: promo_codeId,
                                    county: cartitems.county, 
                                    per_cost: cartitems.lead_type_info['cost'], 
                                    total_cost: single_tot, 
                                    description: cartitems.lead_type_info['description']})
                                console.log(shpCartData__)
                            }
                            setCouponApply(true)
                            setTotalUsed(TotalUsed)
                        }  else if (cartitems.lead_type_info.id !== lead_id) {
                            console.log('not equal')
                            OthersingleTot = (cartitems.lead_type_info['cost'] * cartitems.quantity)
                            if (lead_total > 0) {
                                ser = ((OthersingleTot + lead_total) * 3.5) / 100  
                            } else {
                                ser = ((OthersingleTot - lead_total) * 3.5) / 100
                            }
                            grandTotalCost += OthersingleTot + ser
                            shpCartData__.push({
                                platform_id: cartitems.platform_id,
                                cart_id: cartitems.id, 
                                quantity: cartitems.quantity,
                                state: cartitems.state, 
                                promo_purchasing_count: '',
                                promo_code_id: '',
                                county: cartitems.county, 
                                per_cost: cartitems.lead_type_info['cost'], 
                                total_cost: OthersingleTot, 
                                description: cartitems.lead_type_info['description']})
                            grandtotal_ += (cartitems.lead_type_info['cost'] * cartitems.quantity) 
                        }
                        // if (discountApplied) {
                        //     console.log('before-calculation', grandTotalCost)
                        //     const discountAmount = grandtotal_ - (((discountCoupon.value * grandtotal_) / 100))
                        //     const discountTotal = discountAmount + ((discountAmount * 3.5) / 100)
                        //     setTotal(discountAmount)
                        //     setTotalCost(discountTotal)
                        //     console.log('after-calculaton', discountTotal, discountAmount)
                        // }  else {
                            setTotalCost(grandTotalCost)
                            setTotal(grandtotal_)
                        // }
                    
                    })
                    console.log(shpCartData__)
                    setPromocodeCart(shpCartData__)
            } else {
                toast.error(
                    <ToastContent message="The Coupons code applied is not in the Cart!" />,
                    { duration:3000 }
                )  
            }
        }
    } else {
        toast.error(
            <ToastContent message="Coupon Code and Discount Code can't be applied at the same time" />,
            {duration:3000}             
        )
    }
}

const CouponsReset = () => {
    cartStockVerifier()
    setCouponApply(false)
    setCouponID('')
    setCoupons('')
}
const discountReset = () => {
    setDiscountApplied(false)
    setDiscountCoupon(null)
    cartStockVerifier()
    setCouponName('')
}


const handlelistPromocode = (e) => {
    console.log(e)
    setCoupons(e)
}

console.log(promocodeCart)
// console.log(promocode)
const tenMinutes = 10 * 60 * 1000 
const prevTime = ((saved_minute * 60) + saved_second) * 1000
// const tenMinutes = 10000

const targetTime = saved_minute !== ''  ?  Date.now() + prevTime : Date.now() + tenMinutes
const moveToCart = () => {
    toast.error(
        <ToastContent message="Time is Up..Redirecting to Cart" />,
        {duration:3000}             
    )
    history('/cart')
    // history.push('/cart')
}
const renderer = ({  minutes, seconds, completed }) => {
    if (completed) {
    localStorage.removeItem('used_minute')
    localStorage.removeItem('used_second')
     moveToCart()
    } else {
        localStorage.setItem('used_minute', minutes)
        localStorage.setItem('used_second', seconds)
      return (
        <span>
        {minutes} :{seconds.toString().padStart(2, '0')} 
        </span>
      )
    }
  }

const discountApply = () => {
    if (!couponApply) {
        setApplyLoading(true)
        const config = {
            method: 'post',
            url: `${apiConfig.api.url}discount_code/validate`,
            // url: `https://ivr-marketplace.herokuapp.com/v1/checkout/reserve_leads`,
            data: {title: couponName}
            }
            axios(config).then((response) => {
                console.log('response', response)
                setApplyLoading(false)
                if (response.data.status === 200) {
                    setDiscountCoupon(response.data.data)
                    const discountPrice = total - ((response.data.data.discount_percentage * total) / 100)
                    const discTotal = discountPrice + ((discountPrice * 3.5) / 100)
                    setTotal(discountPrice)
                    setTotalCost(discTotal)
                    setDiscountApplied(true)
                } else {
                    toast.error(
                        <ToastContent message={response.data.message} />,
                        {duration:3000}             
                    )
                }
            }).catch((error) => {
                setApplyLoading(false)
                toast.error(
                    <ToastContent message={error.message} />,
                    {duration:3000}             
                )
            })
     if (discountCoupon && discountCoupon.value) {
    
     } else {
        setDiscountApplied(false)
     }
    } else {
        toast.error(
            <ToastContent message="Promo Code and Discount Code can't be applied at the same time" />,
            {duration:3000}             
        )
    }
}
return (
    <Fragment>
        <Row>
        <Col md={12} className='mb-2'>
                <div className="d-flex align-items-center justify-content-between">
                    <h3>Checkout</h3>
                    {
                   !termsModal && <h3>Remaining Time :   <Countdown date={targetTime} renderer={renderer} controlled={false} /> </h3>
                    }
                </div>
            </Col>
            {/* <Col md={12} className='mb-2'>
            {mypromocode &&
                mypromocode.map(code => {
                return (
                    <Alert color='info'>
                    <h4 className='alert-heading'>Coupon: {code.name}</h4>
                    <div className='alert-body'>
                        {code.description}. You can use upto {code.can_purchase_upto - code.used_count }
                    </div>
                    </Alert>
                )
                })
            }
            </Col> */}
    <Col md={8} className='mb-2'>
        <Accordion className='accordion-without-arrow' open={open} toggle={toggle}>
            <AccordionItem>
                <AccordionHeader targetId='1'>                    
                    <Button block style={{textAlign: 'left', border: `2px solid #e6e9ed`}} color=''><Plus size={18} className="check_address_btn" style={{color: '##141452'}}/><span style={{color: '##141452'}}>Add New Address</span></Button>
                </AccordionHeader>
                <AccordionBody accordionId='1'>
                    {/* <small>Be sure to check "Deliver to this address" when you have finished</small> */}
                    <h5 className="mb-1">Please use the billing address associated with your card</h5>
                    {/* <Form className='list-view product-checkout'> */}
                    <FormProvider {...form}>
                    <Form className='list-view product-checkout' onSubmit={handleSubmit(handleAddress)}>
                    <Row>
                        
                        
                    <Col md='6' sm='12'>
                    <div className='mb-2'>
                        <Label for='checkout-apt-number'>Address<span className="required_color">*</span></Label>
                            <Controller
                                control={control}
                                name="address"
                                rules={{ required: 'Address is required' }}
                                render={({ field }) => (
                                    <Input
                                    {...field}
                                    innerRef={field.ref} // Pass the correct ref prop from react-hook-form
                                    autoFocus
                                    type="text"
                                    id="address"
                                    disabled={fieldDisable}
                                    invalid={errors.address } 
                                    />
                                )}
                            />
              {errors.address && <p className='text-danger'>{errors.address.message}</p>}

                    </div>
                    </Col>
                    <Col md='6' sm='12'>
                    <div className='mb-2'>
                        <Label for='checkout-landmark'>Country<span className="required_color">*</span></Label>
                        
                            <Input
                            autoFocus
                            type='text'
                            disabled={fieldDisable}
                            value='United States of America'
                            />

                    </div>
                    </Col>
                    <Col md='6' sm='12'>
                    <div className='mb-2'>
                        <Label for='States'>State<span className="required_color">*</span></Label>
                        <Controller
                        control={control}
                        name="state"
                        rules={{ required: 'State is required' }} // Add validation rules
                        render={({ field }) => (
                        <>
                        <Select
                            {...field}
                            autoFocus

                            options={states}
                            id="state"
                            invalid={errors.state }
                            isDisabled={fieldDisable}              
                            onChange={(event) => {
                            field.onChange(event)
                            handleState(event)
                            }}
                            className={errors.state ? 'is-invalid' : ''} 
                        >
                            
                        </Select>
                        {errors.state && <p className="text-danger">{errors.state.message}</p>}
                        </>
                        )}
/>
                        
                    </div>
                    </Col>
                    <Col md='6' sm='12'>
                    <div className='mb-2'>
                    <Label for='County'>City<span className="required_color">*</span></Label>                   
                
                    <Controller
                    control={control}
                    name="city_input"
                    rules={{ required: 'City is required' }}
                    render={({ field }) => (
                    <Input
                        {...field}
                    innerRef={field.ref} 
                    autoFocus
                    type="text"
                    id="city_input"
                    invalid={errors.city_input} 
                    disabled={fieldDisable}

                    />

                    )} />
            

{errors.city_input && <p className="text-danger">{errors.city_input.message}</p>}

                                                                                                    <small>Please select the State first</small>
                    </div>
                        {/* {errors.County && <p className='text-danger'>City is required</p>} */}
                    </Col>
                    <Col md='6' sm='12'>
                        <div className='mb-2'>
                        <Label for='checkout-pincode'>Zip Code<span className="required_color">*</span></Label>
                        <Controller
                            control={control}
                            name="zip"
                            rules={{ required: 'Zip code is required' }}
                            render={({ field }) => (
                            <Input
                            {...field}
                            innerRef={field.ref} // Pass the correct ref prop from react-hook-form
                            autoFocus
                            type="number"
                            id="zip"
                            invalid={errors.zip } 
                            disabled={fieldDisable}

                            />
                            )}
                            />
    {errors.zip && <p className='text-danger'>{errors.zip.message}</p>}


                        </div>
                    </Col>
                        <Col md='6' sm='12'>
                        <div className='mb-2'>
                            <Label for='checkout-state'>Address Type<span className="required_color">*</span></Label>
                            <Controller
control={control}
name="address_type"
rules={{ required: 'Address Type is required' }} // Add validation rules
render={({ field }) => (
    <>
    <Select
        {...field}
        autoFocus

        options={addresstype}
        id="state"
        invalid={errors.address_type }
        isDisabled={fieldDisable}


        // Set the invalid prop based on errors
    >
        
    </Select>
    {errors.address_type && <p className="text-danger">{errors.address_type.message}</p>}
    </>
)}
/>
                            
                            {/* {errors.AddressType && <p className='text-danger'>Address Type is required</p>} */}
                        </div>
                        </Col>
                        { !existingAddress &&
                        <Col sm='12' className="mb-1">
                            <div>
                                <Input type='checkbox'  id='basic-cb-checked' onChange={event => handleSaveAddress(event)}/>
                                <Label for='basic-cb-checked' className='form-check-label'>
                                    Save Address for future use?
                                </Label>
                            </div>
                            </Col>
}
                            <Col sm="12" >
                        <Button.Ripple type='submit' className='btn-next delivery-address' color='primary'>
                            Confirm Address
                        </Button.Ripple>
                        </Col>
                    </Row>
                    </Form>
                    </FormProvider>
                </AccordionBody>
            </AccordionItem>
        </Accordion>
    
    </Col>
    <Col md={4}> 
        <Card>
            <CardBody>
                {addressList && 
                    addressList.map((item, index) => (
                        <Accordion open={addOpen} toggle={addressToggle}>
                            <AccordionItem index={index} className='border-bottom'>
                            <AccordionHeader targetId={`${item.id}`}>
                            <Input type="radio"className="p-0" name={`address`} value={index} checked={radioBtn} id={`address${item.id}`} onChange={(event) => handleChangeAddress(event, index, item)}></Input>
                            {/* <Input type="radio"className="p-0" name={`address`} checked={radioBtn} id={`address${item.id}`} data-index={index} onClick={(event) => handleChangeAd(event.target, index)} onChange={handleChangeAddress}></Input> */}
                            <p tag='h4' className="w-100 text-md-start address_accordian_name">{userData.dt.name}</p>
                            <Edit2 size={14} className="address-edit" onClick={() => handleEditAddress(item)}/>
                            </AccordionHeader>
                            <AccordionBody accordionId={`${item.id}`}>
                                    <p className='mb-0'>{item.house_no} {item.landmark} </p>
                                    <p>{item.city} {item.state} {item.zip_code}</p>
                                        {/* <CardText>UTC-5: Eastern Standard Time (EST)</CardText> */}
                                    <p>{item.mobile_number}</p>
                            </AccordionBody>
                            </AccordionItem>
                                        
                        </Accordion>   
                    ))
                    } 
                    {!addressList.length && 
                    <CardText>No Address Found</CardText>
                    }
            </CardBody>
        </Card>

    </Col>          
    <Col md={8}>
        { displayPayment &&
        <Card>
            <CardBody>
            {/* <Form className='list-view product-checkout'> */}
                <Card>
                <CardHeader className='flex-column align-items-start'>
                { total !== 0 &&
                    <>
                    <CardTitle tag='h4' className="mb-1">Payment options</CardTitle>
                    <CardText className='text-muted mt-25'>Be sure to click on correct payment option</CardText>
                    </>
                }
                </CardHeader>
                <CardBody>
                    <Form onSubmit={ handleSubmit2(handleFormSubmit) }>
                { total !== 0 &&
                    <>
                    {paymentList &&
                    paymentList.map((element) => (
                        <>
                        <h6 className='card-holder-name my-75'>{element.nameOnAccount}</h6>
                        <Label check>
                            <Input type="radio" value='1'
                                onChange={() => handleSavedCard(element)} 
                                name="card" id={element.payment_profile}/> 
                            Credit/Debit Card {element.cardNumber}
                        </Label>
                        <div className='customer-cvv mt-1'>
                            <div className='form-inline d-flex align-items-center'>
                                <Label className='label-cvv mb-50' for='card_holder_cvv'>
                                Enter Card Code
                                </Label>
                                <Input className='input-cvv ml-sm-75 ml-0 mb-50' id='card_holder_cvv' disabled={element.payment_profile !== savedCard} onKeyUp={(e) => {
                                    setCardCvv(e.target.value)
                                }}/>
                                {/* <Label for='Phone'>Card Security Code<span className="required_color">*</span></Label>
                                    <Controller
                                        id='card_holder_cvv'
                                        name='card_holder_cvv'
                                        control={control2}
                                        render={({ field }) => (
                                        <Input
                                        // autoFocus
                                        type='number'
                                        placeholder='123'
                                        invalid={errors2.card_holder_cvv && true}
                                        {...field}
                                        />
                                        )}
                                    /> */}
                            </div>
                        </div>
                        </>
                    ))}
                    
                    
                    <Accordion className='accordion-without-arrow' open={openPayment} toggle={paymentToggle}>
                        <AccordionItem>
                            <AccordionHeader targetId='1'>                    
                                <Button className="w-100 mb-1" style={{textAlign: 'left', border: `2px solid #e6e9ed`}} color=''><Plus size={18} className="check_address_btn" style={{color: '##141452'}}/><span style={{color: '##141452'}}>Add New Payment Method</span></Button>
                            </AccordionHeader>
                            <AccordionBody accordionId='1'>
                                <Label check>
                                    <Input type="radio"  name="card" value='1' id={`newCard`} onChange={() => {
                                        setSavedCard('')
                                        setIsCard(true)
                                    }}/> Credit/Debit Card
                                </Label>

                                {/* <Form 
                                onSubmit={handleSubmit2(handleFormSubmit)}
                                >  */}

                                <Card>

                                    <CardBody>
                                        <div className="mb-2">
                                        <small>Pay securely using your credit card </small>
                                        </div>
                                            <Row className='gy-1 pt-75'>
                                            <Col md={6} xs={6}>
                                                <div>
                                                <Label for='credit-card'>Card Number<span className="required_color">*</span></Label>
                                                <Cleave 
                                                    className='form-control'
                                                    placeholder='0000 0000 0000 0000' 
                                                    options={options}
                                                    invalid={cardError}
                                                    onChange={(e) => handleCardNumber(e)}
                                                    />
                                                </div>
                                                {cardError && <small className="text-danger">Card Number is required</small>}
                                            </Col>
                                            <Col md={6} xs={6}>
                                            <div>
                                                <Label for='Name'>CardHolder Name<span className="required_color">*</span></Label>
                                                <Controller
                                                        id='name'
                                                        name='name'
                                                        control={control2}
                                                        rules={{ required: 'Card holder name is required',
                                                                maxLength: {
                                                                            value: 22,
                                                                            message: 'Name cannot be more than 22 characters'
                                                                            },
                                                                validate: validateNoNumbers
                                                                }}
                                                        render={({ field }) => (
                                                        <Input
                                                        id="name"
                                                        type='text'
                                                        placeholder='Card Holder Name'
                                                        invalid={errors2.name && true}
                                                        {...field}
                                                        />
                                                        )}
                                                    />
                                                            {/* {errors2.name && <p className='text-danger'>{errors2.name.message}</p>} */}
                                                            {errors2.name && errors2.name.type === "required" && (
                                                                <span className="text-danger">
                                                                    Name is required
                                                                </span>
                                                                        )}
                                                             {errors2.name && errors2.name.type === "maxLength" && (
                                                                <span className="text-danger">
                                                                    Name cannot be more than 22 characters
                                                                </span>
                                                                        )}
                                                            {errors2.name && errors2.name.type === "validate" && (
                                                                <span className="text-danger">
                                                                    Name should not contain numbers
                                                                </span>
                                                                        )}

                                                </div>
                                            </Col>
                                            <Col md={6} xs={6}>
                                            <div>
                                                <Label for='Phone'>Expiration(MM/YYYY)<span className="required_color">*</span></Label>
                                                
                                                
                                                    <Controller
                                                    id='expiration'
                                                    name='expiration'
                                                    control={control2}
                                                    rules={{ required: 'Expiry Date is required' }}

                                                    render={({ field }) => (
                                                    <InputMask
                                                        {...field}
                                                        id='expiration'
                                                        mask="99/9999"
                                                        // value={field.value}
                                                        className="form-control"
                                                        // onChange={field.onChange}
                                                        invalid={errors2.expiration}
                                                    >
                                                        {(inputProps) => (
                                                        <input
                                                        {...field}
                                                        id='expiration'
                                                        invalid={errors2.expiration}

                                                        placeholder='01/2023'
                                                            {...inputProps}
                                                            type="text"
                                                        />
                                                        )}
                                                    </InputMask>
                                                    )}
                                                    />
                                                    <>{console.log('errors2', errors2)}</>
                                                    {errors2.expiration && <p className='text-danger'>{errors2.expiration.message}</p>}
                                                
                                                </div>
                                            </Col>
                                            <Col md={6} xs={6}>
                                            <div>
                                                <Label for='Phone'>Card Security Code<span className="required_color">*</span></Label>
                                                <Controller
                                                    id='security_code'
                                                    name='security_code'
                                                    control={control2}
                                                    rules={{ required: 'Security Code is required',
                                                             maxLength: {
                                                                          value: 20,
                                                                          message: 'Zip Code cannot be more than 20 characters'
                                                                        } 
                                                             }}

                                                    render={({ field }) => (
                                                    <Input
                                                    // autoFocus
                                                    id="security_code"
                                                    type='number'
                                                    placeholder='123'
                                                    invalid={errors2.security_code}
                                                    {...field}
                                                    />
                                                    )}
                                                />
                                            {errors2.security_code && <p className='text-danger'>{errors2.security_code.message}</p>}
                                                </div>
                                            </Col>
                                            <Col md={6} xs={6}>
                                                <div>
                                                <Input type='checkbox' defaultChecked={checked} id='basic-cb-checked' onChange={event => handleSaveCard(event)}/>
                                                <Label for='basic-cb-checked' className='form-check-label'>
                                                Save card for future use?
                                                </Label>
                                                </div>
                                            </Col>
                                                        
                                            </Row>
        

                                        {/* <Row>
                                        <Col md='12' sm='12'>
                                        <div className='mb-2'>
                                            <Label for='card_number'>Card Number <span className="required_color">*</span></Label>
                                            <Input
                                            name='card_number'
                                            id='card_number'
                                            placeholder='4007 0000 0002 7'
                                            />
                                        </div>
                                        </Col>
                                        <Col md='6' sm='12'>
                                        <div className='mb-2'>
                                            <Label for='expiration'>Expiration (MM/YY) <span className="required_color">*</span></Label>
                                            <Input
                                            name='expiration'
                                            id='expiration'
                                            placeholder='01 / 2023'
                                            />
                                        </div>
                                        </Col>
                                        <Col md='6' sm='12'>
                                        <div className='mb-2'>
                                            <Label for='security_code'>Card Security Code <span className="required_color">*</span></Label>
                                            <Input
                                            name='security_code'
                                            id='security_code'
                                            placeholder='123'
                                            />
                                        </div>
                                        </Col>
                                        </Row> */}
                                    </CardBody>
                                </Card>
                                
                                {/* </Form> */}

                            </AccordionBody>
                        </AccordionItem>
                    </Accordion>
                    <hr/>
                    </>
                    }
                    <Col sm='12'>
                    {((savedCard && Object.keys(savedCard).length > 0)  || ((!savedCard || Object.keys(savedCard).length === 0) && !isCard)) && 
                    <Button.Ripple type='button' onClick={handleFormSubmit}
                
                            className='btn-next delivery-address' color='primary'>
                            Place Order   {console.log(1)}
                            </Button.Ripple>
                    }
                        
                    {((!savedCard || Object.keys(savedCard).length === 0) && isCard) &&
                        <Button.Ripple 
                            type='submit' 
                            className='btn-next delivery-address' color='primary'>
                            Place Order 
                            {console.log(2)}
                        </Button.Ripple>
                    }
                    </Col>
                    </Form>
                    </CardBody>
                </Card>
            {/* </Form> */}
            </CardBody>
        </Card>
}
    </Col>
    <Col md={4}>
        <Card>
            <Card>
            <CardBody>
                {!couponApply &&
                <InputGroup className='coupons mb-2 d-flex ' >
                    <>
                    <Select
                        theme={selectThemeColors}
                        className='react-select coupon-list flex-grow-1'
                        classNamePrefix='select'
                        isClearable
                        placeholder={selectDisabled === true ? 'No Promo Code available' : 'Select Promo Code..'}
                        name='listpromocode'
                        options={listPromocode}
                        onChange={handlelistPromocode} 
                        isDisabled={selectDisabled === true}
                    />
                    <Button className="w-10"  onClick={() => CouponsApply()} color="primary" disabled={totalCost <= 0}>Apply</Button>
                    </>
                </InputGroup>
                }
                 {couponApply && 
                <>
                <div >
                    <Alert color="success" className="d-flex justify-content-between align-items-center mb-2">
                        <span className='ms-1'>Coupon Code Applied!</span> 
                        <Button className="mr-1" color="primary" onClick={() => CouponsReset()}> Reset </Button>
                    </Alert>
                </div>
                </>}
                {/* <div className="text-center mt-1 mb-1">
                    <p>OR</p>
                </div> */}
                {
                    !discountApplied &&  <InputGroup className='coupons mb-2 d-flex ' >
                    <>
                    <Input type='text' placeholder="Enter Coupon Code" onChange={(event) => handleCouponName(event)} value={couponName}></Input>
                    {/* <Select
                        theme={selectThemeColors}
                        className='react-select coupon-list flex-grow-1'
                        classNamePrefix='select'
                        placeholder= 'Select Discount Coupon'
                        name='listpromocode'
                        options={discountCoupons}
                        onChange={handleDiscountCoupon} 
                    /> */}
                    <Button className="w-10"  onClick={() => discountApply()} color="primary" disabled={totalCost <= 0 || couponName === '' || applyLoading}>{applyLoading && <Spinner size='sm' className="me-1"></Spinner>}Apply</Button>
                    </>
                </InputGroup>
                }
                
                {discountApplied && 
                <>
                <div >
                    <Alert color="success" className="d-flex justify-content-between align-items-center mb-2">
                        <span className='ms-1'>{`${discountCoupon.discount_percentage}% Discount Applied!`}</span> 
                        <Button className="mr-1" color="primary" onClick={() => discountReset()}> Reset </Button>
                    </Alert>
                </div>
                </>}
                <div className='price-details'>
                <h5 className='price-title mb-2'><b>Price Details</b></h5>
                <div className='d-flex justify-content-between price-detail mb-1'>
                    <div className='detail-title'>Total Cost</div>
                    <div className='detail-amt'>${parseFloat(initialAmount).toFixed(2)}</div>
                </div>
                {
                    (couponApply || discountApplied) && <div className='d-flex justify-content-between price-detail mb-1'>
                    <div className='detail-title'>Revised Total</div>
                    <div className='detail-amt'>${parseFloat(total).toFixed(2)}</div>
                </div>
                }
                
                <div  className='d-flex justify-content-between price-detail'>
                    <div className='detail-title'>Service Fee</div>
                    <div className='detail-amt'>3.5%</div>
                </div>
                <hr />
                    <div className='price-detail d-flex justify-content-between mb-2'>
                    <div className='detail-title detail-total'><b>Net Payable Amount</b></div>
                    <div className='detail-amt font-weight-bolder'><b>${parseFloat(totalCost).toFixed(2)}</b></div>
                    </div>
            </div>
            </CardBody>
            </Card>
        </Card>
    </Col>
        </Row>
        <div>
                <CheckoutTerms termsModal={termsModal} setTermsModal={setTermsModal}/>
            </div>
        {loader &&
        <ComponentSpinner/>
        }
    </Fragment>

)


}

export default CheckoutModified
