import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { getToken } from '@utils'
import { toast} from 'react-hot-toast'

import axios from "axios"
// ** Third Party Componentsimport Flatpickr from 'react-flatpickr'
import Flatpickr from 'react-flatpickr'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import '@styles/react/libs/react-select/_react-select.scss' 
import moment from 'moment'
import ReactPaginate from 'react-paginate'
import DataTable from 'react-data-table-component'
import { ChevronDown, Plus, MoreVertical, Edit2, UserPlus, Delete, Check, X, Eye, Edit, Trash } from 'react-feather'
import {
  Card,
  CardHeader,
  CardTitle,
  Button, 
  Form,
  UncontrolledDropdown, 
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Label,
  FormGroup,
  Row,
  Col,
  Modal, 
  ModalBody, 
  ModalHeader,
  CardBody,
  Spinner
//   CustomInput
} from 'reactstrap'
// import { useForm, Controller } from "react-hook-form"
import apiConfig from '@configs/apiConfig'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import '@styles/react/libs/tables/react-dataTable-component.scss'
import { ValidationError } from 'yup'
const ToastContent = ({ message = null }) => (
    <>
    {message !== null && (
      <div className='d-flex'>
        <div className='me-1'>
          {/* <Avatar size='sm' color='success' icon={<Edit size={12} />} /> */}
        </div>
        <div className='d-flex flex-column'>
          <div className='d-flex justify-content-between'>
            <span>{message}</span>
          </div>
        </div>
      </div>
    )}
    </>
)
const Couponcode = () => {
    const history = useNavigate()
    const MySwal = withReactContent(Swal)
    const token = getToken()
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone

    // const form = useForm()
    // const {
    //   control,
    //   handleSubmit,
    // //   setValue,
    //   formState: { errors }
    // } = form
    const CustomLabel = ({ htmlFor }) => {
        return (
          <Label className='form-check-label' htmlFor={htmlFor}>
            <span className='switch-icon-left'>
              <Check size={14} />
            </span>
            <span className='switch-icon-right'>
              <X size={14} />
            </span>
          </Label>
        )
      }
 const [modalOpen, setModalOpen] = useState(false)
 const [picker, setPicker] = useState('')
 const [endDate, setEndDate] = useState('')
 const [loading, setLoading] = useState(false)
 const [coupons, setCoupons] = useState([])
 const [rowsPerPage, setRowsPerPage] = useState(10)
 const [currentPage, setCurrentPage] = useState(0)
 const [paginatedData, setPaginatedData] = useState(null)
 const [pending, setPending] = useState(false)
 const [coupon, setCoupon] = useState('')
 const [discount, setDiscount] = useState('')
 const [limit, setLimit] = useState('')
 const [error, setError] = useState(false)
 const [editFlag, setEditFlag] = useState(false)
 const [editId, setEditId] = useState(null)
 const [dateError, setDateError] = useState(null)
//  const isCurrentDay = (date) => {
//   const currentDate = new Date()
//   return date.getFullYear() === currentDate.getFullYear() &&
//          date.getMonth() === currentDate.getMonth() &&
//          date.getDate() === currentDate.getDate()
// }
const dateValidation = (startDate, endDate) => {
    console.log('startDate', startDate)
    console.log('endDate', endDate)
    const currentDate = new Date()
    if ((startDate[0] < currentDate) && !editFlag) {
       setDateError('The given start date should be in the future compared to the current date and time.')
    } else if (startDate[0] >= endDate[0]) {
       setDateError('The start date and time should be earlier than the end date.')
    } else {
      setDateError(null)
    }
}
 const handlePicker = (date) => {
      console.log('date', date)
      if (date.length !== 0) {
      setPicker(date)
      if (endDate !== '') {
        dateValidation(date, endDate)
      }
      } else {
        setPicker('')
      }
 }
 const handleEndDate = (date) => {
  if (date.length !== 0) {
    setEndDate(date)
    if (picker !== '') {
      dateValidation(picker, date)
    }
    } else {
      setEndDate('')
    }
 }
 const listCoupons = (page, per_page) => {
  setPending(true)
  const config = {
      method: 'get',
      url: `${apiConfig.api.url}discount_code/paginated?page=${page}&per_page=${per_page}&time_zone=${tz}`,
      headers: { 
          ContentType: 'application/json',
          Authorization: `Token ${token}`
        }
  }
  axios(config).then((response) => {
      console.log('response', response)
       setPending(false)
      if (response.data.status === 200) {
          setCoupons(response.data.data)
          setPaginatedData(response.data)
          console.log('coupons', coupons)
      } else if (response.data.status === 204) {
          setCoupons([])
          setPaginatedData(null)
      } else {
          toast.error(
              <ToastContent message={response.data.message} />,
              { duration:2000 }
          )
      }
  }).catch((error) => {
      setPending(false)
      toast.error(
          <ToastContent message={error.message} />,
          { duration:2000 }
      )
  })
}
const close = () => {
  setEditFlag(false)
  setPicker('')
  setEndDate('')
  setDiscount('')
  setEditId(null)
  setLimit('')
  setCoupon('')
  setModalOpen(false)
  setError(false)
  setDateError(null)
 }
 const onSubmit = () => {
    setLoading(true)
    const payload = {title: coupon, discount_percentage:parseInt(discount), per_user_limit:parseInt(limit), start_date_time:moment(picker[0]).format("YYYY-MM-DD HH:mm:ss"), end_date_time: moment(endDate[0]).format("YYYY-MM-DD HH:mm:ss")}
    const config = {
        method: !editFlag ? 'post' : 'put',
        url: !editFlag ? `${apiConfig.api.url}discount_code?time_zone=${tz}` :  `${apiConfig.api.url}discount_code/${editId}?time_zone=${tz}`,
        data:payload,
        headers: { 
            ContentType: 'application/json',
            Authorization: `Token ${token}`
          }
    }
    axios(config).then((response) => {
        console.log('response', response)
        setLoading(false)
        if (response.data.status === 200) {
           close()
           listCoupons(1, 10)
           toast.success(
            <ToastContent message={response.data.message} />,
            { duration:2000 }
        )
        } else {
            toast.error(
                <ToastContent message={response.data.message} />,
                { duration:2000 }
            )
        }
    }).catch((error) => {
        console.log(error)
        setLoading(false)
        toast.error(
            <ToastContent message={error.message} />,
            { duration:2000 }
        )
    })
 }

 const editOpen = (row) => {
     setEditFlag(true)
     setPicker([new Date(row.start_date_time)])
     setEndDate([new Date(row.end_date_time)])
     setDiscount(row.discount_percentage)
     setEditId(row.id)
     setLimit(row.per_user_limit)
     setCoupon(row.title)
     setModalOpen(true)
 }
 const handleDiscount = (value) => {
  const numberRegex = /^[0-9\.]+$/
    if (value) {
    if (numberRegex.test(value) && value > 0) {
      setDiscount(value.replace(/\D/g, ""))
    }
  } else {
    setDiscount('')
  }
 }
 
 useEffect(() => {
    listCoupons(1, 10)
 }, [])
 const handleStatus = (row) => {
    console.log(row)
    return MySwal.fire({
      title: 'Are you sure?',
      text: "You want to update!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Confirm!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      },
      buttonsStyling: false
    }).then(function (result) {
      if (result.value) {
        const config = {
          method: 'patch',
          url: `${apiConfig.api.url}discount_code/status/${row.id}`,
          headers: { 
            ContentType: 'application/json',
            Authorization: `Token ${token}`
          }, 
          data :{is_active: !row.is_active}
        }
        console.log(config)
        axios(config)
        .then(response => {
          console.log(response)
          if (response.data.status === 200) {
           listCoupons(currentPage + 1, rowsPerPage)
            // searchValue ? filterUsers(searchValue) : getPromocodeList(currentPage + 1, rowsPerPage)
            MySwal.fire({
              icon: 'success',
              title: 'Updated!',
              text: 'Successfully Updated.',
              customClass: {
                confirmButton: 'btn btn-success'
              }
            })
          } else if (response.data.status > 200 && response.data.status > 299) {
            toast.error(
              <ToastContent message={response.data.message} />,
              { duration:2000 }
            )
          } else if (response.data.status === 401) {
             dispatch(handleLogout())
             toast.error(
              <ToastContent message={response.data.message} />,
              {duration:3000}             
            )
            history('/login')
          } else {
            toast.error(
             <ToastContent message={response.data.message} />,
             {duration:3000}             
           )
          }
        })
        .catch(error => {
          console.log(error)
          if (error && error.status === 401) {
            toast.error(
              <ToastContent message={error.message} />,
              { duration:2000 }
            )
          } else if (error) {
            toast.error(
              <ToastContent message={error.message} />,
              { duration:2000 }
            )
          } 
        })
      } else if (result.dismiss === MySwal.DismissReason.cancel) {
        MySwal.fire({
          title: 'Cancelled',
          text: 'Update Cancelled',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
      }
    })
  }
  const handleDelete = (row) => {
    console.log(row)
    return MySwal.fire({
      title: 'Are you sure?',
      text: "You want to delete!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Confirm!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      },
      buttonsStyling: false
    }).then(function (result) {
      if (result.value) {
        setPending(true)
        const config = {
          method: 'delete',
          url: `${apiConfig.api.url}discount_code/${row.id}`,
          headers: { 
            ContentType: 'application/json',
            Authorization: `Token ${token}`
          }
        }
        console.log(config)
        axios(config)
        .then(response => {
          console.log(response)
          setPending(false)
          if (response.data.status === 200) {
           listCoupons(currentPage + 1, rowsPerPage)
            // searchValue ? filterUsers(searchValue) : getPromocodeList(currentPage + 1, rowsPerPage)
            MySwal.fire({
              icon: 'success',
              title: 'Updated!',
              text: 'Successfully deleted.',
              customClass: {
                confirmButton: 'btn btn-success'
              }
            })
          } else if (response.data.status > 200 && response.data.status > 299) {
            toast.error(
              <ToastContent message={response.data.message} />,
              { duration:2000 }
            )
          } else if (response.data.status === 401) {
             dispatch(handleLogout())
             toast.error(
              <ToastContent message={response.data.message} />,
              {duration:3000}             
            )
            history('/login')
          } else {
            toast.error(
             <ToastContent message={response.data.message} />,
             {duration:3000}             
           )
          }
        })
        .catch(error => {
          setPending(false)
          console.log(error)
          if (error && error.status === 401) {
            toast.error(
              <ToastContent message={error.message} />,
              { duration:2000 }
            )
          } else if (error) {
            toast.error(
              <ToastContent message={error.message} />,
              { duration:2000 }
            )
          } 
        })
      } else if (result.dismiss === MySwal.DismissReason.cancel) {
        MySwal.fire({
          title: 'Cancelled',
          text: 'Action Cancelled',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
      }
    })
  }
 const columns = [
    {
        name: 'Name',
        selector: row => row.title,
        sortable: true,
        minWidth: '160px',
        cell: row => {
            return (
                <span>
                    {row.title}
                </span>
            )
        }
      },
      {
        name: 'Discount %',
        selector: row => row.discount_percentage,
        sortable: true,
        minWidth: '160px',
        cell: row => {
            return (
                <span>
                    {row.discount_percentage}
                </span>
            )
        }
      },
      {
        name: 'Start Date',
        selector: row => row.start_date_time,
        sortable: true,
        minWidth: '210px',
        cell: row => {
            return (
                <span>
                    {row.start_date_time}
                </span>
            )
        }
      },
      {
        name: 'End Date',
        selector: row => row.end_date_time,
        sortable: true,
        minWidth:'210px',
        cell: row => {
            return (
                <span>
                    {row.end_date_time}
                </span>
            )
        }
      },
      {
        name: 'Limit',
        selector: row => row.per_user_limit,
        sortable: true,
        minWidth: '80px',
        cell: row => {
            return (
                <span>
                    {row.per_user_limit}
                </span>
            )
        }
      },
      {
        name: 'Enable/Disable',
        selector: row => row.is_active,
        minWidth: '160px',
        cell: row => {
            return (
            <>
                <div className='d-flex flex-column'>
                    <div className='form-switch form-check-success'>
                    <Input type='switch'   checked={row.is_active} id={`switch_${row.id}`} name={`switch_${row.id}`} onClick={() => handleStatus(row)}/>
                    <CustomLabel htmlFor={`switch_${row.id}`} />
                    </div>
                </div>
            </>
            )
          }
      },
      {
        name: 'Action',
        allowOverflow: true,
        // minWidth: '100px',
        cell: (row) => {
         console.log(row)
          return (
            <div className='d-flex align-items-center'>
            <span className='me-50' style={{cursor:'pointer'}} onClick={() => editOpen(row)}><Edit size={15}/></span>
            <span className='me-50' style={{cursor:'pointer'}}  onClick={() => history(`/coupon-code-access/${row.id}?name=${row.title}`)}><Eye size={15}/></span>
            <span className='me-50' style={{cursor:'pointer'}}  onClick={() => handleDelete(row)}><Trash size={15}/></span>
            </div>
          )
        }
    }
 ]
 console.log('modalOpen', modalOpen)
 const handlePagination = (page) => {
    setCurrentPage(page.selected)
    listCoupons(page.selected + 1, rowsPerPage)
  }
  const handlePerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value))
    setTimeout(() => {
      listCoupons(1, event.target.value)
    }, 300)
  }
 
  const handleLimit = (value) => {
    const numberRegex = /^[0-9\.]+$/
    if (value) {
    if (numberRegex.test(value) && value > 0) {
      setLimit(value.replace(/\D/g, ""))
    }
  } else {
    setLimit('')
  }
  }
  const validationCheck = () => {
    let validationError = false
    if (picker === '') {
      validationError = true
      setError(true)
    }
    if (endDate === '') {
      validationError = true
      setError(true)
    }
    if (coupon === '') {
      validationError = true
      setError(true)
    }
    if (discount === '') {
      validationError = true
      setError(true)
    }
    if (limit === '') {
      validationError = true
      setError(true)
    }
    if (!validationError) {
      setError(false)
      onSubmit()
    }
  }
 const CustomPagination = () => {
    return (
      <ReactPaginate
        previousLabel=""
        nextLabel=""
        forcePage={currentPage}
        onPageChange={(page) => handlePagination(page)}
        pageCount={
          paginatedData &&
          (Math.ceil(paginatedData.pagination.total / rowsPerPage) || 1)
        }
        breakLabel="..."
        activeClassName="active"
        pageClassName="page-item"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        nextLinkClassName="page-link"
        nextClassName="page-item next"
        previousClassName="page-item prev"
        previousLinkClassName="page-link"
        pageLinkClassName="page-link"
        containerClassName="pagination react-paginate separated-pagination pagination-sm justify-content-end mt-2"
      />
    )
  }
  return (
    <div>
         <Card>
        <CardHeader className='flex-md-row flex-column align-md-items-center align-items-start border-bottom'>
          <CardTitle tag='h4'>Couponcode List</CardTitle>
          <div className='d-flex mt-md-0 mt-1'>
            
            <Button className='ml-2' color='primary' onClick={() => setModalOpen(true)}>
              <Plus size={15} />
              <span className='align-middle ml-50'>Add Coupon </span>
            </Button>
            
          </div>
        </CardHeader>
        <CardBody>
          <Row className='justify-content-start mb-1'>
            <Col sm='2'>
              <div className='d-flex align-items-center mt-1 me-2'>
                <Label for='sort-select' className='me-1'>show</Label>
                <Input
                  className='dataTable-select'
                  type='select'
                  id='sort-select'
                  value={rowsPerPage}
                  onChange={e => handlePerPage(e)}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={75}>75</option>
                  <option value={100}>100</option>
                </Input>
              </div>
            </Col>
            
          </Row>
        
          <DataTable
            noHeader
            pagination
            fixedHeader
            fixedHeaderScrollHeight="75vh"
            progressPending={pending}
            columns={columns}
            paginationPerPage={rowsPerPage}
            paginationServer
            className='react-dataTable'
            sortIcon={<ChevronDown size={10} />}
            paginationDefaultPage={currentPage + 1}
            paginationComponent={CustomPagination}
            data={coupons}
          /> 
        </CardBody>
         </Card>
         <Modal isOpen={modalOpen} toggle={close} className='modal-dialog-centered modal-lg'  keyboard={false} backdrop="static">
        <ModalHeader className='bg-transparent' toggle={close}></ModalHeader>
        <ModalBody className='px-sm-5 pt-50 pb-5'>
          <div className='text-center mb-2'>
            <h1 className='mb-1'>{ editFlag ? 'Edit Coupon' : 'Add Coupon'}</h1>
          </div>
          <Form>
          <Row className='mt-1'>
            <Col>
                <Label>Start Date<span className='text-danger'>*</span></Label>
                    <Flatpickr
                    value={picker}
                    id="start_date"
                    className="form-control"
                    onClose={(date) => handlePicker(date)}
                    options={{
                    enableTime: true,
                    // minDate:"today",
                    maxDate:"2099-12-31",
                    // minTime:"14:15",
                    // minTime: currentDateTime.getMinutes() === 59 ? (currentDateTime.getHours() + 1).toString().concat(":00") : currentDateTime.getHours().toString().concat(":", (currentDateTime.getMinutes() + 1)),                 
                    dateFormat: "Y-m-d H:i:S",
                    defaultDate: picker
                    }}
                  />
            </Col>
            <Col>
                <Label>End Date<span className='text-danger'>*</span></Label>
                    <Flatpickr
                    value={endDate}
                    id="end_date"
                    className="form-control"
                    onClose={(date) => handleEndDate(date)}
                    options={{
                    enableTime: true,
                    dateFormat: "Y-m-d H:i:S",
                    maxDate:"2099-12-31",
                    defaultDate: endDate
                    // minDate: picker !== '' && new Date(picker[0].getTime() + (1 * 60000)) : picker[0] + (1 * 60000)
                    }}
                  />
            </Col>
            {dateError !== null && <span className='text-danger mt-1'>{dateError}</span>}
          </Row>
            <Row >
            <Col xs={12} className='mt-1'>
                  <Label>Coupon Code <span className='text-danger'>*</span></Label>
                    <Input
                      value={coupon}
                      onChange={(event) => setCoupon(event.target.value.toUpperCase())}
                      autoFocus
                      type="text"
                      id="title"
                      placeholder='Coupon Name..'
                    />
            </Col>
            <Col xs={12} className='mt-1'>
                  <Label>Discount Percent <span className='text-danger'>*</span></Label>
                
                    <Input
                      value={discount}
                      onChange={(event) => handleDiscount(event.target.value)}
                      autoFocus
                      type="text"
                      id="discount_percent"
                      placeholder='Discount Percent'
                    />
            </Col>
            <Col xs={12} className='mt-1'>
                  <Label>User Limit <span className='text-danger'>*</span></Label>
                    <Input
                      value={limit}
                      onChange={(event) => handleLimit(event.target.value)}                     
                      autoFocus
                      type="text"
                      id="per_user_limit"
                      placeholder='Limit'
                    />             
            </Col>  
            </Row>
            <Row>
            {
              error &&  <Col className='text-center text-danger fw-bold mt-2  pt-50'>
              **All Fields are mandatory**
            </Col>
            }
           
            <Col xs={12} className='text-center mt-2 pt-50'>
                <Button className='me-1' color='primary' style={{marginRight: '20px'}} disabled={loading || dateError !== null} onClick={validationCheck}>
                {loading && <Spinner color="white" size="sm"  className='me-1'/>}Submit
                </Button>
                <Button
                  type='reset'
                  color='secondary'
                  outline
                  onClick={close}
                >
                  Cancel
                </Button>
            </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default Couponcode
